import React from "../_snowpack/pkg/react.js";
import App from "../components/app.js";
import HeroHome from "../components/hero-home.js";
import Process from "../components/process.js";
import ProductGrid from "../components/product-grid.js";
import ContentModules from "../components/content-modules.js";
export default function HomePage(props) {
  const [HeroModule] = props.content.hero;
  const [ProcessModule] = props.content.process;
  const [ProductsModule] = props.content.products;
  return /* @__PURE__ */ React.createElement(App, {
    ...props
  }, /* @__PURE__ */ React.createElement(HeroHome, {
    ...HeroModule
  }), /* @__PURE__ */ React.createElement(ProductGrid, {
    ...ProductsModule
  }), /* @__PURE__ */ React.createElement(Process, {
    ...ProcessModule
  }), /* @__PURE__ */ React.createElement(ContentModules, {
    modules: props.content.modules,
    isProductPage: false
  }));
}
