import styled from "../_snowpack/pkg/@emotion/styled.js";
import React from "../_snowpack/pkg/react.js";
const fromUrl = "https://a.storyblok.com/";
const toUrl = "https://img2.storyblok.com/";
export default function Image(props) {
  const {filename, fieldtype, copyright, id, name, sizes, ...rest} = props;
  if (!filename)
    return null;
  const srcSet = genSrcSetForSizes(filename, sizes);
  return /* @__PURE__ */ React.createElement(Container, {
    src: filename,
    srcSet,
    loading: "lazy",
    ...rest
  });
}
const svgRegexp = /\.svg/;
function genSrcSetForSizes(imageUrl, sizes = []) {
  if (svgRegexp.test(imageUrl)) {
    return;
  }
  return sizes.map((s) => genSrcForSize(imageUrl, s)).join(" , ");
}
function genSrcForSize(image, size) {
  const path = image.replace(fromUrl, "");
  const [w, h] = Array.isArray(size) ? size : [size, 0];
  return `${toUrl}${w}x${h}/${path} ${w}w, ${toUrl}${2 * w}x${2 * h}/${path} ${2 * w}w`;
}
const Container = styled.img``;
