import styled from "../_snowpack/pkg/@emotion/styled.js";
import React from "../_snowpack/pkg/react.js";
import {Title, Text} from "./typo.js";
import {colors, lineHeight, media} from "./theme.js";
import {Grid} from "./grid.js";
import Component from "./editable-component.js";
import Image from "./image.js";
export default function ContentCards(props) {
  const {cards, ...rest} = props;
  return /* @__PURE__ */ React.createElement(Component, {
    ...rest
  }, /* @__PURE__ */ React.createElement(CardsGrid, null, cards.map((card) => {
    return /* @__PURE__ */ React.createElement(Component, {
      key: card._uid,
      ...card
    }, /* @__PURE__ */ React.createElement(Card, null, /* @__PURE__ */ React.createElement(ImageContainer, {
      ...card.asset,
      sizes: [500, 200]
    }), /* @__PURE__ */ React.createElement(Headline, {
      level: 3
    }, card.headline), /* @__PURE__ */ React.createElement(Copy, null, card.copy)));
  })));
}
const CardsGrid = styled(Grid)`
  margin: 100px 0 30px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-gap: 80px;

  ${media.phone} {
    grid-template-columns: 1fr;
    margin: 50px 0 0;
    grid-gap: 50px;
  }
`;
const ImageContainer = styled(Image)`
  max-width: 100%;
  margin-bottom: 40px;
  border-radius: 4px;
  overflow: hidden;
  aspect-ratio: 3/2;
  object-fit: cover;

  ${media.phone} {
    aspect-ratio: auto;
  }
`;
const Headline = styled(Title)`
  margin-bottom: 32px;
  font-size: 30px;
  line-height: ${lineHeight};

  ${media.mobile} {
    font-size: 20px;
    margin-bottom: 16px;
  }
`;
const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Copy = styled(Text)`
  font-size: 24px;
  line-height: ${lineHeight};
  color: ${colors.b2};

  ${media.mobile} {
    font-size: 16px;
  }
`;
