import styled from "../_snowpack/pkg/@emotion/styled.js";
import React from "../_snowpack/pkg/react.js";
import {fontFamily, colors, media, spacings} from "./theme.js";
import {Title, Text} from "./typo.js";
import {Grid} from "./grid.js";
import Button from "./button.js";
import Stack from "./stack.js";
import Component from "./editable-component.js";
import Image from "./image.js";
import Link from "./link.js";
export default function Contact(props) {
  const {image, headline, copy, button, isProductPage, ...rest} = props;
  const [cta] = button;
  return /* @__PURE__ */ React.createElement(Component, {
    ...rest
  }, /* @__PURE__ */ React.createElement(ContactGrid, {
    isProductPage,
    ...rest
  }, image && /* @__PURE__ */ React.createElement(GridAreaImage, null, /* @__PURE__ */ React.createElement(ContactImage, {
    isProductPage,
    ...image,
    sizes: [300]
  })), /* @__PURE__ */ React.createElement(GridAreaText, {
    spacing: "l"
  }, /* @__PURE__ */ React.createElement(Headline, {
    level: 3,
    isProductPage
  }, headline), /* @__PURE__ */ React.createElement(SubTitle, {
    as: "h3",
    isProductPage
  }, copy), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(Link, {
    href: cta.cta_url
  }, cta.cta_text)))));
}
const ContactGrid = styled(Grid)`
  padding: ${(props) => props.isProductPage ? "30px 0 0" : "60px 0 0"};
  grid-template-columns: ${(props) => props.isProductPage ? "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr" : "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr"};
  grid-template-rows: 1fr;
  grid-gap: 0px 24px;
  grid-template-areas: ${(props) => props.isProductPage ? "'Image Image Image Text Text Text Text Text Text'" : "'. Image Image Image Image . Text Text Text Text Text .'"};

  ${media.phone} {
    margin: ${spacings.xl}px 0 0;
    padding: ${spacings.m}px;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-gap: 0 0;
    grid-template-areas: "Text";
    box-shadow: 0px 8px 24px 0px ${colors.shadow};
    border-radius: 4px;
  }
`;
const GridAreaText = styled(Stack)`
  display: grid;
  align-content: center;
  grid-area: Text;

  ${media.phone} {
    --spacing: ${spacings.m}px;
  }
`;
const GridAreaImage = styled.div`
  grid-area: Image;
`;
const ContactImage = styled(Image)`
  max-width: 100%;
  border-radius: 4px;
  object-fit: cover;
  align-self: center;

  ${media.phone} {
    display: none;
  }
`;
const Headline = styled(Title)`
  font-size: 30px;
  line-height: 38px;

  ${media.phone} {
    font-size: 24px;
    line-height: 32px;
  }
`;
const SubTitle = styled(Text)`
  font-family: ${fontFamily};
  color: ${colors.b2};
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  ${media.phone} {
    font-size: 16px;
    line-height: 24px;
  }
`;
const ContactButton = styled(Button)`
  ${media.phone} {
    padding: ${spacings.xs}px 0;
    width: 100%;
    line-height: 24px;
  }
`;
