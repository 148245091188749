import styled from "../_snowpack/pkg/@emotion/styled.js";
import React from "../_snowpack/pkg/react.js";
import {withAttrs} from "../common/utils.js";
import EditableComponent from "./editable-component.js";
import Icon from "./icon.js";
import Inline from "./inline.js";
import Stack from "./stack.js";
import {colors, fontFamily, media, shadow} from "./theme.js";
import {Title} from "./typo.js";
export default function PageContact(props) {
  const {headline, subline, telephone, email, isProductPage} = props;
  return /* @__PURE__ */ React.createElement(EditableComponent, {
    ...props
  }, /* @__PURE__ */ React.createElement(ContactContainer, null, /* @__PURE__ */ React.createElement(ContactInner, null, /* @__PURE__ */ React.createElement(ContactHeadline, {
    isProductPage
  }, headline), /* @__PURE__ */ React.createElement(ContactSubline, {
    isProductPage
  }, subline), /* @__PURE__ */ React.createElement(ContactInfos, null, /* @__PURE__ */ React.createElement(ContactInfo, {
    isProductPage,
    href: `tel:${telephone}`
  }, /* @__PURE__ */ React.createElement(I, {
    name: "phone"
  }), telephone), /* @__PURE__ */ React.createElement(ContactInfo, {
    isProductPage,
    href: `mailto:${email}`
  }, /* @__PURE__ */ React.createElement(I, {
    name: "mail"
  }), email)))));
}
const Card = styled.div`
  padding: 56px;
  box-shadow: ${shadow.card};
  border-radius: 4px;
  overflow: hidden;
`;
const ContactContainer = styled(Card)`
  ${media.phone} {
    margin-top: 48px;
    padding: 16px;
  }
`;
const ContactInner = styled(withAttrs(Stack, {spacing: "m"}))`
  ${media.phone} {
    --spacing: 8px;
  }
`;
const ContactHeadline = styled(withAttrs(Title, {level: 2}))`
  font-size: 30px;

  ${({isProductPage}) => isProductPage && `
    font-size: 30px;
    line-height: 38px;
  `}

  ${media.phone} {
    font-size: 24px;
  }
`;
const ContactSubline = styled.div`
  font-family: ${fontFamily.primary};
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  color: ${colors.b4};

  ${({isProductPage}) => isProductPage && `
    font-size: 16px;
    line-height: 24px;
  `}

  ${media.phone} {
    font-size: 16px;
    line-height: 24px;
  }
`;
const ContactInfos = styled(Inline)`
  --spacing: 24px;
  flex-wrap: wrap;

  ${media.phone} {
    margin-top: 16px;
    --spacing: 16px;
  }
`;
const ContactInfo = styled(withAttrs(Inline, {as: "a"}))`
  --spacing: 10px;
  align-items: center;
  font-family: ${fontFamily.primary};
  color: ${colors.b};
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  text-decoration: none;

  ${({isProductPage}) => isProductPage && `
    font-size: 16px;
    line-height: 24px;
  `};

  ${media.phone} {
    font-size: 16px;
    line-height: 24px;
    width: 100%;
  }
`;
const I = styled(Icon)`
  width: 25px;
  height: 25px;

  ${media.phone} {
    width: 18px;
    height: 18px;
  }
`;
