import React from "../_snowpack/pkg/react.js";
import * as Sentry from "../_snowpack/pkg/@sentry/react.js";
import {BrowserTracing} from "../_snowpack/pkg/@sentry/tracing.js";
import {getGlobalProps} from "./universal.js";
const props = getGlobalProps();
const dsn = props.sentry_dsn;
const locale = props.locale;
let initialized = false;
export function init() {
  if (!dsn)
    return;
  if (initialized)
    return;
  Sentry.init({
    dsn,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1,
    beforeSend(event) {
      if (event.exception) {
        Sentry.showReportDialog({eventId: event.event_id, lang: locale});
      }
      return event;
    }
  });
  initialized = true;
}
export function withProfilter(App) {
  init();
  return Sentry.withProfiler(App);
}
export function withErrorBoundary(App) {
  init();
  return Sentry.withErrorBoundary(App, {
    fallback: /* @__PURE__ */ React.createElement("p", null, "an error has occurred")
  });
}
export function withSentry(App) {
  return withErrorBoundary(withProfilter(App));
}
