import * as __SNOWPACK_ENV__ from '../_snowpack/env.js';
import.meta.env = __SNOWPACK_ENV__;

import {createElement} from "../_snowpack/pkg/react.js";
import {hydrate, render} from "../_snowpack/pkg/react-dom.js";
import {getGlobalProps} from "./universal.js";
export default function bootstrap(C, noSSr) {
  const mount = noSSr ? render : hydrate;
  mount(createElement(C, getGlobalProps()), document.getElementById("root"));
}
undefined /* [snowpack] import.meta.hot */ ?.accept();
