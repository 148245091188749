import styled from "../_snowpack/pkg/@emotion/styled.js";
import React from "../_snowpack/pkg/react.js";
export function Cart(props) {
  return /* @__PURE__ */ React.createElement("svg", {
    width: "21",
    height: "21",
    viewBox: "0 0 21 21",
    fill: "none",
    ...props
  }, /* @__PURE__ */ React.createElement("g", {
    clipPath: "url(#clip0)"
  }, /* @__PURE__ */ React.createElement("path", {
    d: "M5.90625 19.0312C6.63112 19.0312 7.21875 18.4436 7.21875 17.7188C7.21875 16.9939 6.63112 16.4062 5.90625 16.4062C5.18138 16.4062 4.59375 16.9939 4.59375 17.7188C4.59375 18.4436 5.18138 19.0312 5.90625 19.0312Z",
    fill: "#001529"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M15.75 19.0312C16.4749 19.0312 17.0625 18.4436 17.0625 17.7188C17.0625 16.9939 16.4749 16.4062 15.75 16.4062C15.0251 16.4062 14.4375 16.9939 14.4375 17.7188C14.4375 18.4436 15.0251 19.0312 15.75 19.0312Z",
    fill: "#001529"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M3.28125 5.25H17.5888C17.6849 5.25 17.7798 5.27111 17.8669 5.31185C17.9539 5.35258 18.031 5.41194 18.0926 5.48573C18.1542 5.55952 18.1988 5.64594 18.2233 5.73888C18.2478 5.83182 18.2516 5.92901 18.2344 6.02358L16.8034 13.8986C16.776 14.0498 16.6963 14.1866 16.5783 14.2851C16.4603 14.3836 16.3115 14.4375 16.1578 14.4375H5.50582C5.35219 14.4375 5.20343 14.3836 5.08546 14.2852C4.96749 14.1868 4.88778 14.0501 4.86022 13.899L2.78286 2.50727C2.7553 2.35614 2.67559 2.21946 2.55762 2.12105C2.43964 2.02265 2.29089 1.96875 2.13726 1.96875H0.65625",
    stroke: "#001529",
    strokeWidth: "1.5",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  })), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("clipPath", {
    id: "clip0"
  }, /* @__PURE__ */ React.createElement("rect", {
    width: "21",
    height: "21",
    fill: "white"
  }))));
}
export function Menu(props) {
  return /* @__PURE__ */ React.createElement("svg", {
    width: "25",
    height: "24",
    viewBox: "0 0 25 24",
    fill: "none",
    ...props
  }, /* @__PURE__ */ React.createElement("path", {
    d: "M3.47791 12H21.4779",
    stroke: "#001529",
    strokeWidth: "2",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M3.47791 6H21.4779",
    stroke: "#001529",
    strokeWidth: "2",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M3.47791 18H21.4779",
    stroke: "#001529",
    strokeWidth: "2",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }));
}
export function ChevronDown(props) {
  return /* @__PURE__ */ React.createElement("svg", {
    width: "24",
    height: "24",
    viewBox: "0 0 24 24",
    fill: "none",
    ...props
  }, /* @__PURE__ */ React.createElement("path", {
    d: "M6 9L12 15L18 9",
    stroke: "#001529",
    strokeWidth: "2",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }));
}
export function X(props) {
  return /* @__PURE__ */ React.createElement("svg", {
    width: "24",
    height: "24",
    viewBox: "0 0 24 24",
    fill: "none",
    ...props
  }, /* @__PURE__ */ React.createElement("path", {
    d: "M18 6L6 18",
    stroke: "#001529",
    strokeWidth: "2",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M6 6L18 18",
    stroke: "#001529",
    strokeWidth: "2",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }));
}
export function Plus(props) {
  return /* @__PURE__ */ React.createElement("svg", {
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    fill: "none",
    ...props
  }, /* @__PURE__ */ React.createElement("path", {
    d: "M8 3.33337V12.6667",
    stroke: "currentColor",
    strokeWidth: "1.5",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M3.33337 8H12.6667",
    stroke: "currentColor",
    strokeWidth: "1.5",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }));
}
export function Bin(props) {
  return /* @__PURE__ */ React.createElement("svg", {
    width: "12",
    height: "14",
    viewBox: "0 0 12 14",
    fill: "none",
    ...props
  }, /* @__PURE__ */ React.createElement("path", {
    d: "M11.5 3H9.5V1.75C9.5 1.19844 9.05156 0.75 8.5 0.75H3.5C2.94844 0.75 2.5 1.19844 2.5 1.75V3H0.5C0.223437 3 0 3.22344 0 3.5V4C0 4.06875 0.05625 4.125 0.125 4.125H1.06875L1.45469 12.2969C1.47969 12.8297 1.92031 13.25 2.45312 13.25H9.54688C10.0813 13.25 10.5203 12.8313 10.5453 12.2969L10.9312 4.125H11.875C11.9438 4.125 12 4.06875 12 4V3.5C12 3.22344 11.7766 3 11.5 3ZM3.625 1.875H8.375V3H3.625V1.875ZM9.42656 12.125H2.57344L2.19531 4.125H9.80469L9.42656 12.125Z",
    fill: "#00A4E2"
  }));
}
export function Edit(props) {
  const {color = "#00A4E2"} = props;
  return /* @__PURE__ */ React.createElement("svg", {
    width: "14",
    height: "14",
    viewBox: "0 0 14 14",
    fill: "none",
    ...props
  }, /* @__PURE__ */ React.createElement("path", {
    d: "M12.75 12.0625H1.25C0.973437 12.0625 0.75 12.2859 0.75 12.5625V13.125C0.75 13.1938 0.80625 13.25 0.875 13.25H13.125C13.1938 13.25 13.25 13.1938 13.25 13.125V12.5625C13.25 12.2859 13.0266 12.0625 12.75 12.0625ZM3.02656 10.75C3.05781 10.75 3.08906 10.7469 3.12031 10.7422L5.74844 10.2812C5.77969 10.275 5.80938 10.2609 5.83125 10.2375L12.4547 3.61406C12.4692 3.59961 12.4807 3.58244 12.4885 3.56354C12.4963 3.54463 12.5004 3.52437 12.5004 3.50391C12.5004 3.48344 12.4963 3.46318 12.4885 3.44428C12.4807 3.42538 12.4692 3.40821 12.4547 3.39375L9.85781 0.795313C9.82812 0.765625 9.78906 0.75 9.74687 0.75C9.70469 0.75 9.66563 0.765625 9.63594 0.795313L3.0125 7.41875C2.98906 7.44219 2.975 7.47031 2.96875 7.50156L2.50781 10.1297C2.49261 10.2134 2.49804 10.2995 2.52364 10.3807C2.54923 10.4618 2.59421 10.5355 2.65469 10.5953C2.75781 10.6953 2.8875 10.75 3.02656 10.75ZM4.07969 8.025L9.74687 2.35938L10.8922 3.50469L5.225 9.17031L3.83594 9.41562L4.07969 8.025Z",
    fill: color
  }));
}
export function Lightning(props) {
  return /* @__PURE__ */ React.createElement("svg", {
    width: "12",
    height: "18",
    viewBox: "0 0 12 18",
    fill: "none",
    ...props
  }, /* @__PURE__ */ React.createElement("path", {
    d: "M3.9143 17.1825C3.82291 17.1826 3.73261 17.1627 3.64975 17.1241C3.56688 17.0856 3.49347 17.0294 3.43466 16.9594C3.37585 16.8894 3.33307 16.8075 3.30933 16.7192C3.28559 16.631 3.28147 16.5386 3.29725 16.4486V16.4448L4.25555 11.1825H0.545402C0.442419 11.1825 0.341543 11.1533 0.254442 11.0984C0.167341 11.0434 0.0975741 10.9649 0.0532098 10.872C0.00884553 10.7791 -0.00830309 10.6755 0.00374769 10.5732C0.0157985 10.4709 0.0565561 10.3741 0.121306 10.2941L7.59308 1.05738C7.67813 0.949421 7.7962 0.872289 7.92923 0.837779C8.06226 0.803269 8.20294 0.813281 8.32974 0.866283C8.45654 0.919285 8.56249 1.01236 8.6314 1.13128C8.7003 1.25019 8.72835 1.3884 8.71128 1.52477C8.71128 1.535 8.70855 1.54488 8.70684 1.55511L7.74513 6.8188H11.4546C11.5576 6.81881 11.6585 6.84797 11.7456 6.90292C11.8327 6.95786 11.9024 7.03634 11.9468 7.12928C11.9912 7.22221 12.0083 7.32581 11.9963 7.42809C11.9842 7.53036 11.9434 7.62714 11.8787 7.70722L4.40589 16.9439C4.34718 17.0181 4.27248 17.0781 4.18737 17.1194C4.10225 17.1607 4.00891 17.1823 3.9143 17.1825Z",
    fill: "#FAAD14"
  }));
}
export function Check(props) {
  return /* @__PURE__ */ React.createElement("svg", {
    width: "12",
    height: "9",
    viewBox: "0 0 12 9",
    fill: "none",
    ...props
  }, /* @__PURE__ */ React.createElement("path", {
    d: "M11 1L4.125 7.875L1 4.75",
    stroke: "currentColor",
    strokeWidth: "2",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }));
}
export function CheckGreen(props) {
  return /* @__PURE__ */ React.createElement("svg", {
    width: "32",
    height: "38",
    fill: "none",
    viewBox: "0 0 32 38",
    ...props
  }, /* @__PURE__ */ React.createElement("circle", {
    cx: "16",
    cy: "19",
    r: "16",
    fill: "#00CC67",
    opacity: ".3"
  }), /* @__PURE__ */ React.createElement("path", {
    stroke: "#00CC67",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeWidth: "2",
    d: "M21 16l-6.875 6.875L11 19.75"
  }));
}
export function Checkgreen(props) {
  return /* @__PURE__ */ React.createElement("svg", {
    width: "32",
    height: "38",
    fill: "none",
    viewBox: "0 0 32 38",
    ...props
  }, /* @__PURE__ */ React.createElement("circle", {
    cx: "16",
    cy: "19",
    r: "16",
    fill: "#00CC67",
    opacity: ".3"
  }), /* @__PURE__ */ React.createElement("path", {
    stroke: "#00CC67",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeWidth: "2",
    d: "M21 16l-6.875 6.875L11 19.75"
  }));
}
export function Checkaddress(props) {
  return /* @__PURE__ */ React.createElement("svg", {
    width: "24",
    height: "24",
    viewBox: "0 0 24 24",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, /* @__PURE__ */ React.createElement("path", {
    d: "M12 1.5C6.20156 1.5 1.5 6.20156 1.5 12C1.5 17.7984 6.20156 22.5 12 22.5C17.7984 22.5 22.5 17.7984 22.5 12C22.5 6.20156 17.7984 1.5 12 1.5ZM12 20.7188C7.18594 20.7188 3.28125 16.8141 3.28125 12C3.28125 7.18594 7.18594 3.28125 12 3.28125C16.8141 3.28125 20.7188 7.18594 20.7188 12C20.7188 16.8141 16.8141 20.7188 12 20.7188Z",
    fill: "#8BBD22"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M12 3.28125C7.18594 3.28125 3.28125 7.18594 3.28125 12C3.28125 16.8141 7.18594 20.7188 12 20.7188C16.8141 20.7188 20.7188 16.8141 20.7188 12C20.7188 7.18594 16.8141 3.28125 12 3.28125ZM16.5328 8.57109L11.5969 15.4148C11.5279 15.5111 11.4369 15.5896 11.3316 15.6437C11.2262 15.6978 11.1095 15.7261 10.991 15.7261C10.8726 15.7261 10.7558 15.6978 10.6505 15.6437C10.5451 15.5896 10.4541 15.5111 10.3852 15.4148L7.46484 11.3648C7.37578 11.2406 7.46484 11.0672 7.61719 11.0672H8.71641C8.95781 11.0672 9.18281 11.1844 9.32344 11.3789L10.9922 13.6945L14.6766 8.58516C14.8172 8.38828 15.0445 8.27344 15.2836 8.27344H16.3828C16.5352 8.27344 16.6242 8.44688 16.5328 8.57109Z",
    fill: "#F6FFED"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M16.3819 8.27344H15.2827C15.0436 8.27344 14.8163 8.38828 14.6757 8.58516L10.9913 13.6945L9.32252 11.3789C9.18189 11.1844 8.95689 11.0672 8.71549 11.0672H7.61626C7.46392 11.0672 7.37486 11.2406 7.46392 11.3648L10.3842 15.4148C10.4532 15.5111 10.5442 15.5896 10.6495 15.6437C10.7549 15.6978 10.8717 15.7261 10.9901 15.7261C11.1086 15.7261 11.2253 15.6978 11.3307 15.6437C11.436 15.5896 11.527 15.5111 11.596 15.4148L16.5319 8.57109C16.6233 8.44688 16.5343 8.27344 16.3819 8.27344Z",
    fill: "#8BBD22"
  }));
}
export function GrayCircle(props) {
  return /* @__PURE__ */ React.createElement("svg", {
    width: "32",
    height: "38",
    fill: "none",
    viewBox: "0 0 32 38",
    ...props
  }, /* @__PURE__ */ React.createElement("circle", {
    cx: "16",
    cy: "19",
    r: "16",
    fill: "#ccd0d4",
    opacity: ".3"
  }));
}
export function ArrowLeft(props) {
  return /* @__PURE__ */ React.createElement("svg", {
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    fill: "none",
    ...props
  }, /* @__PURE__ */ React.createElement("path", {
    d: "M13.5 8L2.5 8",
    stroke: "#001529",
    strokeWidth: "1.5",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M7 3.5L2.5 8L7 12.5",
    stroke: "#001529",
    strokeWidth: "1.5",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }));
}
export function ButtonArrow(props) {
  return /* @__PURE__ */ React.createElement("svg", {
    width: "14",
    height: "14",
    viewBox: "0 0 14 14",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    ...props
  }, /* @__PURE__ */ React.createElement("path", {
    d: "M1 6.99988L12.7857 6.99988",
    stroke: "white",
    strokeWidth: "2",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M7.28561 12.5L12.7856 7L7.28561 1.5",
    stroke: "white",
    strokeWidth: "2",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }));
}
export function ProductOptionExpressIcon(props) {
  return /* @__PURE__ */ React.createElement("svg", {
    width: "13",
    height: "18",
    viewBox: "0 0 13 18",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    ...props
  }, /* @__PURE__ */ React.createElement("path", {
    d: "M4.50057 17.2209C4.40918 17.2209 4.31888 17.201 4.23602 17.1625C4.15316 17.1239 4.07974 17.0677 4.02093 16.9977C3.96212 16.9278 3.91934 16.8458 3.8956 16.7575C3.87187 16.6693 3.86774 16.5769 3.88352 16.4869V16.4831L4.84182 11.2208H1.13167C1.02869 11.2208 0.927816 11.1916 0.840716 11.1367C0.753615 11.0817 0.683847 11.0033 0.639483 10.9103C0.595119 10.8174 0.57797 10.7138 0.590021 10.6115C0.602072 10.5092 0.642829 10.4125 0.70758 10.3324L8.17936 1.09571C8.26441 0.987751 8.38247 0.910619 8.5155 0.876109C8.64854 0.841599 8.78921 0.851611 8.91601 0.904613C9.04282 0.957615 9.14877 1.05069 9.21767 1.16961C9.28657 1.28852 9.31463 1.42673 9.29755 1.5631C9.29755 1.57333 9.29482 1.58321 9.29312 1.59344L8.3314 6.85713H12.0409C12.1439 6.85714 12.2447 6.8863 12.3318 6.94125C12.4189 6.99619 12.4887 7.07467 12.5331 7.16761C12.5774 7.26054 12.5946 7.36414 12.5825 7.46642C12.5705 7.56869 12.5297 7.66547 12.465 7.74555L4.99217 16.9822C4.93345 17.0564 4.85875 17.1164 4.77364 17.1577C4.68852 17.199 4.59518 17.2206 4.50057 17.2209Z",
    fill: "#00A4E2"
  }));
}
export function ProductOptionVariableIcon(props) {
  return /* @__PURE__ */ React.createElement("svg", {
    width: "8",
    height: "16",
    viewBox: "0 0 8 16",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    ...props
  }, /* @__PURE__ */ React.createElement("path", {
    d: "M4.00065 14.4932L4 1.69603M1.56082 3.43918L4 0.999999L6.43918 3.43918M1.56082 12.5608L4 15L6.43918 12.5608",
    stroke: "#00A4E2",
    strokeWidth: "1.5",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }));
}
export const ArrowDown = styled(ArrowLeft)`
  transform: rotate(-90deg);
`;
export const ArrowRight = styled(ArrowLeft)`
  transform: rotate(180deg);
`;
export function ProductOptionDummyIcon(props) {
  return /* @__PURE__ */ React.createElement("svg", {
    width: "13",
    height: "16",
    viewBox: "0 0 13 16",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    ...props
  }, /* @__PURE__ */ React.createElement("path", {
    d: "M5.13668 13.4316C5.13713 12.8514 4.94012 12.2883 4.57805 11.8349L5.18056 10.3545L4.73001 10.1112C4.64939 10.0675 4.57328 10.0158 4.50278 9.95713L3.98838 9.44987L3.0987 10.9185C2.57938 10.8089 2.03886 10.8629 1.55147 11.073C1.06407 11.2831 0.653734 11.639 0.37689 12.0919C0.100046 12.5447 -0.0297102 13.0722 0.00546504 13.6018C0.0406403 14.1314 0.239019 14.6371 0.573322 15.0493C0.907624 15.4616 1.36143 15.7601 1.87234 15.9039C2.38324 16.0477 2.92615 16.0297 3.42641 15.8524C3.92666 15.6751 4.3597 15.3471 4.66596 14.9136C4.97222 14.4801 5.13666 13.9624 5.13668 13.4316ZM1.14134 13.4317C1.14134 13.1494 1.22503 12.8736 1.38182 12.6389C1.53861 12.4043 1.76146 12.2214 2.0222 12.1134C2.28293 12.0054 2.56983 11.9771 2.84662 12.0322C3.12342 12.0872 3.37767 12.2231 3.57722 12.4227C3.77678 12.6222 3.91268 12.8765 3.96774 13.1533C4.02279 13.4301 3.99454 13.717 3.88654 13.9777C3.77854 14.2384 3.59565 14.4613 3.36099 14.6181C3.12634 14.7749 2.85046 14.8586 2.56825 14.8586C2.18981 14.8586 1.82687 14.7082 1.55927 14.4406C1.29168 14.173 1.14134 13.8101 1.14134 13.4317Z",
    fill: "#00A4E2"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M3.17447 2.76082e-05L2.72571 0.19694C2.354 0.356753 2.15494 0.722754 2.08895 1.36629C2.05328 1.7273 2.04472 2.32767 2.22486 3.13886C2.38361 3.85232 3.23083 7.49592 4.31599 9.02307C4.82932 9.74544 5.51709 9.88135 6.06966 9.98837C6.57407 10.0875 6.97253 10.1667 7.3182 10.6305C7.7156 11.1656 7.90252 11.6033 7.98171 11.8284C7.60387 12.3043 7.40771 12.8991 7.42834 13.5065C7.44896 14.1138 7.68503 14.6939 8.09428 15.1431C8.50354 15.5923 9.05929 15.8812 9.66207 15.9582C10.2649 16.0351 10.8753 15.895 11.3843 15.563C11.8932 15.231 12.2674 14.7287 12.4399 14.146C12.6124 13.5633 12.5719 12.9383 12.3257 12.3827C12.0795 11.8271 11.6436 11.3773 11.0961 11.1137C10.5486 10.8501 9.9251 10.79 9.33727 10.944L3.17447 2.76082e-05ZM6.27799 8.00925C6.27799 8.12213 6.24451 8.23249 6.1818 8.32635C6.11908 8.42021 6.02994 8.49336 5.92565 8.53656C5.82135 8.57976 5.70659 8.59107 5.59588 8.56904C5.48516 8.54702 5.38346 8.49266 5.30364 8.41284C5.22381 8.33302 5.16945 8.23132 5.14743 8.1206C5.12541 8.00988 5.13671 7.89512 5.17991 7.79083C5.22311 7.68653 5.29627 7.59739 5.39013 7.53468C5.48399 7.47196 5.59434 7.43849 5.70723 7.43849C5.8586 7.43849 6.00378 7.49862 6.11082 7.60566C6.21785 7.7127 6.27799 7.85787 6.27799 8.00925ZM11.4148 13.4315C11.4148 13.7137 11.3312 13.9896 11.1744 14.2242C11.0176 14.4589 10.7947 14.6418 10.534 14.7498C10.2733 14.8578 9.98636 14.886 9.70957 14.831C9.43278 14.7759 9.17852 14.64 8.97897 14.4405C8.77941 14.2409 8.64351 13.9867 8.58846 13.7099C8.5334 13.4331 8.56166 13.1462 8.66965 12.8854C8.77765 12.6247 8.96054 12.4019 9.1952 12.2451C9.42985 12.0883 9.70573 12.0046 9.98794 12.0046C10.3664 12.0046 10.7293 12.1549 10.9969 12.4225C11.2645 12.6901 11.4148 13.0531 11.4148 13.4315Z",
    fill: "#00A4E2"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M6.41602 4.85884L8.13472 7.84607L8.60132 6.86043L8.66339 6.7313C8.98623 6.06422 9.84523 4.28986 9.95224 2.47805C9.96045 2.33928 9.96437 2.21157 9.96437 2.0935C9.9633 1.35543 9.77388 0.963032 9.63119 0.722241L9.13177 -0.000129818L6.41602 4.85884Z",
    fill: "#00A4E2"
  }));
}
export function SingleBoxIcon(props) {
  return /* @__PURE__ */ React.createElement("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    width: "104",
    height: "88",
    fill: "none",
    viewBox: "0 0 104 88",
    ...props
  }, /* @__PURE__ */ React.createElement("g", {
    filter: "url(#filter0_f)",
    opacity: ".6",
    style: {mixBlendMode: "multiply"}
  }, /* @__PURE__ */ React.createElement("ellipse", {
    cx: "51.6146",
    cy: "44.0938",
    fill: "#A48DC0",
    rx: "31.9698",
    ry: "22.9814",
    transform: "rotate(14.137 51.6146 44.0938)"
  })), /* @__PURE__ */ React.createElement("path", {
    fill: "#F5F5F5",
    d: "M45.2693 17.0144l-30.1247 8.6779c-.0856.0247-.1446.103-.1446.1922v25.0336c0 .0861.0552.1626.137.1898l35.4811 11.7857c.0429.0142.0894.0135.1319-.0021l32.1189-11.781c.0787-.0289.1311-.1039.1311-.1878V25.8929c0-.0931-.0642-.1738-.1548-.1948l-37.4753-8.6864c-.0332-.0077-.0678-.0068-.1006.0027z"
  }), /* @__PURE__ */ React.createElement("path", {
    fill: "url(#paint0_linear)",
    d: "M45.2693 17.0144l-30.1247 8.6779c-.0856.0247-.1446.103-.1446.1922v25.0336c0 .0861.0552.1626.137.1898l35.4811 11.7857c.0429.0142.0894.0135.1319-.0021l32.1189-11.781c.0787-.0289.1311-.1039.1311-.1878V25.8929c0-.0931-.0642-.1738-.1548-.1948l-37.4753-8.6864c-.0332-.0077-.0678-.0068-.1006.0027z"
  }), /* @__PURE__ */ React.createElement("path", {
    stroke: "#F5F5F5",
    strokeWidth: ".2",
    d: "M45.2693 17.0144l-30.1247 8.6779c-.0856.0247-.1446.103-.1446.1922v25.0336c0 .0861.0552.1626.137.1898l35.4811 11.7857c.0429.0142.0894.0135.1319-.0021l32.1189-11.781c.0787-.0289.1311-.1039.1311-.1878V25.8929c0-.0931-.0642-.1738-.1548-.1948l-37.4753-8.6864c-.0332-.0077-.0678-.0068-.1006.0027z"
  }), /* @__PURE__ */ React.createElement("path", {
    fill: "#DBDBDB",
    d: "M51.1 62.724V35.3433c0-.1371-.093-.2568-.2258-.2907l-35.6-9.0858c-.1897-.0484-.3742.0949-.3742.2907v24.8023c0 .1297.0833.2447.2066.2851l35.6 11.6642c.194.0636.3934-.081.3934-.2851z"
  }), /* @__PURE__ */ React.createElement("path", {
    fill: "url(#paint1_linear)",
    d: "M51.1 62.724V35.3433c0-.1371-.093-.2568-.2258-.2907l-35.6-9.0858c-.1897-.0484-.3742.0949-.3742.2907v24.8023c0 .1297.0833.2447.2066.2851l35.6 11.6642c.194.0636.3934-.081.3934-.2851z"
  }), /* @__PURE__ */ React.createElement("path", {
    stroke: "#E4E4E4",
    strokeWidth: ".2",
    d: "M51.1 62.724V35.3433c0-.1371-.093-.2568-.2258-.2907l-35.6-9.0858c-.1897-.0484-.3742.0949-.3742.2907v24.8023c0 .1297.0833.2447.2066.2851l35.6 11.6642c.194.0636.3934-.081.3934-.2851z"
  }), /* @__PURE__ */ React.createElement("path", {
    fill: "#E8E3E3",
    d: "M83.1 50.9227V26.0001c0-.1998-.1916-.3437-.3834-.2882l-31.9156 9.242c-.1283.0371-.2166.1546-.2166.2882v27.3871c0 .2086.2075.3535.4033.2817l31.9156-11.7065c.1182-.0434.1967-.1558.1967-.2817z"
  }), /* @__PURE__ */ React.createElement("path", {
    fill: "url(#paint2_linear)",
    d: "M83.1 50.9227V26.0001c0-.1998-.1916-.3437-.3834-.2882l-31.9156 9.242c-.1283.0371-.2166.1546-.2166.2882v27.3871c0 .2086.2075.3535.4033.2817l31.9156-11.7065c.1182-.0434.1967-.1558.1967-.2817z"
  }), /* @__PURE__ */ React.createElement("path", {
    stroke: "#EAE8E8",
    strokeWidth: ".2",
    d: "M83.1 50.9227V26.0001c0-.1998-.1916-.3437-.3834-.2882l-31.9156 9.242c-.1283.0371-.2166.1546-.2166.2882v27.3871c0 .2086.2075.3535.4033.2817l31.9156-11.7065c.1182-.0434.1967-.1558.1967-.2817z"
  }), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("linearGradient", {
    id: "paint0_linear",
    x1: "62.7872",
    x2: "36.0862",
    y1: "21.4917",
    y2: "29.6018",
    gradientUnits: "userSpaceOnUse"
  }, /* @__PURE__ */ React.createElement("stop", {
    stopColor: "#E7E7E7"
  }), /* @__PURE__ */ React.createElement("stop", {
    offset: "1",
    stopColor: "#fff",
    stopOpacity: "0"
  })), /* @__PURE__ */ React.createElement("linearGradient", {
    id: "paint1_linear",
    x1: "33",
    x2: "33",
    y1: "26",
    y2: "63",
    gradientUnits: "userSpaceOnUse"
  }, /* @__PURE__ */ React.createElement("stop", {
    stopColor: "#F9F8F8"
  }), /* @__PURE__ */ React.createElement("stop", {
    offset: "1",
    stopColor: "#fff",
    stopOpacity: "0"
  }), /* @__PURE__ */ React.createElement("stop", {
    offset: "1",
    stopColor: "#FFFCFC"
  })), /* @__PURE__ */ React.createElement("linearGradient", {
    id: "paint2_linear",
    x1: "66.8422",
    x2: "66.8422",
    y1: "25.734",
    y2: "62.9156",
    gradientUnits: "userSpaceOnUse"
  }, /* @__PURE__ */ React.createElement("stop", {
    stopColor: "#fff"
  }), /* @__PURE__ */ React.createElement("stop", {
    offset: "1",
    stopColor: "#fff",
    stopOpacity: "0"
  })), /* @__PURE__ */ React.createElement("filter", {
    id: "filter0_f",
    width: "103.022",
    height: "87.2403",
    x: ".103607",
    y: ".473648",
    colorInterpolationFilters: "sRGB",
    filterUnits: "userSpaceOnUse"
  }, /* @__PURE__ */ React.createElement("feFlood", {
    floodOpacity: "0",
    result: "BackgroundImageFix"
  }), /* @__PURE__ */ React.createElement("feBlend", {
    in: "SourceGraphic",
    in2: "BackgroundImageFix",
    result: "shape"
  }), /* @__PURE__ */ React.createElement("feGaussianBlur", {
    result: "effect1_foregroundBlur",
    stdDeviation: "10"
  }))));
}
export function SingleBoxGreen(props) {
  return /* @__PURE__ */ React.createElement("svg", {
    width: "150",
    height: "116",
    viewBox: "0 0 150 116",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    ...props
  }, /* @__PURE__ */ React.createElement("g", {
    style: {mixBlendMode: "darken"},
    opacity: "0.8",
    filter: "url(#filter0_f)"
  }, /* @__PURE__ */ React.createElement("ellipse", {
    cx: "75.4278",
    cy: "58.0883",
    rx: "55.4527",
    ry: "36.0146",
    transform: "rotate(14.1373 75.4278 58.0883)",
    fill: "#CCD0D4"
  })), /* @__PURE__ */ React.createElement("path", {
    d: "M64.0811 15.0247L12.2489 29.9558C12.1015 29.9983 12 30.1331 12 30.2865V73.359C12 73.5073 12.0949 73.6389 12.2356 73.6856L73.2841 93.9639C73.3579 93.9885 73.438 93.9872 73.511 93.9604L128.774 73.6901C128.91 73.6404 129 73.5114 129 73.367V30.301C129 30.1409 128.89 30.0019 128.734 29.9658L64.254 15.0202C64.1969 15.0069 64.1374 15.0085 64.0811 15.0247Z",
    fill: "#F5F5F5"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M64.0811 15.0247L12.2489 29.9558C12.1015 29.9983 12 30.1331 12 30.2865V73.359C12 73.5073 12.0949 73.6389 12.2356 73.6856L73.2841 93.9639C73.3579 93.9885 73.438 93.9872 73.511 93.9604L128.774 73.6901C128.91 73.6404 129 73.5114 129 73.367V30.301C129 30.1409 128.89 30.0019 128.734 29.9658L64.254 15.0202C64.1969 15.0069 64.1374 15.0085 64.0811 15.0247Z",
    fill: "url(#paint0_linear)"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M64.0811 15.0247L12.2489 29.9558C12.1015 29.9983 12 30.1331 12 30.2865V73.359C12 73.5073 12.0949 73.6389 12.2356 73.6856L73.2841 93.9639C73.3579 93.9885 73.438 93.9872 73.511 93.9604L128.774 73.6901C128.91 73.6404 129 73.5114 129 73.367V30.301C129 30.1409 128.89 30.0019 128.734 29.9658L64.254 15.0202C64.1969 15.0069 64.1374 15.0085 64.0811 15.0247Z",
    stroke: "#F5F5F5",
    strokeWidth: "0.344118"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M74.1132 93.6722V46.5613C74.1132 46.3253 73.9533 46.1194 73.7247 46.0611L12.4718 30.4281C12.1454 30.3448 11.8279 30.5914 11.8279 30.9283V73.6028C11.8279 73.826 11.9713 74.0239 12.1834 74.0934L73.4363 94.1627C73.7701 94.2721 74.1132 94.0234 74.1132 93.6722Z",
    fill: "#DBDBDB"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M74.1132 93.6722V46.5613C74.1132 46.3253 73.9533 46.1194 73.7247 46.0611L12.4718 30.4281C12.1454 30.3448 11.8279 30.5914 11.8279 30.9283V73.6028C11.8279 73.826 11.9713 74.0239 12.1834 74.0934L73.4363 94.1627C73.7701 94.2721 74.1132 94.0234 74.1132 93.6722Z",
    fill: "url(#paint1_linear)"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M74.1132 93.6722V46.5613C74.1132 46.3253 73.9533 46.1194 73.7247 46.0611L12.4718 30.4281C12.1454 30.3448 11.8279 30.5914 11.8279 30.9283V73.6028C11.8279 73.826 11.9713 74.0239 12.1834 74.0934L73.4363 94.1627C73.7701 94.2721 74.1132 94.0234 74.1132 93.6722Z",
    stroke: "#E4E4E4",
    strokeWidth: "0.344118"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M129.172 73.367V30.4854C129.172 30.1417 128.842 29.894 128.512 29.9896L73.5987 45.8912C73.378 45.9552 73.2261 46.1573 73.2261 46.387V93.5091C73.2261 93.8679 73.5832 94.1172 73.9201 93.9937L73.8608 93.8321L73.9201 93.9937L128.834 73.8516C129.037 73.7771 129.172 73.5836 129.172 73.367Z",
    fill: "#E8E3E3"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M129.172 73.367V30.4854C129.172 30.1417 128.842 29.894 128.512 29.9896L73.5987 45.8912C73.378 45.9552 73.2261 46.1573 73.2261 46.387V93.5091C73.2261 93.8679 73.5832 94.1172 73.9201 93.9937L73.8608 93.8321L73.9201 93.9937L128.834 73.8516C129.037 73.7771 129.172 73.5836 129.172 73.367Z",
    fill: "url(#paint2_linear)"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M129.172 73.367V30.4854C129.172 30.1417 128.842 29.894 128.512 29.9896L73.5987 45.8912C73.378 45.9552 73.2261 46.1573 73.2261 46.387V93.5091C73.2261 93.8679 73.5832 94.1172 73.9201 93.9937L73.8608 93.8321L73.9201 93.9937L128.834 73.8516C129.037 73.7771 129.172 73.5836 129.172 73.367Z",
    stroke: "#EAE8E8",
    strokeWidth: "0.344118"
  }), /* @__PURE__ */ React.createElement("g", {
    style: {mixBlendMode: "multiply"},
    opacity: "0.6"
  }, /* @__PURE__ */ React.createElement("path", {
    d: "M64.2771 14.923L64.2545 15.0204L64.2771 14.923C64.2031 14.9057 64.1259 14.9078 64.0529 14.9289L12.2206 29.9353C12.0307 29.9903 11.9 30.1642 11.9 30.3619V73.6548C11.9 73.8459 12.0222 74.0156 12.2035 74.0761L73.2519 94.4566C73.3476 94.4886 73.4514 94.487 73.5461 94.452L128.81 74.0795C128.984 74.0152 129.1 73.8489 129.1 73.6628V30.3764C129.1 30.17 128.958 29.9907 128.757 29.9439L64.2771 14.923Z",
    fill: "#00CC67"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M64.2771 14.923L64.2545 15.0204L64.2771 14.923C64.2031 14.9057 64.1259 14.9078 64.0529 14.9289L12.2206 29.9353C12.0307 29.9903 11.9 30.1642 11.9 30.3619V73.6548C11.9 73.8459 12.0222 74.0156 12.2035 74.0761L73.2519 94.4566C73.3476 94.4886 73.4514 94.487 73.5461 94.452L128.81 74.0795C128.984 74.0152 129.1 73.8489 129.1 73.6628V30.3764C129.1 30.17 128.958 29.9907 128.757 29.9439L64.2771 14.923Z",
    stroke: "#00CC67",
    strokeWidth: "0.2"
  })), /* @__PURE__ */ React.createElement("path", {
    d: "M63.1915 23.5346L69.3133 25.6024L75.553 23.6699L74.2505 27.8955L79.1633 30.9514L72.2368 31.4952L69.0386 35.3202L66.059 31.4301L59.1699 30.7391L64.2509 27.7868L63.1915 23.5346Z",
    fill: "white",
    stroke: "white",
    strokeWidth: "2",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("filter", {
    id: "filter0_f",
    x: "0.931519",
    y: "0.620056",
    width: "148.993",
    height: "114.937",
    filterUnits: "userSpaceOnUse",
    colorInterpolationFilters: "sRGB"
  }, /* @__PURE__ */ React.createElement("feFlood", {
    floodOpacity: "0",
    result: "BackgroundImageFix"
  }), /* @__PURE__ */ React.createElement("feBlend", {
    mode: "normal",
    in: "SourceGraphic",
    in2: "BackgroundImageFix",
    result: "shape"
  }), /* @__PURE__ */ React.createElement("feGaussianBlur", {
    stdDeviation: "10",
    result: "effect1_foregroundBlur"
  })), /* @__PURE__ */ React.createElement("linearGradient", {
    id: "paint0_linear",
    x1: "94.222",
    y1: "22.7284",
    x2: "48.2807",
    y2: "36.6826",
    gradientUnits: "userSpaceOnUse"
  }, /* @__PURE__ */ React.createElement("stop", {
    stopColor: "#E7E7E7"
  }), /* @__PURE__ */ React.createElement("stop", {
    offset: "1",
    stopColor: "white",
    stopOpacity: "0"
  })), /* @__PURE__ */ React.createElement("linearGradient", {
    id: "paint1_linear",
    x1: "42.9706",
    y1: "30.4853",
    x2: "42.9706",
    y2: "94.1471",
    gradientUnits: "userSpaceOnUse"
  }, /* @__PURE__ */ React.createElement("stop", {
    stopColor: "#F9F8F8"
  }), /* @__PURE__ */ React.createElement("stop", {
    offset: "1",
    stopColor: "white",
    stopOpacity: "0"
  }), /* @__PURE__ */ React.createElement("stop", {
    offset: "1",
    stopColor: "#FFFCFC"
  })), /* @__PURE__ */ React.createElement("linearGradient", {
    id: "paint2_linear",
    x1: "101.199",
    y1: "30.0275",
    x2: "101.199",
    y2: "94.0018",
    gradientUnits: "userSpaceOnUse"
  }, /* @__PURE__ */ React.createElement("stop", {
    stopColor: "white"
  }), /* @__PURE__ */ React.createElement("stop", {
    offset: "1",
    stopColor: "white",
    stopOpacity: "0"
  }))));
}
export function StarInCircle(props) {
  return /* @__PURE__ */ React.createElement("svg", {
    width: "48",
    height: "48",
    viewBox: "0 0 48 48",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    ...props
  }, /* @__PURE__ */ React.createElement("rect", {
    width: "48",
    height: "48",
    rx: "24",
    fill: "#FF7E2D"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M24 14L27.09 20.26L34 21.27L29 26.14L30.18 33.02L24 29.77L17.82 33.02L19 26.14L14 21.27L20.91 20.26L24 14Z",
    fill: "white",
    stroke: "white",
    strokeWidth: "2",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }));
}
export function XCircle(props) {
  return /* @__PURE__ */ React.createElement("svg", {
    width: "24",
    height: "24",
    viewBox: "0 0 24 24",
    fill: "none",
    ...props
  }, /* @__PURE__ */ React.createElement("path", {
    d: "M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z",
    fill: "white"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M15 9L9 15",
    stroke: "#001529",
    strokeWidth: "1.5",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M9 9L15 15",
    stroke: "#001529",
    strokeWidth: "1.5",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }));
}
export function BigPlus(props) {
  return /* @__PURE__ */ React.createElement("svg", {
    width: "24",
    height: "24",
    viewBox: "0 0 24 24",
    fill: "none",
    ...props
  }, /* @__PURE__ */ React.createElement("path", {
    d: "M12 5V19",
    stroke: "#00A4E2",
    strokeWidth: "2",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M5 12H19",
    stroke: "#00A4E2",
    strokeWidth: "2",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }));
}
export function Calendar(props) {
  return /* @__PURE__ */ React.createElement("svg", {
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    fill: "none",
    ...props
  }, /* @__PURE__ */ React.createElement("path", {
    d: "M11.125 4.75C11.125 4.81875 11.0688 4.875 11 4.875H10.125C10.0563 4.875 10 4.81875 10 4.75V4H6V4.75C6 4.81875 5.94375 4.875 5.875 4.875H5C4.93125 4.875 4.875 4.81875 4.875 4.75V4H2.875V6.125H13.125V4H11.125V4.75Z",
    fill: "#E3F4FC"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M13.75 2.875H11.125V1.875C11.125 1.80625 11.0688 1.75 11 1.75H10.125C10.0562 1.75 10 1.80625 10 1.875V2.875H6V1.875C6 1.80625 5.94375 1.75 5.875 1.75H5C4.93125 1.75 4.875 1.80625 4.875 1.875V2.875H2.25C1.97344 2.875 1.75 3.09844 1.75 3.375V13.75C1.75 14.0266 1.97344 14.25 2.25 14.25H13.75C14.0266 14.25 14.25 14.0266 14.25 13.75V3.375C14.25 3.09844 14.0266 2.875 13.75 2.875ZM13.125 13.125H2.875V7.1875H13.125V13.125ZM13.125 6.125H2.875V4H4.875V4.75C4.875 4.81875 4.93125 4.875 5 4.875H5.875C5.94375 4.875 6 4.81875 6 4.75V4H10V4.75C10 4.81875 10.0562 4.875 10.125 4.875H11C11.0688 4.875 11.125 4.81875 11.125 4.75V4H13.125V6.125Z",
    fill: "#00A4E2"
  }));
}
export function SizesIcon(props) {
  return /* @__PURE__ */ React.createElement("svg", {
    width: "144",
    height: "92",
    viewBox: "0 0 144 92",
    fill: "none",
    ...props
  }, /* @__PURE__ */ React.createElement("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M97.9165 66.662C98.2452 66.75 98.4402 67.0873 98.3522 67.4154L96.9169 72.7631C96.8289 73.0913 96.491 73.286 96.1623 73.1981C95.8336 73.1102 95.6386 72.7729 95.7267 72.4447L96.7769 68.5315L69.4721 84.2706L73.3916 85.3191C73.7203 85.4071 73.9153 85.7444 73.8273 86.0725C73.7392 86.4007 73.4013 86.5954 73.0727 86.5075L67.7163 85.0746C67.3877 84.9867 67.1926 84.6493 67.2807 84.3212L68.7159 78.9735C68.804 78.6453 69.1418 78.4506 69.4705 78.5385C69.7992 78.6264 69.9942 78.9638 69.9062 79.2919L68.856 83.2051L96.1607 67.466L92.2412 66.4175C91.9125 66.3296 91.7175 65.9923 91.8056 65.6641C91.8936 65.3359 92.2315 65.1412 92.5602 65.2291L97.9165 66.662Z",
    fill: "white",
    opacity: "0.5"
  }), /* @__PURE__ */ React.createElement("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M18.8108 67.4154C18.7227 67.0873 18.9178 66.75 19.2465 66.662L24.6028 65.2291C24.9315 65.1412 25.2694 65.3359 25.3574 65.6641C25.4455 65.9923 25.2504 66.3296 24.9217 66.4175L21.0022 67.466L48.307 83.2051L47.2568 79.2919C47.1687 78.9638 47.3638 78.6264 47.6925 78.5385C48.0212 78.4506 48.359 78.6453 48.4471 78.9735L49.8823 84.3212C49.9704 84.6493 49.7753 84.9867 49.4466 85.0746L44.0903 86.5075C43.7616 86.5954 43.4238 86.4007 43.3357 86.0725C43.2476 85.7444 43.4427 85.4071 43.7714 85.3191L47.6909 84.2706L20.3861 68.5315L21.4363 72.4447C21.5244 72.7729 21.3293 73.1102 21.0007 73.1981C20.672 73.286 20.3341 73.0913 20.246 72.7631L18.8108 67.4154Z",
    fill: "white"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M58.6156 0L25.9783 18.8154V56.4444L58.6165 75.2582L91.2571 56.4444V18.8154L58.6156 0ZM89.0834 19.4559L58.6156 37.0189L28.1512 19.4559L58.6156 1.89384L89.0834 19.4559ZM27.6213 21.0455L57.7949 38.4403V72.8911L27.6213 55.4971V21.0455ZM59.4372 72.8902V38.4394L89.6132 21.0447V55.4963L59.4372 72.8902Z",
    fill: "white"
  }), /* @__PURE__ */ React.createElement("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M103.587 18.4292C103.827 18.189 104.217 18.189 104.458 18.4292L108.379 22.344C108.62 22.5842 108.62 22.9737 108.379 23.2139C108.138 23.4542 107.748 23.4542 107.508 23.2139L104.638 20.3493L104.638 51.8274L107.508 48.9628C107.748 48.7225 108.138 48.7225 108.379 48.9628C108.62 49.203 108.62 49.5925 108.379 49.8327L104.458 53.7475C104.217 53.9877 103.827 53.9877 103.587 53.7475L99.6655 49.8327C99.4248 49.5925 99.4248 49.203 99.6655 48.9628C99.9061 48.7225 100.296 48.7225 100.537 48.9628L103.406 51.8274L103.406 20.3493L100.537 23.2139C100.296 23.4542 99.9061 23.4542 99.6654 23.2139C99.4248 22.9737 99.4248 22.5842 99.6654 22.344L103.587 18.4292Z",
    fill: "white",
    opacity: "0.5"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M116.306 41.8301V33.7922H117.778V37.1336H121.39V33.7922H122.862V41.8301H121.39V38.3279H117.778V41.8301H116.306Z",
    fill: "white",
    opacity: "0.5"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M127.129 41.9679C126.577 41.9679 126.079 41.8416 125.634 41.589C125.197 41.3363 124.848 40.988 124.588 40.544C124.335 40.0924 124.208 39.5718 124.208 38.9824C124.208 38.3929 124.338 37.8762 124.599 37.4322C124.86 36.9805 125.209 36.6284 125.646 36.3758C126.09 36.1232 126.589 35.9968 127.141 35.9968C127.685 35.9968 128.176 36.1232 128.613 36.3758C129.058 36.6284 129.407 36.9805 129.66 37.4322C129.92 37.8762 130.051 38.3929 130.051 38.9824C130.051 39.5718 129.92 40.0924 129.66 40.544C129.407 40.988 129.058 41.3363 128.613 41.589C128.168 41.8416 127.674 41.9679 127.129 41.9679ZM127.129 40.6933C127.513 40.6933 127.846 40.5517 128.13 40.2684C128.414 39.9775 128.556 39.5489 128.556 38.9824C128.556 38.4159 128.414 37.991 128.13 37.7078C127.846 37.4169 127.517 37.2714 127.141 37.2714C126.75 37.2714 126.412 37.4169 126.129 37.7078C125.853 37.991 125.715 38.4159 125.715 38.9824C125.715 39.5489 125.853 39.9775 126.129 40.2684C126.412 40.5517 126.746 40.6933 127.129 40.6933ZM128.176 35.3538C127.977 35.3538 127.808 35.2849 127.67 35.1471C127.532 35.0093 127.463 34.8409 127.463 34.6419C127.463 34.4428 127.532 34.2744 127.67 34.1366C127.808 33.9988 127.977 33.9299 128.176 33.9299C128.375 33.9299 128.54 33.9988 128.671 34.1366C128.809 34.2744 128.878 34.4428 128.878 34.6419C128.878 34.8409 128.809 35.0093 128.671 35.1471C128.54 35.2849 128.375 35.3538 128.176 35.3538ZM126.094 35.3538C125.895 35.3538 125.726 35.2849 125.588 35.1471C125.458 35.0093 125.393 34.8409 125.393 34.6419C125.393 34.4428 125.458 34.2744 125.588 34.1366C125.726 33.9988 125.895 33.9299 126.094 33.9299C126.294 33.9299 126.462 33.9988 126.6 34.1366C126.738 34.2744 126.807 34.4428 126.807 34.6419C126.807 34.8409 126.738 35.0093 126.6 35.1471C126.462 35.2849 126.294 35.3538 126.094 35.3538Z",
    fill: "white",
    opacity: "0.5"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M131.367 41.8301V33.5625H132.839V37.0073C133.031 36.6935 133.288 36.4485 133.61 36.2724C133.939 36.0887 134.315 35.9968 134.737 35.9968C135.442 35.9968 135.987 36.2189 136.37 36.6629C136.761 37.1069 136.957 37.7575 136.957 38.6149V41.8301H135.496V38.7527C135.496 38.2628 135.396 37.8877 135.197 37.6274C135.005 37.3671 134.698 37.237 134.277 37.237C133.863 37.237 133.518 37.3824 133.242 37.6733C132.973 37.9642 132.839 38.37 132.839 38.8905V41.8301H131.367Z",
    fill: "white",
    opacity: "0.5"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M141.136 41.9679C140.561 41.9679 140.051 41.8454 139.606 41.6004C139.162 41.3555 138.813 41.011 138.56 40.567C138.307 40.123 138.18 39.6101 138.18 39.0283C138.18 38.4389 138.303 37.9145 138.548 37.4552C138.801 36.9959 139.146 36.6399 139.583 36.3873C140.028 36.127 140.55 35.9968 141.148 35.9968C141.707 35.9968 142.202 36.1193 142.631 36.3643C143.061 36.6093 143.394 36.9461 143.632 37.3748C143.877 37.7958 144 38.2666 144 38.7872C144 38.8714 143.996 38.9594 143.988 39.0513C143.988 39.1431 143.985 39.2388 143.977 39.3383H139.641C139.672 39.7823 139.825 40.1307 140.101 40.3833C140.385 40.6359 140.726 40.7622 141.125 40.7622C141.424 40.7622 141.673 40.6971 141.872 40.567C142.079 40.4292 142.233 40.2531 142.332 40.0388H143.827C143.72 40.3986 143.54 40.7278 143.287 41.0263C143.042 41.3172 142.735 41.5469 142.367 41.7153C142.006 41.8837 141.596 41.9679 141.136 41.9679ZM141.148 37.1911C140.787 37.1911 140.469 37.2944 140.193 37.5011C139.917 37.7001 139.741 38.0063 139.664 38.4197H142.505C142.482 38.0446 142.344 37.7461 142.091 37.5241C141.838 37.3021 141.523 37.1911 141.148 37.1911Z",
    fill: "white",
    opacity: "0.5"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M92.4818 91.8623V83.8244H95.7137C96.5494 83.8244 97.1858 84.0196 97.6229 84.41C98.0676 84.7928 98.29 85.2865 98.29 85.8913C98.29 86.3965 98.1519 86.8023 97.8759 87.1085C97.6075 87.407 97.2778 87.6099 96.8868 87.717C97.3469 87.8089 97.7264 88.0386 98.0254 88.406C98.3245 88.7658 98.474 89.1868 98.474 89.6691C98.474 90.3045 98.244 90.8289 97.7839 91.2423C97.3239 91.6556 96.6721 91.8623 95.8287 91.8623H92.4818ZM93.954 87.2003H95.4951C95.9092 87.2003 96.2274 87.1046 96.4498 86.9133C96.6721 86.7219 96.7833 86.4501 96.7833 86.098C96.7833 85.7611 96.6721 85.497 96.4498 85.3057C96.2351 85.1066 95.9092 85.0071 95.4721 85.0071H93.954V87.2003ZM93.954 90.6681H95.5987C96.0357 90.6681 96.3731 90.5686 96.6108 90.3696C96.8561 90.1629 96.9788 89.8758 96.9788 89.5084C96.9788 89.1333 96.8523 88.8385 96.5993 88.6242C96.3462 88.4098 96.005 88.3027 95.5757 88.3027H93.954V90.6681Z",
    fill: "white",
    opacity: "0.5"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M99.703 91.8623V86.1669H101.014L101.152 87.2348C101.359 86.8673 101.639 86.5764 101.992 86.3621C102.352 86.1401 102.774 86.0291 103.257 86.0291V87.5793H102.843C102.521 87.5793 102.233 87.629 101.98 87.7285C101.727 87.828 101.528 88.0003 101.382 88.2453C101.244 88.4902 101.175 88.8309 101.175 89.2672V91.8623H99.703Z",
    fill: "white",
    opacity: "0.5"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M106.934 92.0001C106.359 92.0001 105.849 91.8776 105.404 91.6327C104.959 91.3877 104.611 91.0432 104.358 90.5992C104.104 90.1552 103.978 89.6423 103.978 89.0605C103.978 88.4711 104.101 87.9467 104.346 87.4874C104.599 87.0281 104.944 86.6721 105.381 86.4195C105.826 86.1592 106.347 86.0291 106.945 86.0291C107.505 86.0291 108 86.1516 108.429 86.3965C108.858 86.6415 109.192 86.9783 109.43 87.407C109.675 87.828 109.798 88.2988 109.798 88.8194C109.798 88.9036 109.794 88.9916 109.786 89.0835C109.786 89.1754 109.782 89.271 109.775 89.3706H105.439C105.469 89.8146 105.623 90.1629 105.899 90.4155C106.182 90.6681 106.524 90.7944 106.922 90.7944C107.221 90.7944 107.471 90.7294 107.67 90.5992C107.877 90.4614 108.03 90.2854 108.13 90.071H109.625C109.518 90.4308 109.338 90.76 109.085 91.0585C108.839 91.3494 108.532 91.5791 108.164 91.7475C107.804 91.9159 107.394 92.0001 106.934 92.0001ZM106.945 87.2233C106.585 87.2233 106.267 87.3266 105.991 87.5333C105.715 87.7324 105.538 88.0386 105.462 88.4519H108.302C108.279 88.0768 108.141 87.7783 107.888 87.5563C107.635 87.3343 107.321 87.2233 106.945 87.2233Z",
    fill: "white",
    opacity: "0.5"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M111.829 85.2827C111.561 85.2827 111.339 85.2023 111.162 85.0416C110.994 84.8808 110.909 84.6779 110.909 84.433C110.909 84.188 110.994 83.989 111.162 83.8359C111.339 83.6751 111.561 83.5947 111.829 83.5947C112.098 83.5947 112.316 83.6751 112.485 83.8359C112.661 83.989 112.749 84.188 112.749 84.433C112.749 84.6779 112.661 84.8808 112.485 85.0416C112.316 85.2023 112.098 85.2827 111.829 85.2827ZM111.093 91.8623V86.1669H112.565V91.8623H111.093Z",
    fill: "white",
    opacity: "0.5"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M116.644 91.8623C116.046 91.8623 115.567 91.7169 115.206 91.426C114.846 91.1351 114.666 90.6184 114.666 89.8758V87.3955H113.688V86.1669H114.666L114.838 84.6397H116.138V86.1669H117.679V87.3955H116.138V89.8873C116.138 90.1629 116.195 90.3543 116.31 90.4614C116.433 90.5609 116.64 90.6107 116.931 90.6107H117.644V91.8623H116.644Z",
    fill: "white",
    opacity: "0.5"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M121.569 92.0001C120.994 92.0001 120.484 91.8776 120.039 91.6327C119.594 91.3877 119.245 91.0432 118.992 90.5992C118.739 90.1552 118.613 89.6423 118.613 89.0605C118.613 88.4711 118.736 87.9467 118.981 87.4874C119.234 87.0281 119.579 86.6721 120.016 86.4195C120.461 86.1592 120.982 86.0291 121.58 86.0291C122.14 86.0291 122.635 86.1516 123.064 86.3965C123.493 86.6415 123.827 86.9783 124.065 87.407C124.31 87.828 124.433 88.2988 124.433 88.8194C124.433 88.9036 124.429 88.9916 124.421 89.0835C124.421 89.1754 124.417 89.271 124.41 89.3706H120.074C120.104 89.8146 120.258 90.1629 120.534 90.4155C120.817 90.6681 121.159 90.7944 121.557 90.7944C121.856 90.7944 122.105 90.7294 122.305 90.5992C122.512 90.4614 122.665 90.2854 122.765 90.071H124.26C124.153 90.4308 123.972 90.76 123.719 91.0585C123.474 91.3494 123.167 91.5791 122.799 91.7475C122.439 91.9159 122.029 92.0001 121.569 92.0001ZM121.58 87.2233C121.22 87.2233 120.902 87.3266 120.626 87.5333C120.35 87.7324 120.173 88.0386 120.097 88.4519H122.937C122.914 88.0768 122.776 87.7783 122.523 87.5563C122.27 87.3343 121.956 87.2233 121.58 87.2233Z",
    fill: "white",
    opacity: "0.5"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M2.34626 91.8623V85.0071H0V83.8244H6.17619V85.0071H3.81843V91.8623H2.34626Z",
    fill: "white"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M8.02583 85.2827C7.75747 85.2827 7.53511 85.2023 7.35875 85.0416C7.19007 84.8808 7.10573 84.6779 7.10573 84.433C7.10573 84.188 7.19007 83.989 7.35875 83.8359C7.53511 83.6751 7.75747 83.5947 8.02583 83.5947C8.29419 83.5947 8.51272 83.6751 8.6814 83.8359C8.85775 83.989 8.94593 84.188 8.94593 84.433C8.94593 84.6779 8.85775 84.8808 8.6814 85.0416C8.51272 85.2023 8.29419 85.2827 8.02583 85.2827ZM7.28975 91.8623V86.1669H8.76191V91.8623H7.28975Z",
    fill: "white"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M13.0703 92.0001C12.4952 92.0001 11.9854 91.8776 11.5406 91.6327C11.0959 91.3877 10.7471 91.0432 10.494 90.5992C10.241 90.1552 10.1145 89.6423 10.1145 89.0605C10.1145 88.4711 10.2372 87.9467 10.4825 87.4874C10.7355 87.0281 11.0806 86.6721 11.5176 86.4195C11.9624 86.1592 12.4837 86.0291 13.0818 86.0291C13.6415 86.0291 14.1361 86.1516 14.5655 86.3965C14.9949 86.6415 15.3284 86.9783 15.5661 87.407C15.8114 87.828 15.9341 88.2988 15.9341 88.8194C15.9341 88.9036 15.9303 88.9916 15.9226 89.0835C15.9226 89.1754 15.9188 89.271 15.9111 89.3706H11.5751C11.6058 89.8146 11.7592 90.1629 12.0352 90.4155C12.3189 90.6681 12.6601 90.7944 13.0588 90.7944C13.3578 90.7944 13.607 90.7294 13.8064 90.5992C14.0134 90.4614 14.1668 90.2854 14.2664 90.071H15.7616C15.6543 90.4308 15.4741 90.76 15.221 91.0585C14.9757 91.3494 14.669 91.5791 14.3009 91.7475C13.9406 91.9159 13.5304 92.0001 13.0703 92.0001ZM13.0818 87.2233C12.7214 87.2233 12.4032 87.3266 12.1272 87.5333C11.8512 87.7324 11.6748 88.0386 11.5981 88.4519H14.439C14.416 88.0768 14.2779 87.7783 14.0249 87.5563C13.7719 87.3343 13.4575 87.2233 13.0818 87.2233Z",
    fill: "white"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M17.4368 91.8623V87.3955H16.6548V86.1669H17.4368V85.5009C17.4368 84.8119 17.6094 84.322 17.9544 84.0311C18.3071 83.7402 18.7825 83.5947 19.3806 83.5947H20.0131V84.8464H19.6106C19.3576 84.8464 19.1774 84.8961 19.07 84.9956C18.9627 85.0951 18.909 85.2636 18.909 85.5009V86.1669H20.1396V87.3955H18.909V91.8623H17.4368Z",
    fill: "white"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M23.8528 92.0001C23.2777 92.0001 22.7678 91.8776 22.3231 91.6327C21.8784 91.3877 21.5295 91.0432 21.2765 90.5992C21.0234 90.1552 20.8969 89.6423 20.8969 89.0605C20.8969 88.4711 21.0196 87.9467 21.265 87.4874C21.518 87.0281 21.863 86.6721 22.3001 86.4195C22.7448 86.1592 23.2662 86.0291 23.8643 86.0291C24.424 86.0291 24.9185 86.1516 25.3479 86.3965C25.7773 86.6415 26.1108 86.9783 26.3485 87.407C26.5939 87.828 26.7166 88.2988 26.7166 88.8194C26.7166 88.9036 26.7127 88.9916 26.7051 89.0835C26.7051 89.1754 26.7012 89.271 26.6936 89.3706H22.3576C22.3883 89.8146 22.5416 90.1629 22.8176 90.4155C23.1013 90.6681 23.4426 90.7944 23.8413 90.7944C24.1403 90.7944 24.3895 90.7294 24.5888 90.5992C24.7959 90.4614 24.9492 90.2854 25.0489 90.071H26.5441C26.4367 90.4308 26.2565 90.76 26.0035 91.0585C25.7581 91.3494 25.4514 91.5791 25.0834 91.7475C24.723 91.9159 24.3128 92.0001 23.8528 92.0001ZM23.8643 87.2233C23.5039 87.2233 23.1857 87.3266 22.9097 87.5333C22.6336 87.7324 22.4573 88.0386 22.3806 88.4519H25.2214C25.1984 88.0768 25.0604 87.7783 24.8074 87.5563C24.5543 87.3343 24.24 87.2233 23.8643 87.2233Z",
    fill: "white"
  }));
}
export function Info(props) {
  return /* @__PURE__ */ React.createElement("svg", {
    width: "25",
    height: "24",
    viewBox: "0 0 25 24",
    fill: "none",
    ...props
  }, /* @__PURE__ */ React.createElement("path", {
    d: "M12.5 1.5C6.70156 1.5 2 6.20156 2 12C2 17.7984 6.70156 22.5 12.5 22.5C18.2984 22.5 23 17.7984 23 12C23 6.20156 18.2984 1.5 12.5 1.5ZM12.5 20.7188C7.68594 20.7188 3.78125 16.8141 3.78125 12C3.78125 7.18594 7.68594 3.28125 12.5 3.28125C17.3141 3.28125 21.2188 7.18594 21.2188 12C21.2188 16.8141 17.3141 20.7188 12.5 20.7188Z",
    fill: "#FAAD14"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M12.5 3.28125C7.68594 3.28125 3.78125 7.18594 3.78125 12C3.78125 16.8141 7.68594 20.7188 12.5 20.7188C17.3141 20.7188 21.2188 16.8141 21.2188 12C21.2188 7.18594 17.3141 3.28125 12.5 3.28125ZM13.25 17.0625C13.25 17.1656 13.1656 17.25 13.0625 17.25H11.9375C11.8344 17.25 11.75 17.1656 11.75 17.0625V10.6875C11.75 10.5844 11.8344 10.5 11.9375 10.5H13.0625C13.1656 10.5 13.25 10.5844 13.25 10.6875V17.0625ZM12.5 9C12.2056 8.99399 11.9253 8.87282 11.7192 8.6625C11.5132 8.45218 11.3977 8.16945 11.3977 7.875C11.3977 7.58055 11.5132 7.29782 11.7192 7.0875C11.9253 6.87718 12.2056 6.75601 12.5 6.75C12.7944 6.75601 13.0747 6.87718 13.2808 7.0875C13.4868 7.29782 13.6023 7.58055 13.6023 7.875C13.6023 8.16945 13.4868 8.45218 13.2808 8.6625C13.0747 8.87282 12.7944 8.99399 12.5 9Z",
    fill: "#FDDA25",
    fillOpacity: "0.2"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M11.375 7.875C11.375 8.17337 11.4935 8.45952 11.7045 8.6705C11.9155 8.88147 12.2016 9 12.5 9C12.7984 9 13.0845 8.88147 13.2955 8.6705C13.5065 8.45952 13.625 8.17337 13.625 7.875C13.625 7.57663 13.5065 7.29048 13.2955 7.0795C13.0845 6.86853 12.7984 6.75 12.5 6.75C12.2016 6.75 11.9155 6.86853 11.7045 7.0795C11.4935 7.29048 11.375 7.57663 11.375 7.875ZM13.0625 10.5H11.9375C11.8344 10.5 11.75 10.5844 11.75 10.6875V17.0625C11.75 17.1656 11.8344 17.25 11.9375 17.25H13.0625C13.1656 17.25 13.25 17.1656 13.25 17.0625V10.6875C13.25 10.5844 13.1656 10.5 13.0625 10.5Z",
    fill: "#FAAD14"
  }));
}
export function InfoSmall(props) {
  return /* @__PURE__ */ React.createElement("svg", {
    width: "16",
    height: "16",
    viewBox: "0 0 25 24",
    fill: "none",
    ...props
  }, /* @__PURE__ */ React.createElement("path", {
    d: "M12.5 1.5C6.70156 1.5 2 6.20156 2 12C2 17.7984 6.70156 22.5 12.5 22.5C18.2984 22.5 23 17.7984 23 12C23 6.20156 18.2984 1.5 12.5 1.5ZM12.5 20.7188C7.68594 20.7188 3.78125 16.8141 3.78125 12C3.78125 7.18594 7.68594 3.28125 12.5 3.28125C17.3141 3.28125 21.2188 7.18594 21.2188 12C21.2188 16.8141 17.3141 20.7188 12.5 20.7188Z",
    fill: "#00A4E2"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M12.5 3.28125C7.68594 3.28125 3.78125 7.18594 3.78125 12C3.78125 16.8141 7.68594 20.7188 12.5 20.7188C17.3141 20.7188 21.2188 16.8141 21.2188 12C21.2188 7.18594 17.3141 3.28125 12.5 3.28125ZM13.25 17.0625C13.25 17.1656 13.1656 17.25 13.0625 17.25H11.9375C11.8344 17.25 11.75 17.1656 11.75 17.0625V10.6875C11.75 10.5844 11.8344 10.5 11.9375 10.5H13.0625C13.1656 10.5 13.25 10.5844 13.25 10.6875V17.0625ZM12.5 9C12.2056 8.99399 11.9253 8.87282 11.7192 8.6625C11.5132 8.45218 11.3977 8.16945 11.3977 7.875C11.3977 7.58055 11.5132 7.29782 11.7192 7.0875C11.9253 6.87718 12.2056 6.75601 12.5 6.75C12.7944 6.75601 13.0747 6.87718 13.2808 7.0875C13.4868 7.29782 13.6023 7.58055 13.6023 7.875C13.6023 8.16945 13.4868 8.45218 13.2808 8.6625C13.0747 8.87282 12.7944 8.99399 12.5 9Z",
    fill: "#00A4E2",
    fillOpacity: "0"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M11.375 7.875C11.375 8.17337 11.4935 8.45952 11.7045 8.6705C11.9155 8.88147 12.2016 9 12.5 9C12.7984 9 13.0845 8.88147 13.2955 8.6705C13.5065 8.45952 13.625 8.17337 13.625 7.875C13.625 7.57663 13.5065 7.29048 13.2955 7.0795C13.0845 6.86853 12.7984 6.75 12.5 6.75C12.2016 6.75 11.9155 6.86853 11.7045 7.0795C11.4935 7.29048 11.375 7.57663 11.375 7.875ZM13.0625 10.5H11.9375C11.8344 10.5 11.75 10.5844 11.75 10.6875V17.0625C11.75 17.1656 11.8344 17.25 11.9375 17.25H13.0625C13.1656 17.25 13.25 17.1656 13.25 17.0625V10.6875C13.25 10.5844 13.1656 10.5 13.0625 10.5Z",
    fill: "#00A4E2"
  }));
}
export function ArrowHeadRight(props) {
  return /* @__PURE__ */ React.createElement("svg", {
    width: "10",
    height: "16",
    viewBox: "0 0 10 16",
    fill: "none",
    ...props
  }, /* @__PURE__ */ React.createElement("path", {
    d: "M1.625 14.75L8.375 8L1.625 1.25",
    stroke: "#324353",
    strokeWidth: "2",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }));
}
export function ArrowHeadDown(props) {
  return /* @__PURE__ */ React.createElement("svg", {
    width: "16",
    height: "10",
    viewBox: "0 0 16 10",
    fill: "none",
    ...props
  }, /* @__PURE__ */ React.createElement("path", {
    d: "M1.25 1.625L8 8.375L14.75 1.625",
    stroke: "#001529",
    strokeWidth: "2",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }));
}
export function Phone(props) {
  return /* @__PURE__ */ React.createElement("svg", {
    width: "32",
    height: "32",
    viewBox: "0 0 32 32",
    fill: "none",
    ...props
  }, /* @__PURE__ */ React.createElement("path", {
    d: "M9.44738 5.7776L12.9099 9.24323L9.13488 13.0151L9.38488 13.6901C10.1366 15.737 11.3245 17.596 12.8662 19.1382C14.4078 20.6804 16.2664 21.8689 18.313 22.6214L18.988 22.8714L19.0161 22.8432L22.7599 19.0932L26.2224 22.5589L22.9568 25.8245C22.6193 26.1589 22.1443 26.3151 21.6818 26.237C17.9974 25.6276 14.3255 23.6464 11.3411 20.662C8.35676 17.6776 6.37551 14.0026 5.76613 10.3182C5.68801 9.85573 5.84426 9.38073 6.18176 9.04323L9.44738 5.7776Z",
    fill: "#E3F4FC"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M4.59063 7.45937L7.91875 4.13438C8.325 3.72813 8.86875 3.5 9.44375 3.5C10.0188 3.5 10.5625 3.725 10.9688 4.13438L14.5531 7.7125C14.9594 8.11875 15.1875 8.66563 15.1875 9.24062C15.1875 9.81875 14.9625 10.3594 14.5531 10.7687L11.7531 13.5719C12.391 15.0581 13.3075 16.4084 14.4531 17.55C15.6 18.7031 16.9406 19.6125 18.4281 20.2562L21.2281 17.4531C21.6344 17.0469 22.1781 16.8187 22.7531 16.8187C23.0367 16.8177 23.3177 16.8733 23.5796 16.9822C23.8414 17.0911 24.0789 17.2512 24.2781 17.4531L27.8656 21.0312C28.2719 21.4375 28.5 21.9844 28.5 22.5594C28.5 23.1375 28.275 23.6781 27.8656 24.0875L24.5406 27.4125C23.8469 28.1062 22.8906 28.5031 21.9094 28.5031C21.7063 28.5031 21.5094 28.4875 21.3094 28.4531C17.1719 27.7719 13.0656 25.5687 9.75 22.2562C6.4375 18.9375 4.2375 14.8312 3.55 10.6906C3.35312 9.51562 3.74688 8.30625 4.59063 7.45937ZM5.76562 10.3187C6.375 14.0031 8.35625 17.6781 11.3406 20.6625C14.325 23.6469 17.9969 25.6281 21.6812 26.2375C22.1437 26.3156 22.6188 26.1594 22.9563 25.825L26.2219 22.5594L22.7594 19.0937L19.0156 22.8437L18.9875 22.8719L18.3125 22.6219C16.2659 21.8694 14.4073 20.6809 12.8657 19.1387C11.324 17.5965 10.1361 15.7375 9.38437 13.6906L9.13437 13.0156L12.9094 9.24375L9.44687 5.77812L6.18125 9.04375C5.84375 9.38125 5.6875 9.85625 5.76562 10.3187Z",
    fill: "#00A4E2"
  }));
}
export function Mail(props) {
  return /* @__PURE__ */ React.createElement("svg", {
    width: "32",
    height: "32",
    viewBox: "0 0 32 32",
    fill: "none",
    ...props
  }, /* @__PURE__ */ React.createElement("path", {
    d: "M14.9214 16.7596L4.24637 8.45963L3.38699 7.79088L4.24949 8.46275V24.7503H27.7495V8.46275L17.0682 16.7596C16.7612 16.9981 16.3836 17.1276 15.9948 17.1276C15.606 17.1276 15.2284 16.9981 14.9214 16.7596Z",
    fill: "#E3F4FC"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M27.3846 6.21275L28.6127 7.79086L27.7502 8.46273L28.6159 7.79086L27.3877 6.21275H27.3846Z",
    fill: "#E3F4FC"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M29 5H3C2.44687 5 2 5.44687 2 6V26C2 26.5531 2.44687 27 3 27H29C29.5531 27 30 26.5531 30 26V6C30 5.44687 29.5531 5 29 5ZM26.0469 7.25313L16 15.0625L5.95312 7.25313H26.0469ZM27.75 8.4625V24.75H4.25V8.4625L3.3875 7.79063L4.24687 8.45938L14.9219 16.7594C15.2289 16.9979 15.6066 17.1273 15.9953 17.1273C16.3841 17.1273 16.7618 16.9979 17.0688 16.7594L27.75 8.4625L28.6125 7.79063L27.3844 6.2125H27.3875L28.6156 7.79063L27.75 8.4625Z",
    fill: "#00A4E2"
  }));
}
export function SmartPhone(props) {
  return /* @__PURE__ */ React.createElement("svg", {
    width: "33",
    height: "32",
    viewBox: "0 0 33 32",
    fill: "none",
    ...props
  }, /* @__PURE__ */ React.createElement("path", {
    d: "M23.9167 2H9.41666C8.31353 2 7.41666 2.89688 7.41666 4V28C7.41666 29.1031 8.31353 30 9.41666 30H23.9167C25.0198 30 25.9167 29.1031 25.9167 28V4C25.9167 2.89688 25.0198 2 23.9167 2ZM23.6667 27.75H9.66666V4.25H23.6667V27.75Z",
    fill: "#00A4E2"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M9.66666 27.75H23.6667V4.25H9.66666V27.75ZM16.6667 23.3125C17.3573 23.3125 17.9167 23.8719 17.9167 24.5625C17.9167 25.2531 17.3573 25.8125 16.6667 25.8125C15.976 25.8125 15.4167 25.2531 15.4167 24.5625C15.4167 23.8719 15.976 23.3125 16.6667 23.3125Z",
    fill: "#E3F4FC"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M15.4167 24.5625C15.4167 24.894 15.5484 25.212 15.7828 25.4464C16.0172 25.6808 16.3351 25.8125 16.6667 25.8125C16.9982 25.8125 17.3161 25.6808 17.5505 25.4464C17.785 25.212 17.9167 24.894 17.9167 24.5625C17.9167 24.231 17.785 23.913 17.5505 23.6786C17.3161 23.4442 16.9982 23.3125 16.6667 23.3125C16.3351 23.3125 16.0172 23.4442 15.7828 23.6786C15.5484 23.913 15.4167 24.231 15.4167 24.5625Z",
    fill: "#00A4E2"
  }));
}
export function Download(props) {
  return /* @__PURE__ */ React.createElement("svg", {
    width: "24",
    height: "24",
    viewBox: "0 0 24 24",
    fill: "none",
    ...props
  }, /* @__PURE__ */ React.createElement("path", {
    d: "M11.8523 15.4922C11.8699 15.5146 11.8923 15.5327 11.9179 15.5452C11.9435 15.5577 11.9715 15.5641 12 15.5641C12.0285 15.5641 12.0565 15.5577 12.0821 15.5452C12.1077 15.5327 12.1301 15.5146 12.1477 15.4922L14.7727 12.1711C14.8687 12.0492 14.782 11.8687 14.625 11.8687H12.8883V3.9375C12.8883 3.83437 12.8039 3.75 12.7008 3.75H11.2945C11.1914 3.75 11.107 3.83437 11.107 3.9375V11.8664H9.375C9.21797 11.8664 9.13125 12.0469 9.22734 12.1688L11.8523 15.4922ZM20.5781 14.6719H19.1719C19.0688 14.6719 18.9844 14.7563 18.9844 14.8594V18.4688H5.01562V14.8594C5.01562 14.7563 4.93125 14.6719 4.82812 14.6719H3.42188C3.31875 14.6719 3.23438 14.7563 3.23438 14.8594V19.5C3.23438 19.9148 3.56953 20.25 3.98438 20.25H20.0156C20.4305 20.25 20.7656 19.9148 20.7656 19.5V14.8594C20.7656 14.7563 20.6812 14.6719 20.5781 14.6719Z",
    fill: "#00A4E2"
  }));
}
export function ArrowLeftRounded(props) {
  return /* @__PURE__ */ React.createElement("svg", {
    width: "60",
    height: "60",
    viewBox: "0 0 60 60",
    fill: "none",
    ...props
  }, /* @__PURE__ */ React.createElement("path", {
    d: "M30 59.5C13.7076 59.5 0.499998 46.2924 0.499999 30C0.499999 13.7076 13.7076 0.499998 30 0.499999C46.2924 0.499999 59.5 13.7076 59.5 30C59.5 46.2924 46.2924 59.5 30 59.5Z",
    fill: "white",
    stroke: "#CCD0D4"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M41.25 30L18.75 30",
    stroke: "#324353",
    strokeWidth: "2",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M29.25 19.5L18.75 30L29.25 40.5",
    stroke: "#324353",
    strokeWidth: "2",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }));
}
export function ArrowRightRounded(props) {
  return /* @__PURE__ */ React.createElement("svg", {
    width: "60",
    height: "60",
    viewBox: "0 0 60 60",
    fill: "none",
    ...props
  }, /* @__PURE__ */ React.createElement("path", {
    d: "M30 0.5C46.2924 0.500001 59.5 13.7076 59.5 30C59.5 46.2924 46.2924 59.5 30 59.5C13.7076 59.5 0.5 46.2924 0.5 30C0.500001 13.7076 13.7076 0.5 30 0.5Z",
    fill: "white",
    stroke: "#CCD0D4"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M18.75 30L41.25 30",
    stroke: "#324353",
    strokeWidth: "2",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M30.75 40.5L41.25 30L30.75 19.5",
    stroke: "#324353",
    strokeWidth: "2",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }));
}
export function Message(props) {
  return /* @__PURE__ */ React.createElement("svg", {
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    fill: "none",
    ...props
  }, /* @__PURE__ */ React.createElement("path", {
    d: "M12.1146 3.88908C11.5823 3.35503 10.9507 2.93037 10.2552 2.63908C9.54192 2.33939 8.77577 2.18584 8.00208 2.18751H7.97552C6.41771 2.19376 4.9599 2.80314 3.86927 3.9047C2.77708 5.00626 2.18177 6.46876 2.18802 8.02658C2.19271 8.96876 2.42708 9.9047 2.86771 10.7313L2.93802 10.8625V13.0625H5.13802L5.26927 13.1328C6.09583 13.5735 7.03177 13.8078 7.97396 13.8125H8.00052C9.5474 13.8125 11.0005 13.2156 12.0958 12.1313C13.1958 11.0406 13.8068 9.58283 13.8161 8.02658C13.8193 7.23595 13.6677 6.47033 13.3646 5.74845C13.0731 5.05311 12.6485 4.42147 12.1146 3.88908ZM4.87552 8.75001C4.67926 8.74601 4.49239 8.66523 4.35501 8.52501C4.21762 8.3848 4.14068 8.19632 4.14068 8.00001C4.14068 7.80371 4.21762 7.61523 4.35501 7.47501C4.49239 7.3348 4.67926 7.25402 4.87552 7.25001C5.07178 7.25402 5.25865 7.3348 5.39604 7.47501C5.53342 7.61523 5.61037 7.80371 5.61037 8.00001C5.61037 8.19632 5.53342 8.3848 5.39604 8.52501C5.25865 8.66523 5.07178 8.74601 4.87552 8.75001ZM8.00052 8.75001C7.80426 8.74601 7.61739 8.66523 7.48001 8.52501C7.34262 8.3848 7.26568 8.19632 7.26568 8.00001C7.26568 7.80371 7.34262 7.61523 7.48001 7.47501C7.61739 7.3348 7.80426 7.25402 8.00052 7.25001C8.19678 7.25402 8.38366 7.3348 8.52104 7.47501C8.65842 7.61523 8.73537 7.80371 8.73537 8.00001C8.73537 8.19632 8.65842 8.3848 8.52104 8.52501C8.38366 8.66523 8.19678 8.74601 8.00052 8.75001ZM11.1255 8.75001C10.9293 8.74601 10.7424 8.66523 10.605 8.52501C10.4676 8.3848 10.3907 8.19632 10.3907 8.00001C10.3907 7.80371 10.4676 7.61523 10.605 7.47501C10.7424 7.3348 10.9293 7.25402 11.1255 7.25001C11.3218 7.25402 11.5087 7.3348 11.646 7.47501C11.7834 7.61523 11.8604 7.80371 11.8604 8.00001C11.8604 8.19632 11.7834 8.3848 11.646 8.52501C11.5087 8.66523 11.3218 8.74601 11.1255 8.75001Z",
    fill: "#E3F4FC"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M10.375 8C10.375 8.19891 10.454 8.38968 10.5947 8.53033C10.7353 8.67098 10.9261 8.75 11.125 8.75C11.3239 8.75 11.5147 8.67098 11.6553 8.53033C11.796 8.38968 11.875 8.19891 11.875 8C11.875 7.80109 11.796 7.61032 11.6553 7.46967C11.5147 7.32902 11.3239 7.25 11.125 7.25C10.9261 7.25 10.7353 7.32902 10.5947 7.46967C10.454 7.61032 10.375 7.80109 10.375 8ZM4.125 8C4.125 8.19891 4.20402 8.38968 4.34467 8.53033C4.48532 8.67098 4.67609 8.75 4.875 8.75C5.07391 8.75 5.26468 8.67098 5.40533 8.53033C5.54598 8.38968 5.625 8.19891 5.625 8C5.625 7.80109 5.54598 7.61032 5.40533 7.46967C5.26468 7.32902 5.07391 7.25 4.875 7.25C4.67609 7.25 4.48532 7.32902 4.34467 7.46967C4.20402 7.61032 4.125 7.80109 4.125 8Z",
    fill: "#00A4E2"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M14.4564 5.2875C14.1032 4.44844 13.597 3.69531 12.9517 3.04844C12.3109 2.40531 11.5502 1.8941 10.7126 1.54375C9.85324 1.18281 8.94074 1 8.00012 1H7.96887C7.02199 1.00469 6.10481 1.19219 5.24231 1.56094C4.41193 1.91488 3.65834 2.427 3.02356 3.06875C2.38449 3.71406 1.88293 4.46406 1.53606 5.3C1.17668 6.16563 0.995432 7.08594 1.00012 8.03281C1.00542 9.11792 1.26214 10.187 1.75012 11.1562V13.5312C1.75012 13.7219 1.82584 13.9047 1.96064 14.0395C2.09543 14.1743 2.27824 14.25 2.46887 14.25H4.84543C5.81464 14.738 6.88376 14.9947 7.96887 15H8.00168C8.93762 15 9.84543 14.8188 10.7001 14.4641C11.5334 14.1179 12.2913 13.6126 12.9314 12.9766C13.5767 12.3375 14.0845 11.5906 14.4392 10.7578C14.8079 9.89531 14.9954 8.97813 15.0001 8.03125C15.0048 7.07969 14.8204 6.15625 14.4564 5.2875ZM12.0954 12.1313C11.0001 13.2156 9.54699 13.8125 8.00012 13.8125H7.97356C7.03137 13.8078 6.09543 13.5734 5.26887 13.1328L5.13762 13.0625H2.93762V10.8625L2.86731 10.7312C2.42668 9.90469 2.19231 8.96875 2.18762 8.02656C2.18137 6.46875 2.77668 5.00625 3.86887 3.90469C4.95949 2.80312 6.41731 2.19375 7.97512 2.1875H8.00168C8.78293 2.1875 9.54074 2.33906 10.2548 2.63906C10.9517 2.93125 11.5767 3.35156 12.1142 3.88906C12.6501 4.425 13.072 5.05156 13.3642 5.74844C13.6673 6.47031 13.8189 7.23594 13.8157 8.02656C13.8064 9.58281 13.1954 11.0406 12.0954 12.1313Z",
    fill: "#00A4E2"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M7.25 8C7.25 8.19891 7.32902 8.38968 7.46967 8.53033C7.61032 8.67098 7.80109 8.75 8 8.75C8.19891 8.75 8.38968 8.67098 8.53033 8.53033C8.67098 8.38968 8.75 8.19891 8.75 8C8.75 7.80109 8.67098 7.61032 8.53033 7.46967C8.38968 7.32902 8.19891 7.25 8 7.25C7.80109 7.25 7.61032 7.32902 7.46967 7.46967C7.32902 7.61032 7.25 7.80109 7.25 8Z",
    fill: "#1890FF"
  }));
}
export function Copy(props) {
  return /* @__PURE__ */ React.createElement("svg", {
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    fill: "none",
    ...props
  }, /* @__PURE__ */ React.createElement("path", {
    d: "M3.625 11.0312H5.84375C6.18906 11.0312 6.46875 11.3109 6.46875 11.6562V13.875H10.375V4.125H3.625V11.0312Z",
    fill: "#E3F4FC"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M13 1H4.625C4.55625 1 4.5 1.05625 4.5 1.125V2C4.5 2.06875 4.55625 2.125 4.625 2.125H12.375V12.875C12.375 12.9438 12.4313 13 12.5 13H13.375C13.4438 13 13.5 12.9438 13.5 12.875V1.5C13.5 1.22344 13.2766 1 13 1Z",
    fill: "#00A4E2"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M11 3H3C2.72344 3 2.5 3.22344 2.5 3.5V11.7922C2.5 11.925 2.55312 12.0516 2.64687 12.1453L5.35469 14.8531C5.38906 14.8875 5.42813 14.9156 5.47031 14.9391V14.9688H5.53594C5.59062 14.9891 5.64844 15 5.70781 15H11C11.2766 15 11.5 14.7766 11.5 14.5V3.5C11.5 3.22344 11.2766 3 11 3ZM5.46875 13.3781L4.12344 12.0312H5.46875V13.3781ZM10.375 13.875H6.46875V11.6562C6.46875 11.3109 6.18906 11.0312 5.84375 11.0312H3.625V4.125H10.375V13.875Z",
    fill: "#00A4E2"
  }));
}
export function Profile(props) {
  return /* @__PURE__ */ React.createElement("svg", {
    width: "21",
    height: "21",
    viewBox: "0 0 21 21",
    fill: "none",
    ...props
  }, /* @__PURE__ */ React.createElement("g", {
    clipPath: "url(#clip0)"
  }, /* @__PURE__ */ React.createElement("path", {
    d: "M10.4999 19.0852C15.254 19.0852 19.1079 15.2313 19.1079 10.4773C19.1079 5.72324 15.254 1.86932 10.4999 1.86932C5.74588 1.86932 1.89197 5.72324 1.89197 10.4773C1.89197 15.2313 5.74588 19.0852 10.4999 19.0852Z",
    stroke: "#001529",
    strokeWidth: "1.5",
    strokeMiterlimit: "10"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M10.5 13.3466C12.4808 13.3466 14.0866 11.7408 14.0866 9.75999C14.0866 7.77914 12.4808 6.17334 10.5 6.17334C8.51913 6.17334 6.91333 7.77914 6.91333 9.75999C6.91333 11.7408 8.51913 13.3466 10.5 13.3466Z",
    stroke: "#001529",
    strokeWidth: "1.5",
    strokeMiterlimit: "10"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M4.74353 16.8771C5.28387 15.8147 6.10764 14.9226 7.12365 14.2995C8.13967 13.6764 9.3083 13.3466 10.5002 13.3466C11.692 13.3466 12.8607 13.6764 13.8767 14.2995C14.8927 14.9226 15.7165 15.8147 16.2568 16.877",
    stroke: "#001529",
    strokeWidth: "1.5",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  })), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("clipPath", {
    id: "clip0"
  }, /* @__PURE__ */ React.createElement("rect", {
    width: "21",
    height: "21",
    fill: "white"
  }))));
}
export function CloseBanner(props) {
  const {color = "white", ...rest} = props;
  return /* @__PURE__ */ React.createElement("svg", {
    width: "24",
    height: "24",
    viewBox: "0 0 24 24",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    ...rest
  }, /* @__PURE__ */ React.createElement("path", {
    d: "M18 6L6 18",
    stroke: color,
    strokeWidth: "2",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M6 6L18 18",
    stroke: color,
    strokeWidth: "2",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }));
}
export function Play(props) {
  return /* @__PURE__ */ React.createElement("svg", {
    viewBox: "0 0 1024 1024",
    focusable: "false",
    width: "1em",
    height: "1em",
    fill: "currentColor",
    ...props
  }, /* @__PURE__ */ React.createElement("path", {
    d: "M715.8 493.5L335 165.1c-14.2-12.2-35-1.2-35 18.5v656.8c0 19.7 20.8 30.7 35 18.5l380.8-328.4c10.9-9.4 10.9-27.6 0-37z"
  }));
}
export function Pause(props) {
  return /* @__PURE__ */ React.createElement("svg", {
    viewBox: "64 64 896 896",
    focusable: "false",
    width: "1em",
    height: "1em",
    fill: "currentColor",
    ...props
  }, /* @__PURE__ */ React.createElement("path", {
    d: "M304 176h80v672h-80zm408 0h-64c-4.4 0-8 3.6-8 8v656c0 4.4 3.6 8 8 8h64c4.4 0 8-3.6 8-8V184c0-4.4-3.6-8-8-8z"
  }));
}
export function Delivery(props) {
  return /* @__PURE__ */ React.createElement("svg", {
    viewBox: "64 64 896 896",
    focusable: "false",
    "data-icon": "barcode",
    width: "1em",
    height: "1em",
    fill: "currentColor",
    "aria-hidden": "true",
    ...props
  }, /* @__PURE__ */ React.createElement("path", {
    d: "M120 160H72c-4.4 0-8 3.6-8 8v688c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V168c0-4.4-3.6-8-8-8zm833 0h-48c-4.4 0-8 3.6-8 8v688c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V168c0-4.4-3.6-8-8-8zM200 736h112c4.4 0 8-3.6 8-8V168c0-4.4-3.6-8-8-8H200c-4.4 0-8 3.6-8 8v560c0 4.4 3.6 8 8 8zm321 0h48c4.4 0 8-3.6 8-8V168c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v560c0 4.4 3.6 8 8 8zm126 0h178c4.4 0 8-3.6 8-8V168c0-4.4-3.6-8-8-8H647c-4.4 0-8 3.6-8 8v560c0 4.4 3.6 8 8 8zm-255 0h48c4.4 0 8-3.6 8-8V168c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v560c0 4.4 3.6 8 8 8zm-79 64H201c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h112c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zm257 0h-48c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zm256 0H648c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h178c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zm-385 0h-48c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8z"
  }));
}
export function Invoice(props) {
  return /* @__PURE__ */ React.createElement("svg", {
    viewBox: "64 64 896 896",
    focusable: "false",
    "data-icon": "dollar",
    width: "1em",
    height: "1em",
    fill: "currentColor",
    "aria-hidden": "true",
    ...props
  }, /* @__PURE__ */ React.createElement("path", {
    d: "M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372zm47.7-395.2l-25.4-5.9V348.6c38 5.2 61.5 29 65.5 58.2.5 4 3.9 6.9 7.9 6.9h44.9c4.7 0 8.4-4.1 8-8.8-6.1-62.3-57.4-102.3-125.9-109.2V263c0-4.4-3.6-8-8-8h-28.1c-4.4 0-8 3.6-8 8v33c-70.8 6.9-126.2 46-126.2 119 0 67.6 49.8 100.2 102.1 112.7l24.7 6.3v142.7c-44.2-5.9-69-29.5-74.1-61.3-.6-3.8-4-6.6-7.9-6.6H363c-4.7 0-8.4 4-8 8.7 4.5 55 46.2 105.6 135.2 112.1V761c0 4.4 3.6 8 8 8h28.4c4.4 0 8-3.6 8-8.1l-.2-31.7c78.3-6.9 134.3-48.8 134.3-124-.1-69.4-44.2-100.4-109-116.4zm-68.6-16.2c-5.6-1.6-10.3-3.1-15-5-33.8-12.2-49.5-31.9-49.5-57.3 0-36.3 27.5-57 64.5-61.7v124zM534.3 677V543.3c3.1.9 5.9 1.6 8.8 2.2 47.3 14.4 63.2 34.4 63.2 65.1 0 39.1-29.4 62.6-72 66.4z"
  }));
}
export function Home() {
  return /* @__PURE__ */ React.createElement("svg", {
    width: "16",
    height: "14",
    viewBox: "0 0 16 14",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, /* @__PURE__ */ React.createElement("path", {
    d: "M14.8588 6.96903C14.8588 6.96747 14.8573 6.96747 14.8573 6.96591L14.8292 6.9331C14.8276 6.93153 14.826 6.92841 14.8229 6.92685C14.812 6.91435 14.7995 6.90185 14.7885 6.88935L8.75103 0.856534L8.34634 0.451846C8.25413 0.360247 8.12943 0.308838 7.99946 0.308838C7.86949 0.308838 7.7448 0.360247 7.65259 0.451846L1.2104 6.89091C1.09402 7.00668 1.00813 7.14947 0.9604 7.30653L0.951025 7.33935L0.946338 7.35653L0.94165 7.37528C0.938525 7.38622 0.936963 7.39716 0.9354 7.4081C0.9354 7.40966 0.9354 7.41278 0.933838 7.41435C0.924463 7.46122 0.919775 7.5081 0.918213 7.55653V7.6081C0.918213 7.61591 0.918213 7.62372 0.919775 7.63153C0.919775 7.63935 0.919775 7.6456 0.921338 7.65341C0.921338 7.66122 0.9229 7.66903 0.9229 7.67685C0.9229 7.68622 0.924463 7.6956 0.926025 7.70497C0.926025 7.70966 0.927588 7.71435 0.927588 7.71903L0.932275 7.7581V7.75966C1.01196 8.23622 1.4354 8.5956 1.93228 8.5956H2.59634V13.6878H13.4042V8.59872H14.0823C14.2167 8.59872 14.3463 8.57216 14.4651 8.52216C14.5838 8.47216 14.6948 8.39872 14.7901 8.30497C14.8832 8.21218 14.9569 8.10184 15.0071 7.98034C15.0573 7.85884 15.0828 7.7286 15.0823 7.59716C15.0823 7.36747 15.0042 7.14716 14.8588 6.96903ZM8.87446 12.5628H7.12446V9.37528H8.87446V12.5628ZM12.2792 7.47372V12.5628H9.87446V9.00028C9.87446 8.65497 9.59478 8.37528 9.24946 8.37528H6.74946C6.40415 8.37528 6.12446 8.65497 6.12446 9.00028V12.5628H3.71978V7.47372H2.21978L8.00103 1.69716L8.36196 2.0581L13.7807 7.47372H12.2792Z",
    fill: "#00A4E2"
  }));
}
export function Redquestion() {
  return /* @__PURE__ */ React.createElement("svg", {
    width: "25",
    height: "24",
    viewBox: "0 0 25 24",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, /* @__PURE__ */ React.createElement("path", {
    d: "M12.5 1.5C6.70156 1.5 2 6.20156 2 12C2 17.7984 6.70156 22.5 12.5 22.5C18.2984 22.5 23 17.7984 23 12C23 6.20156 18.2984 1.5 12.5 1.5ZM12.5 20.7188C7.68594 20.7188 3.78125 16.8141 3.78125 12C3.78125 7.18594 7.68594 3.28125 12.5 3.28125C17.3141 3.28125 21.2188 7.18594 21.2188 12C21.2188 16.8141 17.3141 20.7188 12.5 20.7188Z",
    fill: "#E21725"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M12.5 3.28125C7.68594 3.28125 3.78125 7.18594 3.78125 12C3.78125 16.8141 7.68594 20.7188 12.5 20.7188C17.3141 20.7188 21.2188 16.8141 21.2188 12C21.2188 7.18594 17.3141 3.28125 12.5 3.28125ZM12.5 18.0938C11.982 18.0938 11.5625 17.6742 11.5625 17.1562C11.5625 16.6383 11.982 16.2188 12.5 16.2188C13.018 16.2188 13.4375 16.6383 13.4375 17.1562C13.4375 17.6742 13.018 18.0938 12.5 18.0938ZM13.9742 12.9492C13.762 13.0312 13.5793 13.1752 13.4501 13.3625C13.321 13.5498 13.2512 13.7717 13.25 13.9992V14.5312C13.25 14.6344 13.1656 14.7188 13.0625 14.7188H11.9375C11.8344 14.7188 11.75 14.6344 11.75 14.5312V14.0273C11.75 13.4859 11.907 12.9516 12.2164 12.5062C12.5188 12.0703 12.9406 11.7375 13.4375 11.5477C14.2344 11.2406 14.75 10.5727 14.75 9.84375C14.75 8.81016 13.7398 7.96875 12.5 7.96875C11.2602 7.96875 10.25 8.81016 10.25 9.84375V10.0219C10.25 10.125 10.1656 10.2094 10.0625 10.2094H8.9375C8.83437 10.2094 8.75 10.125 8.75 10.0219V9.84375C8.75 8.92266 9.15312 8.0625 9.88437 7.42266C10.5875 6.80625 11.5156 6.46875 12.5 6.46875C13.4844 6.46875 14.4125 6.80859 15.1156 7.42266C15.8469 8.0625 16.25 8.92266 16.25 9.84375C16.25 11.1984 15.357 12.4172 13.9742 12.9492Z",
    fill: "#FCE8E9"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M11.5625 17.1562C11.5625 17.4049 11.6613 17.6433 11.8371 17.8192C12.0129 17.995 12.2514 18.0938 12.5 18.0938C12.7486 18.0938 12.9871 17.995 13.1629 17.8192C13.3387 17.6433 13.4375 17.4049 13.4375 17.1562C13.4375 16.9076 13.3387 16.6692 13.1629 16.4933C12.9871 16.3175 12.7486 16.2188 12.5 16.2188C12.2514 16.2188 12.0129 16.3175 11.8371 16.4933C11.6613 16.6692 11.5625 16.9076 11.5625 17.1562ZM15.1156 7.42266C14.4125 6.80859 13.4844 6.46875 12.5 6.46875C11.5156 6.46875 10.5875 6.80625 9.88437 7.42266C9.15312 8.0625 8.75 8.92266 8.75 9.84375V10.0219C8.75 10.125 8.83437 10.2094 8.9375 10.2094H10.0625C10.1656 10.2094 10.25 10.125 10.25 10.0219V9.84375C10.25 8.81016 11.2602 7.96875 12.5 7.96875C13.7398 7.96875 14.75 8.81016 14.75 9.84375C14.75 10.5727 14.2344 11.2406 13.4375 11.5477C12.9406 11.7375 12.5187 12.0703 12.2164 12.5062C11.907 12.9516 11.75 13.4859 11.75 14.0273V14.5312C11.75 14.6344 11.8344 14.7188 11.9375 14.7188H13.0625C13.1656 14.7188 13.25 14.6344 13.25 14.5312V13.9992C13.2512 13.7717 13.321 13.5498 13.4501 13.3625C13.5793 13.1752 13.762 13.0312 13.9742 12.9492C15.357 12.4172 16.25 11.1984 16.25 9.84375C16.25 8.92266 15.8469 8.0625 15.1156 7.42266Z",
    fill: "#E21725"
  }));
}
export function Smile() {
  return /* @__PURE__ */ React.createElement("svg", {
    width: "24",
    height: "24",
    viewBox: "0 0 24 24",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, /* @__PURE__ */ React.createElement("path", {
    d: "M12 1.5C6.20156 1.5 1.5 6.20156 1.5 12C1.5 17.7984 6.20156 22.5 12 22.5C17.7984 22.5 22.5 17.7984 22.5 12C22.5 6.20156 17.7984 1.5 12 1.5ZM12 20.7188C7.18594 20.7188 3.28125 16.8141 3.28125 12C3.28125 7.18594 7.18594 3.28125 12 3.28125C16.8141 3.28125 20.7188 7.18594 20.7188 12C20.7188 16.8141 16.8141 20.7188 12 20.7188Z",
    fill: "#A873E9"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M12 3.28125C7.18594 3.28125 3.28125 7.18594 3.28125 12C3.28125 16.8141 7.18594 20.7188 12 20.7188C16.8141 20.7188 20.7188 16.8141 20.7188 12C20.7188 7.18594 16.8141 3.28125 12 3.28125ZM6.75 9.86719C6.75601 9.5728 6.87718 9.29249 7.0875 9.08641C7.29782 8.88034 7.58055 8.76492 7.875 8.76492C8.16945 8.76492 8.45218 8.88034 8.6625 9.08641C8.87282 9.29249 8.99399 9.5728 9 9.86719C8.99399 10.1616 8.87282 10.4419 8.6625 10.648C8.45218 10.854 8.16945 10.9695 7.875 10.9695C7.58055 10.9695 7.29782 10.854 7.0875 10.648C6.87718 10.4419 6.75601 10.1616 6.75 9.86719ZM12 16.2422C9.99609 16.2422 8.35313 14.6648 8.25 12.6891C8.24873 12.6637 8.25264 12.6383 8.26149 12.6144C8.27034 12.5906 8.28395 12.5688 8.30149 12.5504C8.31903 12.532 8.34013 12.5173 8.36351 12.5073C8.3869 12.4973 8.41207 12.4922 8.4375 12.4922H9.56484C9.66328 12.4922 9.74766 12.5672 9.75469 12.6656C9.84375 13.8258 10.8164 14.7422 12 14.7422C13.1836 14.7422 14.1586 13.8258 14.2453 12.6656C14.2523 12.5672 14.3367 12.4922 14.4352 12.4922H15.5625C15.5879 12.4922 15.6131 12.4973 15.6365 12.5073C15.6599 12.5173 15.681 12.532 15.6985 12.5504C15.716 12.5688 15.7297 12.5906 15.7385 12.6144C15.7474 12.6383 15.7513 12.6637 15.75 12.6891C15.6469 14.6648 14.0039 16.2422 12 16.2422ZM16.125 10.9922C15.8306 10.9862 15.5503 10.865 15.3442 10.6547C15.1382 10.4444 15.0227 10.1616 15.0227 9.86719C15.0227 9.57273 15.1382 9.29001 15.3442 9.07969C15.5503 8.86936 15.8306 8.7482 16.125 8.74219C16.4194 8.7482 16.6997 8.86936 16.9058 9.07969C17.1118 9.29001 17.2273 9.57273 17.2273 9.86719C17.2273 10.1616 17.1118 10.4444 16.9058 10.6547C16.6997 10.865 16.4194 10.9862 16.125 10.9922Z",
    fill: "#F6F1FD"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M6.75 9.86719C6.75 10.1656 6.86853 10.4517 7.0795 10.6627C7.29048 10.8737 7.57663 10.9922 7.875 10.9922C8.17337 10.9922 8.45952 10.8737 8.6705 10.6627C8.88147 10.4517 9 10.1656 9 9.86719C9 9.56882 8.88147 9.28267 8.6705 9.07169C8.45952 8.86071 8.17337 8.74219 7.875 8.74219C7.57663 8.74219 7.29048 8.86071 7.0795 9.07169C6.86853 9.28267 6.75 9.56882 6.75 9.86719ZM15.5625 12.4922H14.4352C14.3367 12.4922 14.2523 12.5672 14.2453 12.6656C14.1586 13.8258 13.1836 14.7422 12 14.7422C10.8164 14.7422 9.84375 13.8258 9.75469 12.6656C9.74766 12.5672 9.66328 12.4922 9.56484 12.4922H8.4375C8.41207 12.4922 8.3869 12.4973 8.36351 12.5073C8.34013 12.5173 8.31903 12.532 8.30149 12.5504C8.28395 12.5688 8.27034 12.5906 8.26149 12.6144C8.25264 12.6383 8.24873 12.6637 8.25 12.6891C8.35312 14.6648 9.99609 16.2422 12 16.2422C14.0039 16.2422 15.6469 14.6648 15.75 12.6891C15.7513 12.6637 15.7474 12.6383 15.7385 12.6144C15.7297 12.5906 15.716 12.5688 15.6985 12.5504C15.681 12.532 15.6599 12.5173 15.6365 12.5073C15.6131 12.4973 15.5879 12.4922 15.5625 12.4922ZM15 9.86719C15 10.1656 15.1185 10.4517 15.3295 10.6627C15.5405 10.8737 15.8266 10.9922 16.125 10.9922C16.4234 10.9922 16.7095 10.8737 16.9205 10.6627C17.1315 10.4517 17.25 10.1656 17.25 9.86719C17.25 9.56882 17.1315 9.28267 16.9205 9.07169C16.7095 8.86071 16.4234 8.74219 16.125 8.74219C15.8266 8.74219 15.5405 8.86071 15.3295 9.07169C15.1185 9.28267 15 9.56882 15 9.86719Z",
    fill: "#A873E9"
  }));
}
export function Visitenkarte() {
  return /* @__PURE__ */ React.createElement("svg", {
    width: "24",
    height: "24",
    viewBox: "0 0 24 24",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, /* @__PURE__ */ React.createElement("path", {
    d: "M21.75 3.75H2.25C1.83516 3.75 1.5 4.08516 1.5 4.5V19.5C1.5 19.9148 1.83516 20.25 2.25 20.25H21.75C22.1648 20.25 22.5 19.9148 22.5 19.5V4.5C22.5 4.08516 22.1648 3.75 21.75 3.75ZM20.8125 18.5625H3.1875V5.4375H20.8125V18.5625Z",
    fill: "#FF7E2D"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M3.1875 18.5625H20.8125V5.4375H3.1875V18.5625ZM14.25 9.84375C14.25 9.74062 14.2734 9.65625 14.3039 9.65625H17.1961C17.2266 9.65625 17.25 9.74062 17.25 9.84375V10.9688C17.25 11.0719 17.2266 11.1562 17.1961 11.1562H14.3039C14.2734 11.1562 14.25 11.0719 14.25 10.9688V9.84375ZM14.25 13.2188C14.25 13.1156 14.325 13.0312 14.4164 13.0312H18.7687C18.8602 13.0312 18.9352 13.1156 18.9352 13.2188V14.3438C18.9352 14.4469 18.8602 14.5312 18.7687 14.5312H14.4164C14.325 14.5312 14.25 14.4469 14.25 14.3438V13.2188ZM5.06719 15.5766C5.13281 14.3273 5.81484 13.2422 6.81562 12.6211C6.39141 12.1523 6.13359 11.5336 6.13359 10.8516C6.13359 9.40078 7.30313 8.22656 8.74453 8.22656C10.1859 8.22656 11.3555 9.40078 11.3555 10.8516C11.3555 11.5336 11.0977 12.1547 10.6734 12.6211C11.6719 13.2398 12.3562 14.3273 12.4219 15.5766C12.4231 15.602 12.4192 15.6274 12.4104 15.6512C12.4015 15.675 12.3879 15.6968 12.3704 15.7152C12.3528 15.7337 12.3317 15.7483 12.3084 15.7583C12.285 15.7683 12.2598 15.7735 12.2344 15.7734H11.2055C11.107 15.7734 11.0273 15.6961 11.0203 15.5977C10.9313 14.4141 9.94219 13.4766 8.74219 13.4766C7.54219 13.4766 6.55312 14.4141 6.46406 15.5977C6.45703 15.6961 6.37734 15.7734 6.27891 15.7734H5.25C5.14219 15.7734 5.05781 15.6844 5.06719 15.5766Z",
    fill: "#FFF2EA"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M7.52979 10.8516C7.52979 11.1748 7.65745 11.4848 7.88469 11.7133C8.11193 11.9419 8.42014 12.0703 8.7415 12.0703C9.06287 12.0703 9.37108 11.9419 9.59832 11.7133C9.82556 11.4848 9.95322 11.1748 9.95322 10.8516C9.95322 10.6915 9.92188 10.533 9.86099 10.3852C9.80009 10.2373 9.71084 10.1029 9.59832 9.98978C9.4858 9.8766 9.35222 9.78683 9.20521 9.72558C9.0582 9.66434 8.90063 9.63281 8.7415 9.63281C8.58238 9.63281 8.42481 9.66434 8.2778 9.72558C8.13079 9.78683 7.99721 9.8766 7.88469 9.98978C7.77217 10.1029 7.68292 10.2373 7.62202 10.3852C7.56113 10.533 7.52979 10.6915 7.52979 10.8516Z",
    fill: "#FFF2EA"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M14.3036 11.1562H17.1958C17.2262 11.1562 17.2497 11.0719 17.2497 10.9688V9.84375C17.2497 9.74063 17.2262 9.65625 17.1958 9.65625H14.3036C14.2731 9.65625 14.2497 9.74063 14.2497 9.84375V10.9688C14.2497 11.0719 14.2731 11.1562 14.3036 11.1562ZM14.4161 14.5312H18.7684C18.8598 14.5312 18.9348 14.4469 18.9348 14.3438V13.2188C18.9348 13.1156 18.8598 13.0312 18.7684 13.0312H14.4161C14.3247 13.0312 14.2497 13.1156 14.2497 13.2188V14.3438C14.2497 14.4469 14.3247 14.5312 14.4161 14.5312ZM5.24969 15.7734H6.27859C6.37703 15.7734 6.45672 15.6961 6.46375 15.5977C6.55281 14.4141 7.54187 13.4766 8.74187 13.4766C9.94187 13.4766 10.9309 14.4141 11.02 15.5977C11.027 15.6961 11.1067 15.7734 11.2052 15.7734H12.2341C12.2595 15.7735 12.2847 15.7683 12.308 15.7583C12.3314 15.7483 12.3525 15.7337 12.3701 15.7152C12.3876 15.6968 12.4012 15.675 12.4101 15.6512C12.4189 15.6274 12.4228 15.602 12.4216 15.5766C12.3559 14.3273 11.6716 13.2398 10.6731 12.6211C11.1134 12.1371 11.3567 11.5059 11.3552 10.8516C11.3552 9.40078 10.1856 8.22656 8.74422 8.22656C7.30281 8.22656 6.13328 9.40078 6.13328 10.8516C6.13328 11.5336 6.39109 12.1523 6.81531 12.6211C6.30702 12.9361 5.88251 13.3694 5.57805 13.8841C5.27358 14.3987 5.09819 14.9794 5.06687 15.5766C5.0575 15.6844 5.14187 15.7734 5.24969 15.7734ZM8.74187 9.63281C9.40984 9.63281 9.95359 10.1789 9.95359 10.8516C9.95359 11.5242 9.40984 12.0703 8.74187 12.0703C8.07391 12.0703 7.53016 11.5242 7.53016 10.8516C7.53016 10.1789 8.07391 9.63281 8.74187 9.63281Z",
    fill: "#FF7E2D"
  }));
}
