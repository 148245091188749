import styled from "../_snowpack/pkg/@emotion/styled.js";
import React, {useState} from "../_snowpack/pkg/react.js";
import Modal from "./modal.js";
import Button from "./button.js";
import {t} from "../common/i18n.js";
import {unmountComponentAtNode, render} from "../_snowpack/pkg/react-dom.js";
import {useEffect} from "../_snowpack/pkg/react.js";
import {withAttrs} from "../common/utils.js";
import {fontFamily, gap} from "./theme.js";
import Icon from "./icon.js";
export function alert(options) {
  const {
    title = t("Ein Fehler ist aufgetreten!"),
    message,
    okText = t("Weiter"),
    icon
  } = options;
  return confirm({
    title,
    message,
    okText,
    cancelText: null,
    icon
  });
}
export function confirm(options) {
  const el = document.createElement("div");
  document.body.appendChild(el);
  return new Promise((resolve) => {
    const clean = () => {
      unmountComponentAtNode(el);
      return el.parentNode && el.parentNode.removeChild(el);
    };
    const resolveAndClean = (res) => {
      return () => {
        setTimeout(clean, 1e3);
        resolve(res);
      };
    };
    render(/* @__PURE__ */ React.createElement(Confirm, {
      ...options,
      onConfirm: resolveAndClean(true),
      onCancel: resolveAndClean(false)
    }), el);
  });
}
export function Confirm(props) {
  const {
    title,
    message,
    okText = t("Weiter"),
    cancelText = t("Abbrechen"),
    onCancel,
    onConfirm,
    active: initialActive = true,
    icon,
    ...rest
  } = props;
  const [active, setActive] = useState(initialActive);
  const onCancelClick = () => {
    setActive(false);
    onCancel && onCancel();
  };
  const onConfirmClick = () => {
    setActive(false);
    onConfirm && onConfirm();
  };
  useEffect(() => {
    setActive(initialActive);
  }, [initialActive]);
  const iconType = icon ? icon : "info";
  return /* @__PURE__ */ React.createElement(Container, {
    title,
    active,
    icon: title && /* @__PURE__ */ React.createElement(Icon, {
      name: iconType
    }),
    footer: /* @__PURE__ */ React.createElement(Buttons, null, cancelText && /* @__PURE__ */ React.createElement(Btn, {
      onClick: onCancelClick,
      secondary: true
    }, cancelText), okText && /* @__PURE__ */ React.createElement(Btn, {
      onClick: onConfirmClick
    }, okText)),
    ...rest
  }, /* @__PURE__ */ React.createElement(Message, null, message));
}
const Container = styled(Modal)`
  .modal-content {
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }
`;
const Message = styled.div`
  font-family: ${fontFamily.primary};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #4c5b69;
  max-width: 360px;
`;
const Buttons = styled.div`
  display: flex;
  justify-content: center;
  ${gap("24px")}
`;
const Btn = styled(withAttrs(Button, {small: true}))`
  min-width: 167px;
  font-size: 14px;
  // &:first-of-type {
  //   margin-left: auto;
  // }
`;
