import React, {Children} from "../_snowpack/pkg/react.js";
import isPropValid from "../_snowpack/pkg/@emotion/is-prop-valid.js";
import {isFragment} from "../_snowpack/pkg/react-is.js";
import {
  getUrl,
  getGlobalProps,
  getCurrentQueryString
} from "./universal.js";
import {Routes} from "../typings/routes.js";
export function getLinkUrl(link) {
  const {url, cached_url, story} = link;
  if (story) {
    return `/${story.full_slug}`;
  }
  return url || (cached_url?.startsWith("/") ? cached_url : `/${cached_url}`);
}
export function goTo(route, data, options) {
  location.href = `${location.origin}${getUrl(route, data, options)}`;
}
export function flattenChildren(children) {
  const childs = Children.toArray(children);
  return childs.reduce((children2, child) => {
    if (!child)
      return children2;
    if (isFragment(child)) {
      return [...children2, ...flattenChildren(child.props.children)];
    }
    return [...children2, child];
  }, []);
}
export function withAttrs(C, attrs) {
  return function CWithAttrs(props) {
    const mergedProps = {...attrs, ...props};
    return /* @__PURE__ */ React.createElement(C, {
      ...mergedProps
    });
  };
}
export function stopForward(...props) {
  return {
    shouldForwardProp: (prop) => isPropValid(prop) && !props.includes(prop)
  };
}
export function getTranslatedName(page) {
  const lang = getGlobalProps().locale;
  const {name, translated_slugs} = page;
  const condidate = translated_slugs.find((t) => t.lang === lang);
  return condidate && condidate.name ? condidate.name : name;
}
export function getTranslatedSlug(page) {
  const lang = getGlobalProps().locale;
  const {slug, translated_slugs} = page;
  const condidate = translated_slugs.find((t) => t.lang === lang);
  if (!condidate || !condidate.path)
    return slug;
  return condidate.path.split("/").pop();
}
export function rgba(hex, a) {
  let chars = hex.substring(1).split("");
  if (chars.length === 3) {
    chars = [chars[0], chars[0], chars[1], chars[1], chars[2], chars[2]];
  }
  hex = chars.join("");
  const r = parseInt(hex.slice(0, 2), 16);
  const g = parseInt(hex.slice(2, 4), 16);
  const b = parseInt(hex.slice(4, 6), 16);
  return `rgba(${r}, ${g}, ${b}, ${a})`;
}
export function shadeColor(hex, percent) {
  let chars = hex.substring(1).split("");
  if (chars.length === 3) {
    chars = [chars[0], chars[0], chars[1], chars[1], chars[2], chars[2]];
  }
  hex = chars.join("");
  let r = parseInt(hex.slice(0, 2), 16);
  let g = parseInt(hex.slice(2, 4), 16);
  let b = parseInt(hex.slice(4, 6), 16);
  r = Math.trunc(r * (100 + percent) / 100);
  g = Math.trunc(g * (100 + percent) / 100);
  b = Math.trunc(b * (100 + percent) / 100);
  r = r < 255 ? r : 255;
  g = g < 255 ? g : 255;
  b = b < 255 ? b : 255;
  const rr = r.toString(16).length == 1 ? "0" + r.toString(16) : r.toString(16);
  const gg = g.toString(16).length == 1 ? "0" + g.toString(16) : g.toString(16);
  const bb = b.toString(16).length == 1 ? "0" + b.toString(16) : b.toString(16);
  return "#" + rr + gg + bb;
}
export function fixLineBreaks(str) {
  const parts = str.split("\n");
  return parts.map((s, i) => i === 0 ? [s] : [/* @__PURE__ */ React.createElement("br", {
    key: i
  }), s]).flat();
}
export function getFormattedroductName(product) {
  const {page} = product;
  return getTranslatedName(page);
}
export function getQuantityOptions(article) {
  const {
    cf_quantity_maximum: max = 2e3,
    cf_quantity_minimum: min = 1,
    cf_quantity_step: step = 1
  } = article;
  return {min, max, step};
}
export function getValidReorderQuantity(input) {
  const {min, max, step, quantity} = input;
  if (quantity <= min) {
    return min;
  }
  if (quantity >= max) {
    return max;
  }
  const rest = quantity % step;
  return quantity - rest;
}
export function getPrintTemplateUrlForProduct(product, article, config) {
  const pageProduct = getPageProduct(product.page);
  if (pageProduct.component === "model_product") {
    return;
  }
  if (pageProduct.component === "variable_product") {
    const [editor] = pageProduct.editor || [];
    if (!editor)
      return;
    if (editor.component === "editor_2d")
      return;
    if (!config)
      return;
    return getUrl(Routes.getVariablePrintTemplate, {slug: product.page.slug}, {query: {config, article_id: article.id}});
  }
  const {print_template = []} = pageProduct;
  const [printTemplate] = print_template;
  if (!printTemplate)
    return;
  if (printTemplate.component === "print_template_variable") {
    return getUrl(Routes.getVariablePrintTemplate, {slug: product.page.slug}, {query: {config, article_id: article.id}});
  }
  return printTemplate.file.filename;
}
export function getHomeUrl() {
  return getUrl(Routes.home);
}
export function getEditorUrl(page, options) {
  return getUrl(Routes.productEditor, {slug: getTranslatedSlug(page)}, {query: options});
}
export function formatLocalDate(date, options) {
  const {locale} = getGlobalProps();
  return new Intl.DateTimeFormat(locale, options ?? {timeZone: "Europe/Berlin"}).format(new Date(date));
}
export function genQuery(params) {
  return Object.entries(params).filter(([, v]) => v !== void 0 && v !== null).map(([k, v]) => `${k}=${encodeURI(v)}`).join("&");
}
export function parseQuery(q) {
  if (!q) {
    q = getCurrentQueryString();
  }
  const searchParams = new URLSearchParams(q);
  const query = {};
  searchParams.forEach((value, key) => {
    let v = value;
    if (value && value[0] === "{") {
      v = JSON.parse(value);
    }
    query[key] = v;
  });
  return query;
}
export function replaceSearchQuery(query) {
  if (isSSR())
    return;
  const search = "?" + genQuery(query);
  history.replaceState(null, "", search);
}
function encodeURI(v) {
  if (isObject(v)) {
    return encodeURIComponent(JSON.stringify(v));
  }
  return encodeURIComponent(v);
}
export function isObject(o) {
  return Object.prototype.toString.call(o) === "[object Object]";
}
export function getSelectedArticle(product, cartItem) {
  const {articles} = product;
  const defaultsTo = articles[0];
  if (!cartItem)
    return defaultsTo;
  return articles.find((a) => a.id === cartItem.article_id) || defaultsTo;
}
export function getProductInnerDimensions(article, page, config = {}) {
  const pageProduct = getPageProduct(page);
  if (pageProduct.component === "model_product") {
    return {};
  }
  if (pageProduct.component === "variable_product")
    return {
      length: config.length,
      width: config.width,
      height: config.height
    };
  return getSizesFromDimensionsString(article.cf_inner_dimensions);
}
const DimensionRegexp = /\d+ x \d+ (x \d+)?/;
export function getSizesFromDimensionsString(dimensionsStr = "") {
  const match = dimensionsStr.match(DimensionRegexp);
  if (!match)
    return {};
  const [length, width, height] = match[0].split("x").map((i) => Number(i.trim()));
  return {length, width, height};
}
export function debounceAsync(fn, delay) {
  let id;
  let p;
  let res;
  return (...args) => {
    const oldRes = res;
    clearTimeout(id);
    const waiter = new Promise((resolve) => {
      res = resolve;
      id = setTimeout(() => {
        res = void 0;
        p = fn(...args);
        resolve(p);
      }, delay);
    });
    if (oldRes) {
      oldRes(p ? p : waiter);
    }
    return waiter;
  };
}
export function getPageVariantForArticle(page, article) {
  return getPageVariantForArticleId(page, article.id);
}
export function getPageOptionsForOrderOptions(page, options) {
  const [product] = page.content.product;
  const ids = options.map((a) => a.articleId);
  if (product.component === "variable_product" || product.component === "model_product")
    return [];
  if (!product.product_options)
    return [];
  return product.product_options.filter((o) => ids.includes(o.weclapp_article_id));
}
export function getPageVariantForArticleId(page, articleId) {
  const [product] = page.content.product;
  if (product.component === "variable_product" || product.component === "model_product")
    return;
  return product.variants.find((v) => v.weclapp_article_id === articleId);
}
export function getProductInnerDimensionsString(article, page, values) {
  if (!page)
    return;
  const product = getPageProduct(page);
  if (product.component === "model_product")
    return;
  return product.component === "standard_product" ? getArticleInnerDimensions(article) : getVariableProductDimensions(product, values);
}
export function getProductConfigFromArticle(article, page) {
  const product = getPageProduct(page);
  if (product.component !== "variable_product")
    return;
  const {cf_product_config} = article;
  if (!cf_product_config)
    return;
  return JSON.parse(cf_product_config);
}
export function getArticleInnerDimensions(article) {
  return article.cf_inner_dimensions;
}
export function getVariableProductDimensions(product, values = {}) {
  const config = product.config.find((c) => c.component === "size_config");
  if (!config)
    return;
  return getFormattedSize(config, values);
}
export function getFormattedSize(config, values) {
  const unitCommon = hasCommonUnit(config);
  const str = config.dimensions.map((d) => formatSize(d, values, !unitCommon)).join(" x ");
  if (!unitCommon)
    return str;
  const unit = config.dimensions[0].unit;
  return formatDimension(str, unit);
}
function hasCommonUnit(config) {
  const set = new Set(config.dimensions.map((d) => d.unit));
  return set.size === 1;
}
function formatSize(d, values, withUni) {
  const {key, unit} = d;
  const value = values[key];
  if (!withUni)
    return value;
  return formatDimension(value, unit);
}
function formatDimension(value, unit) {
  return `${value}${unit ? ` ${unit}` : ""}`;
}
export function innerHTML(unescapedStr, tagName = "span") {
  const Tag = tagName;
  return /* @__PURE__ */ React.createElement(Tag, {
    dangerouslySetInnerHTML: {__html: unescapedStr}
  });
}
export function onResize(fn, el) {
  if (el) {
    const resizeObserver = new ResizeObserver(fn);
    resizeObserver.observe(el);
    return () => resizeObserver.disconnect();
  }
  window.addEventListener("resize", fn);
  return () => window.removeEventListener("resize", fn);
}
export function getPageProduct(page) {
  const [product] = page.content.product;
  return product;
}
export function getProductEditor(product) {
  if (product.component === "model_product") {
    return;
  }
  const [editor] = product.editor || [];
  return editor;
}
export function getPageEditor(page) {
  return getProductEditor(getPageProduct(page));
}
export function getPartsFromPrintRequirements(printRequirements) {
  return printRequirements.filter((p) => p !== "inside" && p !== "outside");
}
const surfaceMap = {
  outside: "outside",
  inside: "inside",
  bottom: "inside",
  lid: "outside"
};
export function mapSurface(p) {
  return surfaceMap[p];
}
export const ConditionalWrapper = ({
  condition,
  wrapper,
  children
}) => condition ? wrapper(children) : children;
let flexGapSupported;
export function checkFlexGapSupport() {
  if (isSSR()) {
    return true;
  }
  if (flexGapSupported !== void 0) {
    return flexGapSupported;
  }
  const flex = document.createElement("div");
  flex.style.display = "flex";
  flex.style.flexDirection = "column";
  flex.style.rowGap = "1px";
  flex.style.position = "absolute";
  flex.appendChild(document.createElement("div"));
  flex.appendChild(document.createElement("div"));
  document.body.appendChild(flex);
  flexGapSupported = flex.scrollHeight === 1;
  flex.parentNode?.removeChild(flex);
  return flexGapSupported;
}
export function isStepsBasedConfig(product) {
  const [pageProduct] = product.page.content.product;
  return pageProduct.component === "variable_product" && pageProduct.config.some((c) => c.component === "group_config");
}
export function createTemplateStringReplacer(data = {}, isPreview) {
  const replacers = Object.entries(data).map(([k, v]) => {
    const regexp = new RegExp(`..${k}..`, "g");
    return (str) => str.replace(regexp, v);
  });
  if (!isPreview) {
    replacers.push((str) => str.replace(/\.\.(\w+)\.\./g, ""));
  }
  return (str) => replacers.reduce((acc, r) => r(acc), str);
}
export function formatDateTime(d) {
  return formatLocalDate(d, {
    hour: "2-digit",
    minute: "2-digit",
    month: "2-digit",
    year: "numeric",
    day: "2-digit"
  });
}
export function isSSR() {
  return typeof process !== "undefined";
}
export const callIframeMethod = (iframeRef, method, ...args) => {
  if (!iframeRef.current || !iframeRef.current.contentWindow)
    return;
  iframeRef.current.contentWindow.postMessage({
    method,
    args
  }, "*");
  return new Promise((resolve) => {
    function resolver(e) {
      const {data} = e;
      if (data.method !== method || data.args.toString() !== args.toString()) {
        return;
      }
      window.removeEventListener("message", resolver, false);
      resolve(data.result);
    }
    window.addEventListener("message", resolver, false);
  });
};
