import styled from "../_snowpack/pkg/@emotion/styled.js";
import React from "../_snowpack/pkg/react.js";
import {GridContainer} from "./grid.js";
import {colors, fontFamily, media, gap} from "./theme.js";
import Logo from "./logo.js";
import {Text} from "./typo.js";
import {getLinkUrl} from "../common/utils.js";
export default function Footer(props) {
  const {menus, ...rest} = props;
  const {footer} = menus;
  if (!footer)
    return null;
  if (footer.component !== "footer_menu")
    return null;
  const groups = footer ? footer.groups : [];
  const middle = Math.ceil(groups.length / 2);
  const firstHalf = groups.slice(0, middle);
  const secondHalf = groups.slice(middle);
  return /* @__PURE__ */ React.createElement(Container, {
    ...rest
  }, /* @__PURE__ */ React.createElement(Inner, null, /* @__PURE__ */ React.createElement(OuterGrid, null, /* @__PURE__ */ React.createElement(LogoContainer, null), /* @__PURE__ */ React.createElement(InnerGrid, null, firstHalf.map((g) => /* @__PURE__ */ React.createElement(MenuGroup, {
    key: g._uid,
    ...g
  }))), /* @__PURE__ */ React.createElement(InnerGrid, null, secondHalf.map((g) => /* @__PURE__ */ React.createElement(MenuGroup, {
    key: g._uid,
    ...g
  }))))));
}
function MenuGroup(props) {
  const {text, links} = props;
  return /* @__PURE__ */ React.createElement(MenuGroupContainer, null, /* @__PURE__ */ React.createElement(MenuGroupHeadline, {
    as: "div"
  }, text), links.map((l) => {
    const {_uid, link, text: text2, icon} = l;
    const hasIcon = !!icon?.id;
    const href = getLinkUrl(link);
    return /* @__PURE__ */ React.createElement(MenuGroupLink, {
      key: _uid,
      href,
      inline: hasIcon
    }, icon && /* @__PURE__ */ React.createElement(MenuGoupLinkIcon, {
      src: icon.filename,
      loading: "lazy"
    }), text2);
  }));
}
const gridGap = "50px";
const Container = styled.div`
  position: relative;
  padding-top: 80px;
  padding-bottom: 80px;
  background: ${colors.b};
  z-index: 100;
  ${media.mobile} {
    padding-top: 30px;
    padding-bottom: 60px;
  }
`;
const Inner = styled(GridContainer)``;
const OuterGrid = styled.div`
  display: grid;
  column-gap: ${gridGap};
  row-gap: 24px;
  grid-template-columns: repeat(5, 1fr);

  ${media.mobile} {
    grid-template-columns: repeat(2, 1fr);
  }
`;
const LogoContainer = styled(Logo)`
  ${media.mobile} {
    grid-column: span 2;
  }

  ${media.phone} {
    width: 94px;
  }
`;
const InnerGrid = styled.div`
  display: grid;
  grid-column: span 2;
  column-gap: ${gridGap};
  grid-template-columns: repeat(2, 1fr);

  ${media.mobile} {
    grid-column: span 1;
  }

  ${media.phone} {
    display: flex;
    flex-direction: column;
    ${gap("24px")};
  }
`;
const MenuGroupContainer = styled.div`
  color: ${colors.w};
`;
const MenuGroupHeadline = styled(Text)`
  font-weight: 700;
  font-family: ${fontFamily.secondary};
  white-space: nowrap;

  ${media.phone} {
    font-size: 12px;
  }
`;
const MenuGroupLink = styled.a`
  display: block;
  font-wight: 600;
  color: ${colors.b5};
  text-decoration: none;
  margin-top: 16px;
  line-height: 1.5em;

  &:hover {
    color: ${colors.w};
  }

  ${({inline}) => inline && `
      display: inline-block;

      & + a {
        margin-left: 16px;
      }
    `}

  ${media.phone} {
    font-size: 12px;
  }
`;
const MenuGoupLinkIcon = styled.img``;
