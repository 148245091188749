import styled from "../_snowpack/pkg/@emotion/styled.js";
import React from "../_snowpack/pkg/react.js";
import {media} from "./theme.js";
export function Grid(props) {
  return /* @__PURE__ */ React.createElement(GridC, {
    ...props
  });
}
export function GridContainer(props) {
  return /* @__PURE__ */ React.createElement(GridContainerC, {
    ...props
  });
}
const GridC = styled.div`
  display: grid;
  grid-gap: 24px;
`;
const GridContainerC = styled.div`
  max-width: 1200px;
  padding-left: 30px;
  padding-right: 30px;
  margin: auto;

  ${media.mobile} {
    padding-left: 16px;
    padding-right: 16px;
  }
`;
