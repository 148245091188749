import styled from "../_snowpack/pkg/@emotion/styled.js";
import React from "../_snowpack/pkg/react.js";
import {spacings, colors, media} from "./theme.js";
import {Title, Text} from "./typo.js";
import Img from "./image.js";
export default function ProcessStep(props) {
  const {headline, color, copy, icon, is_final_step, isProductPage, ...rest} = props;
  return /* @__PURE__ */ React.createElement(ProcessStepContainer, {
    ...rest
  }, /* @__PURE__ */ React.createElement(ProcessStepSquare, {
    color: color || "transparent"
  }, /* @__PURE__ */ React.createElement(Image, {
    ...icon
  })), !is_final_step && /* @__PURE__ */ React.createElement(Arrow, {
    color
  }), /* @__PURE__ */ React.createElement(ProcessStepContent, null, headline && /* @__PURE__ */ React.createElement(Headline, {
    level: 5
  }, headline), copy && /* @__PURE__ */ React.createElement(Copy, null, copy)));
}
function Arrow(props) {
  const {color} = props;
  return /* @__PURE__ */ React.createElement(ArrowContainer, null, /* @__PURE__ */ React.createElement("svg", {
    viewBox: "0 0 189 16",
    fill: "none"
  }, /* @__PURE__ */ React.createElement("path", {
    d: "M188.707 8.70711C189.098 8.31658 189.098 7.68342 188.707 7.29289L182.343 0.928932C181.953 0.538408 181.319 0.538408 180.929 0.928932C180.538 1.31946 180.538 1.95262 180.929 2.34315L186.586 8L180.929 13.6569C180.538 14.0474 180.538 14.6805 180.929 15.0711C181.319 15.4616 181.953 15.4616 182.343 15.0711L188.707 8.70711ZM0 9H188V7H0V9Z",
    fill: color
  })));
}
const ProcessStepContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 250px;

  img {
    max-width: 100%;
  }

  ${media.phone} {
    flex-direction: row;
    max-width: 100%;
    margin-bottom: ${spacings.xl}px;
    min-height: 100px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;
const Image = styled(Img)`
  max-width: 100%;
  height: auto;
`;
const ProcessStepSquare = styled.div`
  width: 92px;
  height: 92px;
  min-width: 92px;
  border: 2px solid;
  border-radius: 4px;
  margin-bottom: ${spacings.xl}px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({color}) => `
    border-color: ${color};
    background: ${color}50;
  `}

  ${media.phone} {
    margin-bottom: 0;
    margin-right: ${spacings.ml}px;
    width: 62px;
    height: 62px;
    min-width: 62px;
  }
`;
const Headline = styled(Title)`
  font-size: 20px;
  line-height: 28px;
  margin-bottom: ${spacings.m}px;

  ${media.mobile} {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: ${spacings.xs}px;
  }
`;
const Copy = styled(Text)`
  font-size: 16px;
  line-height: 24px;
  color: ${colors.b2};

  ${media.mobile} {
    font-size: 14px;
    line-height: 22px;
  }
`;
const ProcessStepContent = styled.div`
  text-align: left;
`;
const ArrowContainer = styled.div`
  position: absolute;
  transform: translate(calc(50% + 45px), calc(100% + 21px));
  width: calc(100% - 92px);
  height: 16px;
  overflow: hidden;

  svg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 189px;
    height: 16px;
  }

  ${media.phone} {
    max-width: unset;
    transform: translate(calc(50% - 50px), calc(100% + 54px)) rotate(90deg);
    width: 80px;
  }
`;
