import React, {Component} from "../_snowpack/pkg/react.js";
import styled from "../_snowpack/pkg/@emotion/styled.js";
import request from "../common/request.js";
export default class DebugBar extends Component {
  constructor(props = {}) {
    super(props);
    this.state = {content: ""};
  }
  async componentDidMount() {
    const content = await request("/dev/debug-bar", {
      method: "get"
    });
    this.setState({content});
  }
  render() {
    const {content} = this.state;
    return /* @__PURE__ */ React.createElement(DebugBarModal, {
      dangerouslySetInnerHTML: {__html: content}
    });
  }
}
const DebugBarModal = styled.div`
  position: fixed;
  top: 100px;
  left: 10px;
  width: 400px;
  padding: 20px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
  word-break: break-all;
  z-index: 1000;
  max-height: 80vh;
  overflow-y: scroll;
  background-color: white;
`;
