import styled from "../_snowpack/pkg/@emotion/styled.js";
import React from "../_snowpack/pkg/react.js";
import {colors, media} from "./theme.js";
import {getLinkUrl} from "../common/utils.js";
import Logo from "./logo.js";
export default function Footer(props) {
  const {menus, ...rest} = props;
  if (!menus || !menus.footer)
    return null;
  const {footer} = menus;
  if (footer.component !== "account_menu_footer")
    return null;
  return /* @__PURE__ */ React.createElement(Container, {
    ...rest
  }, /* @__PURE__ */ React.createElement(Inner, null, /* @__PURE__ */ React.createElement(LogoC, null), /* @__PURE__ */ React.createElement(Links, null, footer.links.map((l) => /* @__PURE__ */ React.createElement(ReducedMenuLinks, {
    key: l._uid,
    href: getLinkUrl(l.link)
  }, l.text)))));
}
const Container = styled.div`
  position: relative;
  padding-top: 80px;
  padding-bottom: 80px;
  background: ${colors.b};
  z-index: 100;
  ${media.mobile} {
    padding-top: 30px;
    padding-bottom: 60px;
  }
`;
const Inner = styled.div`
  position: relative;
  max-width: 1200px;
  padding-left: 30px;
  padding-right: 30px;
  margin: auto;
`;
const Links = styled.div`
  display: flex;
  gap: 50px;
  align-items: center;
  justify-content: center;
  min-height: 40px;

  ${media.mobile} {
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
    align-items: initial;
  }
`;
const ReducedMenuLinks = styled.a`
  color: ${colors.w};
  text-decoration: none;
`;
const LogoC = styled(Logo)`
  position: absolute;

  ${media.mobile} {
    position: relative;
  }
`;
