import {compile} from "../_snowpack/pkg/path-to-regexp.js";
const ssr = isSSR();
export function getUrl(name, data, options = {query: {}, locale: true}) {
  const {query} = options;
  const _storyblok = getStoryblokIdFromSearch();
  const route = getRoute(name);
  const toPath = compile(route);
  const link = localizeLink(toPath(data));
  const qs = genQuery({
    ...query,
    _storyblok
  });
  return `${link}${qs ? `?${qs}` : ""}`;
}
function getRoute(name) {
  const {locale, routes} = getGlobalProps();
  const r = routes[`${name}-${locale}`];
  if (r)
    return r;
  return routes[name];
}
function getStoryblokIdFromSearch() {
  if (ssr)
    return;
  const sp = new URLSearchParams(location.search);
  return sp.get("_storyblok");
}
export function getFromLocalStore(key) {
  if (ssr)
    return null;
  return localStorage.getItem(key);
}
export const getGlobalProps = genUniversalFn(() => severLib("async-local-storage").getStore().ctx.viewData, () => window.DATA ?? {});
export function getServerContext() {
  return severLib("async-local-storage").getStore().ctx;
}
export const getCurrentPath = genUniversalFn(() => severLib("async-local-storage").getStore().ctx.path, () => location.pathname);
export const getCurrentQueryString = genUniversalFn(() => severLib("async-local-storage").getStore().ctx.querystring, () => location.search);
function genUniversalFn(server, client) {
  return ssr ? server : client;
}
function severLib(path) {
  return eval(`require("../../libs/${path}")`);
}
export function localizeLink(path) {
  const {locale} = getGlobalProps();
  if (locale === "de")
    return path;
  return `/${locale}${path}`;
}
export function formatCurrency(amount, currency = "EUR") {
  const {locale} = getGlobalProps();
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency
  }).format(amount);
}
function isSSR() {
  return typeof window === "undefined";
}
function genQuery(params) {
  return Object.entries(params).filter(([, v]) => v !== void 0 && v !== null).map(([k, v]) => `${k}=${encodeURI(v)}`).join("&");
}
function encodeURI(v) {
  if (isObject(v)) {
    return encodeURIComponent(JSON.stringify(v));
  }
  return encodeURIComponent(v);
}
export function isObject(o) {
  return Object.prototype.toString.call(o) === "[object Object]";
}
