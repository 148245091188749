import styled from "../_snowpack/pkg/@emotion/styled.js";
import React from "../_snowpack/pkg/react.js";
import {gap, spacings} from "./theme.js";
export default function Inline(props) {
  const {spacing = "m", children, as, ...rest} = props;
  return /* @__PURE__ */ React.createElement(Container, {
    spacing,
    as,
    ...rest
  }, children);
}
const Container = styled.div`
  --spacing: ${({spacing}) => `${spacings[spacing]}px`};
  display: flex;
  align-items: center;
  ${gap("var(--spacing)")}
`;
