import React, {createContext, useContext, useState} from "../_snowpack/pkg/react.js";
export class Store {
  constructor(s) {
    this.Provider = (props) => {
      const {Context} = this;
      const {initialState, computedState} = props;
      let [storeState, setStoreState] = useState(initialState);
      if (computedState) {
        storeState = computedState(storeState);
      }
      return /* @__PURE__ */ React.createElement(Context.Provider, {
        value: [storeState, setStoreState]
      }, props.children);
    };
    this.useStore = () => {
      return useContext(this.Context);
    };
    this.Context = createContext([s, (s2) => s2]);
  }
}
export default function createStore(s) {
  return new Store(s);
}
