import styled from "../_snowpack/pkg/@emotion/styled.js";
import React from "../_snowpack/pkg/react.js";
import {Title} from "./typo.js";
import Stack from "./stack.js";
import Button from "./button.js";
import ProcessStep from "./process-step.js";
import {gap, media, spacings} from "./theme.js";
import Component from "./editable-component.js";
import {withAttrs} from "../common/utils.js";
export default function Process(props) {
  const {headline, button, steps, isProductPage, ...rest} = props;
  const [cta] = button || [];
  return /* @__PURE__ */ React.createElement(Component, {
    ...rest
  }, /* @__PURE__ */ React.createElement(ProcessContainer, {
    isProductPage
  }, /* @__PURE__ */ React.createElement(ProcessStack, null, headline && /* @__PURE__ */ React.createElement(Headline, {
    isProductPage
  }, headline), /* @__PURE__ */ React.createElement(ProcessStepList, null, steps?.map((step) => {
    return /* @__PURE__ */ React.createElement(ProcessStep, {
      key: step._uid,
      ...step,
      isProductPage
    });
  })), cta && /* @__PURE__ */ React.createElement(ProcessButton, {
    href: cta.cta_url
  }, cta.cta_text))));
}
const ProcessContainer = styled.div`
  width: 100%;
  padding: ${(props) => props.isProductPage ? "30px 0" : "60px 0"};
  text-align: center;

  ${media.mobile} {
    padding: ${spacings.xl}px 0 0;
  }
`;
const ProcessStepList = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: space-evenly;
  ${gap("10px")}

  ${media.phone} {
    flex-direction: column;
    ${gap("10px", "column")}
  }
`;
const ProcessStack = styled(withAttrs(Stack, {spacing: "l"}))`
  width: 100%;

  ${media.phone} {
    --spacing: 16px;
  }
`;
const ProcessButton = styled(Button)`
  width: 300px;
  align-self: center;

  ${media.mobile} {
    width: 100%;
  }
`;
const Headline = styled(withAttrs(Title, {level: 3}))`
  font-size: 38px;
  line-height: 46px;
  margin-bottom: 1.5em;

  ${({isProductPage}) => isProductPage && `
    font-size: 30px;
    line-height: 38px;
    text-align: left;
    margin-bottom: 0;
    `}

  ${media.mobile} {
    font-size: 24px;
    line-height: 32px;
    text-align: left;
    margin-bottom: 0;
  }
`;
