import styled from "../_snowpack/pkg/@emotion/styled.js";
import React, {cloneElement} from "../_snowpack/pkg/react.js";
import {unmountComponentAtNode, render} from "../_snowpack/pkg/react-dom.js";
import {rgba, withAttrs} from "../common/utils.js";
import Portal from "./portal.js";
import {colors, fontFamily, media} from "./theme.js";
import Button from "./button.js";
export function showModal(args) {
  const el = document.createElement("div");
  document.body.appendChild(el);
  return new Promise((resolve) => {
    const C = (props) => {
      const M = args(resolveAndClean);
      return cloneElement(M, props);
    };
    const clean = () => {
      unmountComponentAtNode(el);
      return el.parentNode && el.parentNode.removeChild(el);
    };
    const resolveAndClean = () => {
      setTimeout(clean, 500);
      render(/* @__PURE__ */ React.createElement(C, {
        active: false
      }), el);
      resolve(true);
    };
    render(/* @__PURE__ */ React.createElement(C, {
      active: false
    }), el);
    setTimeout(() => render(/* @__PURE__ */ React.createElement(C, {
      active: true
    }), el), 1);
  });
}
const modalsPromiseMap = new Map();
export function showModalOnce(key, args) {
  if (modalsPromiseMap.has(key)) {
    return modalsPromiseMap.get(key);
  }
  const modalPromise = showModal(args);
  modalPromise.finally(() => modalsPromiseMap.delete(key));
  modalsPromiseMap.set(key, modalPromise);
  return modalPromise;
}
export default function Modal(props) {
  const {title, icon, children, footer, active, ...rest} = props;
  return /* @__PURE__ */ React.createElement(Portal, null, /* @__PURE__ */ React.createElement(Container, {
    active,
    ...rest
  }, /* @__PURE__ */ React.createElement(Inner, {
    active
  }, /* @__PURE__ */ React.createElement(ContentContainer, null, /* @__PURE__ */ React.createElement(ContentInner, null, icon && /* @__PURE__ */ React.createElement(Icon, null, icon), title && /* @__PURE__ */ React.createElement(Title, null, title), /* @__PURE__ */ React.createElement(Content, null, children)), footer && /* @__PURE__ */ React.createElement(Footer, null, footer)))));
}
const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  z-index: 5000;

  ${({active}) => active && `pointer-events: auto;`}
`;
const Inner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  height: 100%;
  width: 100%;
  background: ${rgba(colors.b, 0)};
  transition: background 0.3s;

  ${({active}) => active && `
      background: ${rgba(colors.b, 0.4)};

      > div {
        opacity: 1;
        transform: scale(1);
        overflow-y: auto;
      }
    `}
`;
const ContentContainer = styled.div`
  max-width: 600px;
  max-height: 80vh;
  min-width: 280px;
  overflow: hidden;
  opacity: 0;
  transform: scale(0.8);
  transition: transform 0.3s, opacity 0.3s;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 4px;
  background: white;

  ${media.mobile} {
    max-width: calc(100vw - 40px);
    margin: 0;
  }
`;
const ContentInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;

  ${media.mobile} {
    padding: 20px;
  }
`;
const Title = styled.div`
  font-family: ${fontFamily.secondary};
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 10px;
`;
const Icon = styled.div`
  margin-bottom: 12px;
`;
const Content = styled.div`
  font-family: ${fontFamily.primary};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
`;
const Footer = styled.div`
  // border-top: 1px solid ${colors.b5};
  // background: ${colors.b6};

  padding: 0 32px 16px;
  ${media.mobile} {
    padding: 10px 20px;
  }
`;
export const ConfirmModalFooter = styled.div`
  display: flex;
  gap: 16px;

  > :first-of-type {
    margin-left: auto;
  }
`;
export const ConfirmModalButton = styled(withAttrs(Button, {small: true}))`
  min-width: 69px;
  margin-top: 32px;
`;
export const ConfirmModalInner = styled.div``;
export const ConfirmModal = styled(Modal)``;
export const ConfirmButtonContainer = styled.div`
  display: flex;
  gap: 10px;
`;
