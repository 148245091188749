import {getGlobalProps} from "./universal.js";
export default async function request(url, params = {}) {
  const requestInit = getRequestInit(params);
  const r = new Request(url, requestInit);
  const resp = await fetch(r);
  const contentType = resp.headers.get("content-type");
  const isJSON = contentType && contentType.includes("application/json");
  const res = isJSON ? await resp.json() : await resp.text();
  if (resp.ok) {
    return res;
  }
  throw isJSON ? Object.assign(new Error(res.message), res) : new Error(res);
}
function getRequestInit(params) {
  const {method = "get", ...rest} = params;
  const {body, headers} = getFormattedBodyandHeaders(params);
  return {
    ...rest,
    headers,
    method,
    body
  };
}
function getFormattedBodyandHeaders(params) {
  const {body} = params;
  let {headers = {}} = params;
  headers = {
    "csrf-token": getCSRFToken(),
    "X-Requested-With": "XMLHttpRequest",
    ...headers
  };
  if (isPlainObject(body) || Array.isArray(body)) {
    return {
      headers: {
        "content-type": "application/json",
        ...headers
      },
      body: JSON.stringify(body)
    };
  }
  return {
    headers,
    body
  };
}
function getCSRFToken() {
  return getGlobalProps().csrfToken;
}
function isPlainObject(v) {
  return typeof v === "object" && Object.prototype.toString.call(v) === "[object Object]";
}
