import styled from "../_snowpack/pkg/@emotion/styled.js";
import React from "../_snowpack/pkg/react.js";
import {fontFamily, colors, media, spacings, lineHeight} from "./theme.js";
import {Title, Text} from "./typo.js";
import Button from "./button.js";
import Stack from "./stack.js";
import Steps from "./steps.js";
import Image from "./image.js";
import Component from "./editable-component.js";
import Slider from "./slider.js";
import {withAttrs} from "../common/utils.js";
export default function HeroHome(props) {
  const {slides, steps, ...rest} = props;
  const [stepsModule] = steps;
  return /* @__PURE__ */ React.createElement(Component, {
    ...rest
  }, /* @__PURE__ */ React.createElement(Container, null, /* @__PURE__ */ React.createElement(Slider, null, slides.map((s) => /* @__PURE__ */ React.createElement(Slide, {
    key: s._uid,
    ...s
  }))), /* @__PURE__ */ React.createElement(StepsModule, {
    ...stepsModule
  })));
}
function Slide(props) {
  const {
    headline,
    text_alignment = "center",
    copy,
    button,
    image,
    ...rest
  } = props;
  const [cta] = button || [];
  return /* @__PURE__ */ React.createElement(Component, {
    ...rest
  }, /* @__PURE__ */ React.createElement(SlideContainer, {
    ...rest
  }, /* @__PURE__ */ React.createElement(TextContainer, {
    alignment: text_alignment
  }, /* @__PURE__ */ React.createElement(Headline, null, headline), copy && /* @__PURE__ */ React.createElement(SubTitle, {
    as: "h2"
  }, copy), cta && /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(HeroButton, {
    href: cta.cta_url
  }, cta.cta_text))), /* @__PURE__ */ React.createElement(ImageContainer, null, /* @__PURE__ */ React.createElement(HeroImage, {
    ...image,
    sizes: [515, 300]
  }))));
}
const SlideContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "text image";
  grid-gap: 50px;

  ${media.phone} {
    grid-template-columns: 1fr;
    grid-template-areas: "text" "image";
    height: 100%;
  }
`;
const Container = styled.div`
  padding: 40px 0 80px;

  ${media.mobile} {
    padding: 40px 0 0;
  }
`;
const Headline = styled(Title)`
  font-size: 46px;

  ${media.mobile} {
    font-size: 38px;
    line-height: 46px;
  }
`;
const HeroButton = styled(Button)`
  ${media.phone} {
    width: 100%;
  }
`;
const StepsModule = styled(Steps)``;
const SubTitle = styled(Text)`
  font-family: ${fontFamily};
  color: ${colors.b2};
  font-weight: normal;
  font-size: 20px;
  line-height: ${lineHeight};

  ${media.mobile} {
    --spacing: ${spacings.m}px;
  }
`;
const HeroImage = styled(Image)`
  display: block;
  height: 100%;
  width: 100%;
  object-fit: contain;

  ${media.phone} {
    height: auto;
    max-height: 200px;
  }
`;
const flexAlignmentMap = {
  top: "flex-start",
  center: "center",
  bottom: "flex-end"
};
const TextContainer = styled(withAttrs(Stack, {spacing: "l"}))`
  grid-area: text;
  ${({alignment}) => `align-self:${flexAlignmentMap[alignment]};`}

  ${media.mobile} {
    --spacing: ${spacings.m}px;
    align-self: flex-start;
  }
`;
const ImageContainer = styled(Stack)`
  justify-content: center;
  grid-area: image;
`;
