import React from "../_snowpack/pkg/react.js";
import styled from "../_snowpack/pkg/@emotion/styled.js";
import {ButtonArrow} from "./icons.js";
export default function Link(props) {
  const {children, href, arrow} = props;
  return /* @__PURE__ */ React.createElement(A, {
    href
  }, children, " ", arrow && /* @__PURE__ */ React.createElement(Arrow, null));
}
const A = styled.a`
  color: #00a4e2;
  text-decoration: none;
  font-weight: bold;

  &:hover {
    svg {
      margin-left: 13px;
    }
  }
`;
const Arrow = styled(ButtonArrow)`
  margin-left: 8px;
  transition: margin-left 0.3s ease;

  path {
    stroke: #00a4e2;
  }
`;
