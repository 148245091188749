import {Component} from "../_snowpack/pkg/react.js";
import {createPortal} from "../_snowpack/pkg/react-dom.js";
import {isSSR} from "../common/utils.js";
const ssr = isSSR();
class Portal extends Component {
  constructor(props) {
    super(props);
    this.el = document.createElement("div");
    this.el.style.position = "absolute";
    this.el.style.top = "0";
    this.el.style.left = "0";
    this.el.style.right = "0";
    this.el.style.bottom = "0";
    this.el.style.pointerEvents = "none";
  }
  componentDidMount() {
    document.body.appendChild(this.el);
  }
  componentWillUnmount() {
    document.body.removeChild(this.el);
  }
  render() {
    return createPortal(this.props.children, this.el);
  }
}
export default ssr ? () => null : Portal;
