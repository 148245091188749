import styled from "../_snowpack/pkg/@emotion/styled.js";
import Doc from "./doc.js";
import React from "../_snowpack/pkg/react.js";
import {spacings, colors, media} from "./theme.js";
import {Title} from "./typo.js";
import Stack from "./stack.js";
import Component from "./editable-component.js";
import Image from "./image.js";
import {withAttrs} from "../common/utils.js";
import {getLinkUrl} from "../common/utils.js";
import Button from "./button.js";
export default function ImageRichText(props) {
  const {
    module_direction,
    text_alignment,
    headline,
    image,
    copy,
    CTA,
    ...rest
  } = props;
  return /* @__PURE__ */ React.createElement(Component, {
    ...props
  }, /* @__PURE__ */ React.createElement(ImageTextContainer, {
    isMirrored: module_direction,
    ...rest
  }, /* @__PURE__ */ React.createElement(TextStack, {
    alignment: text_alignment
  }, headline && /* @__PURE__ */ React.createElement(Headline, null, headline), copy && /* @__PURE__ */ React.createElement(Copy, {
    ...copy
  }), CTA && CTA.length > 0 && /* @__PURE__ */ React.createElement(Stack, null, /* @__PURE__ */ React.createElement(CtaModule, {
    align: CTA[0].alignment
  }, /* @__PURE__ */ React.createElement(Button, {
    href: getLinkUrl(CTA[0].link)
  }, /* @__PURE__ */ React.createElement("span", null, CTA[0].Text))))), /* @__PURE__ */ React.createElement(ImageStack, null, /* @__PURE__ */ React.createElement(ImageContainer, {
    ...image,
    sizes: [365]
  }))));
}
const ImageTextContainer = styled.div`
  width: 100%;
  padding: 60px 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-gap: 0px 24px;
  grid-template-areas: ${(props) => {
  if (!props.isMirrored) {
    return props.isProductPage ? "'Image Image Image Text Text Text Text Text Text'" : "'. Image Image Image Image . Text Text Text Text Text .'";
  } else {
    return props.isProductPage ? "'Text Text Text Text Text Text Image Image Image'" : "'. Text Text Text Text Text . Image Image Image Image .'";
  }
}};

  ${media.phone} {
    padding: ${spacings.xl}px 0 0;
    grid-template-columns: 100%;
    grid-template-rows: auto auto auto;
    grid-gap: 0px ${spacings.m}px;
    grid-template-areas:
      "Image"
      "."
      "Text";
  }
`;
const ImageStack = styled.div`
  display: flex;
  justify-content: center;
  grid-area: Image;

  ${media.mobile} {
    margin-bottom: ${spacings.m}px;
  }
`;
const TextStack = styled(withAttrs(Stack, {spacing: "l"}))`
  grid-area: Text;
  justify-content: center;
  align-content: ${(props) => props.alignment};

  ${media.mobile} {
    --spacing: ${spacings.m}px;
  }
`;
const Headline = styled(withAttrs(Title, {level: 3}))`
  font-size: 30px;
  line-height: 38px;

  ${media.mobile} {
    font-size: 20px;
    line-height: 28px;
  }
`;
const Copy = styled(Doc)`
  font-size: 16px;
  line-height: 22px;
  color: ${colors.b2};

  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${colors.b};
  }

  ${media.mobile} {
    font-size: 16px;
    line-height: 24px;
  }
`;
const ImageContainer = styled(Image)`
  width: 100%;
  grid-area: Image;
  border-radius: 4px;
  object-fit: cover;

  ${media.phone} {
    max-width: 100%;
    max-height: 250px;
    width: 100%;
  }
`;
const CtaModule = styled.div`
  display: flex;
  justify-content: ${(props) => {
  const {align} = props;
  if (align === "center")
    return "center";
  if (align === "right")
    return "flex-end";
  return "flex-start";
}};

  margin: var(--spacing) 0;
`;
