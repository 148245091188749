import styled from "../_snowpack/pkg/@emotion/styled.js";
import React from "../_snowpack/pkg/react.js";
import {media, spacings, colors} from "./theme.js";
import {Text} from "./typo.js";
import Component from "./editable-component.js";
import Img from "./image.js";
export default function Steps(props) {
  const {steps, isProductPage, ...rest} = props;
  return /* @__PURE__ */ React.createElement(Component, {
    ...rest
  }, /* @__PURE__ */ React.createElement(StepContainer, {
    ...rest
  }, steps.map((step) => {
    const stepHasCopy = step.copy ? true : false;
    return /* @__PURE__ */ React.createElement(Component, {
      key: step._uid,
      ...step
    }, /* @__PURE__ */ React.createElement(Step, {
      hasCopy: stepHasCopy
    }, /* @__PURE__ */ React.createElement(Image, {
      ...step.icon
    }), /* @__PURE__ */ React.createElement(StepHeadline, {
      hasCopy: stepHasCopy
    }, step.headline), stepHasCopy && /* @__PURE__ */ React.createElement(StepCopy, null, step.copy)));
  })));
}
const StepContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  grid-area: steps;
  margin: 10px 0;

  ${media.phone} {
    flex-direction: column;
    margin: ${spacings.m}px 0 0;
  }
`;
const Image = styled(Img)`
  width: 38px;
  height: auto;
`;
const Step = styled.div`
  display: flex;
  justify-items: center;
  align-items: center;
  flex-direction: ${({hasCopy}) => hasCopy ? "column" : "row"};

  ${({hasCopy}) => hasCopy && `
      & + & {
        margin-left: 2.222222222%;
      }

      width: 100%;
      padding: 32px;
      border-radius: 4px;
      box-shadow: 0px 8px 24px rgba(163, 177, 191, 0.35);
    `};

  ${media.phone} {
    & + & {
      margin-top: ${spacings.m}px;
      margin-left: 0;
    }

    svg {
      width: 24px;
    }
  }
`;
const StepHeadline = styled(Text)`
  margin-left: 24px;
  font-weight: bold;
  font-size: 16px;

  ${media.mobile} {
    margin-left: 8px;
  }

  ${({hasCopy}) => hasCopy && `
      margin: 10px 0;
      display: block;
      width: 100%;
      text-align: center;
    `};
`;
const StepCopy = styled(Text)`
  color: ${colors.b2};
`;
