import {keyframes} from "../_snowpack/pkg/@emotion/react.js";
import styled from "../_snowpack/pkg/@emotion/styled.js";
import React from "../_snowpack/pkg/react.js";
import {colors} from "./theme.js";
export default function Loader(props) {
  const {...rest} = props;
  return /* @__PURE__ */ React.createElement(Container, {
    ...rest
  });
}
const rotate = keyframes`
  from {
    transform-origin: center center;
    transform: rotate(0deg);
  }

  to {
    transform-origin: center center;
    transform: rotate(360deg);
  }
`;
const Container = styled(LoaderIcon)`
  width: 120px;
  height: 120px;
  color: ${colors.c};
  animation: ${rotate} 1s linear infinite;
`;
function LoaderIcon(props) {
  return /* @__PURE__ */ React.createElement("svg", {
    viewBox: "0 0 38 38",
    ...props
  }, /* @__PURE__ */ React.createElement("g", {
    fill: "none",
    fillRule: "evenodd"
  }, /* @__PURE__ */ React.createElement("g", {
    transform: "translate(1 1)"
  }, /* @__PURE__ */ React.createElement("path", {
    d: "M36 18c0-9.94-8.06-18-18-18",
    id: "Oval-2",
    stroke: "currentColor",
    strokeWidth: "2"
  }))));
}
