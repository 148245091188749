import i18next from "../_snowpack/pkg/i18next.js";
import {getGlobalProps} from "./universal.js";
export function init() {
  i18next.init({
    initImmediate: false,
    lng: getLang(),
    nsSeparator: false,
    keySeparator: false,
    fallbackLng: false,
    resources: getResources()
  });
}
export function t(key, options) {
  init();
  const translation = i18next.t(key, options);
  return translation ?? key;
}
function getResources() {
  return getGlobalProps().resources;
}
export function getLang() {
  return getGlobalProps().locale;
}
