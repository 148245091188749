import styled from "../_snowpack/pkg/@emotion/styled.js";
import React from "../_snowpack/pkg/react.js";
import {media, spacings} from "./theme.js";
import {Title} from "./typo.js";
import Stack from "./stack.js";
import Component from "./editable-component.js";
import Image from "./image.js";
import {withAttrs} from "../common/utils.js";
export default function ClientList(props) {
  const {headline, clients, ...rest} = props;
  return /* @__PURE__ */ React.createElement(Component, {
    ...rest
  }, /* @__PURE__ */ React.createElement(ClientContainer, null, /* @__PURE__ */ React.createElement(ClientStack, {
    spacing: "l"
  }, /* @__PURE__ */ React.createElement(Headline, {
    level: 2
  }, headline), clients && /* @__PURE__ */ React.createElement(ClientListElement, null, clients.map((image, i) => {
    return /* @__PURE__ */ React.createElement(Component, {
      key: i,
      ...image
    }, /* @__PURE__ */ React.createElement(ClientImage, {
      ...image.logo
    }));
  })))));
}
const ClientContainer = styled.div`
  width: 100%;
  padding: 80px 0;

  ${media.mobile} {
    padding: ${spacings.xl}px 0 0;
  }
`;
const ClientStack = styled(Stack)`
  width: 100%;

  ${media.mobile} {
    --spacing: ${spacings.m}px;
  }
`;
const Headline = styled(Title)`
  ${media.mobile} {
    font-size: 24px;
    line-height: 32px;
  }
`;
const ClientImage = styled(withAttrs(Image, {sizes: [250]}))`
  flex: 1;
  max-width: 150px;
  max-height: 100px;
  object-fit: contain;

  ${media.mobile} {
    max-height: auto;
    flex: 50%;
    max-width: 250px;
    padding: ${spacings.ml}px;
  }
`;
const ClientListElement = styled.div`
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;
  justify-content: space-between;

  ${media.mobile} {
    flex-wrap: wrap;
    justify-content: center;
  }
`;
