import styled from "../_snowpack/pkg/@emotion/styled.js";
import React from "../_snowpack/pkg/react.js";
import {colors, fontFamily, lineHeight} from "./theme.js";
import Inline from "./inline.js";
import DefaultLoader from "./loader.js";
export default function Button(props) {
  const {href, type, children, loading, customColor, ...rest} = props;
  let as = "div";
  if (href) {
    as = "a";
  }
  if (type === "submit") {
    as = "button";
  }
  return /* @__PURE__ */ React.createElement(ButtonContainer, {
    href,
    as,
    buttonLoading: loading,
    customColor: customColor ? customColor : "",
    ...rest
  }, loading ? /* @__PURE__ */ React.createElement(Loader, null) : /* @__PURE__ */ React.createElement(Inline, {
    spacing: "s"
  }, children));
}
const ButtonContainer = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 16px 32px;
  font-family: ${fontFamily.secondary};
  font-size: 16px;
  line-height: ${lineHeight};
  font-weight: 700;
  background-color: ${colors.c};
  color: ${colors.w};
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
  outline: none;
  appearance: none;
  border: none;

  &:hover {
    background-color: ${colors.ch};
  }

  ${({stretch}) => stretch && `
      display: flex;
      width: 100%;
    `};

  ${({secondary}) => secondary && `
      background-color: ${colors.w};
      color: ${colors.c};
      border: 1px solid ${colors.b5};

      &:hover {
        background-color: ${colors.w};
        color: ${colors.ch};
        border: 1px solid ${colors.b4};
      }
    `}

  ${({buttonLoading}) => buttonLoading && `
      background-color: ${colors.b6};
      border: 1px solid ${colors.b6};
      color: ${colors.b1};
      pointer-events: none;
    `}


  ${({disabled}) => disabled && `
      background-color: ${colors.b6};
      color: ${colors.b1};
      pointer-events: none;
    `}

  ${({small}) => small && `
      padding: 8px 16px;
      font-size: 14px;
    `}

    ${({small, secondary}) => small && secondary && `
      color: black;

      &:hover {
        color: black;
      }
    `}


    //TODO hover color effect
    ${({customColor}) => customColor && `
      color: ${customColor};
      &:hover {
        color: ${customColor};
        border: 1px solid ${colors.b4};
      }
    `}
`;
const Loader = styled(DefaultLoader)`
  width: 22px;
  height: 22px;
`;
