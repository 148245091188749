import React from "../_snowpack/pkg/react.js";
import * as icons from "./icons.js";
import capitalize from "../_snowpack/pkg/lodash/capitalize.js";
export default function Icon(props) {
  const {name, ...rest} = props;
  const I = icons[capitalize(name)];
  return /* @__PURE__ */ React.createElement(I, {
    ...rest
  });
}
