import styled from "../_snowpack/pkg/@emotion/styled.js";
import React from "../_snowpack/pkg/react.js";
import {Text} from "./typo.js";
import {colors, fontFamily} from "./theme.js";
import Img from "./image.js";
import {withAttrs} from "../common/utils.js";
export default function ProductOption(props) {
  const {text, icon, ...rest} = props;
  return /* @__PURE__ */ React.createElement(ProductOptionContainer, {
    ...rest
  }, icon && /* @__PURE__ */ React.createElement(Icon, {
    ...icon
  }), /* @__PURE__ */ React.createElement(ProductOptionText, null, text));
}
const ProductOptionContainer = styled.div`
  display: flex;
  align-items: center;
`;
const ProductOptionText = styled(Text)`
  font-size: 12px;
  line-height: 22px;
  color: ${colors.b4};
  font-weight: 500;
  font-family: ${fontFamily.secondary};
  margin-left: 6px;
  white-space: nowrap;
`;
const Icon = styled(withAttrs(Img, {sizes: [24]}))`
  width: 24px;
  height: 24px;
  object-fit: contain;
`;
