import styled from "../_snowpack/pkg/@emotion/styled.js";
import Doc from "./doc.js";
import React from "../_snowpack/pkg/react.js";
import {spacings, colors, media} from "./theme.js";
import {Title} from "./typo.js";
import Stack from "./stack.js";
import {ButtonArrow} from "./icons.js";
import Component from "./editable-component.js";
import Image from "./image.js";
import {withAttrs} from "../common/utils.js";
import {getLinkUrl} from "../common/utils.js";
import Button from "./button.js";
export default function ImageText(props) {
  const {
    module_direction,
    text_alignment,
    headline,
    image,
    copy,
    link,
    link_name,
    show_as_a_button,
    isProductPage = false,
    ...rest
  } = props;
  const href = link ? getLinkUrl(link) : void 0;
  return /* @__PURE__ */ React.createElement(Component, {
    ...props
  }, /* @__PURE__ */ React.createElement(ImageTextContainer, {
    isMirrored: module_direction,
    isProductPage,
    ...rest
  }, /* @__PURE__ */ React.createElement(TextStack, {
    alignment: text_alignment
  }, /* @__PURE__ */ React.createElement(Headline, {
    isProductPage
  }, headline), copy && /* @__PURE__ */ React.createElement(Copy, {
    isProductPage,
    ...copy
  }), link_name && (show_as_a_button ? /* @__PURE__ */ React.createElement(ButtonContainer, null, /* @__PURE__ */ React.createElement(Btn, {
    href
  }, link_name)) : /* @__PURE__ */ React.createElement(Link, {
    href
  }, /* @__PURE__ */ React.createElement("span", null, link_name), " ", /* @__PURE__ */ React.createElement(Arrow, null)))), /* @__PURE__ */ React.createElement(ImageStack, null, /* @__PURE__ */ React.createElement(ImageContainer, {
    ...image,
    sizes: [365]
  }))));
}
const ImageTextContainer = styled.div`
  width: 100%;
  padding: ${(props) => props.isProductPage ? "30px 0" : "60px 0"};
  display: grid;
  grid-template-columns: ${(props) => props.isProductPage ? "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr" : "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr"};
  grid-template-rows: 1fr;
  grid-gap: 0px 24px;
  grid-template-areas: ${(props) => {
  if (!props.isMirrored) {
    return props.isProductPage ? "'Image Image Image Text Text Text Text Text Text'" : "'. Image Image Image Image . Text Text Text Text Text .'";
  } else {
    return props.isProductPage ? "'Text Text Text Text Text Text Image Image Image'" : "'. Text Text Text Text Text . Image Image Image Image .'";
  }
}};

  ${media.phone} {
    padding: ${spacings.xl}px 0 0;
    grid-template-columns: 100%;
    grid-template-rows: auto auto auto;
    grid-gap: 0px ${spacings.m}px;
    grid-template-areas:
      "Image"
      "."
      "Text";
  }
`;
const ImageStack = styled.div`
  display: flex;
  justify-content: center;
  grid-area: Image;

  ${media.mobile} {
    margin-bottom: ${spacings.m}px;
  }
`;
const TextStack = styled(withAttrs(Stack, {spacing: "l"}))`
  grid-area: Text;
  justify-content: center;
  align-content: ${(props) => props.alignment};

  ${media.mobile} {
    --spacing: ${spacings.m}px;
  }
`;
const Headline = styled(withAttrs(Title, {level: 3}))`
  font-size: 30px;
  line-height: 38px;

  ${media.mobile} {
    font-size: 20px;
    line-height: 28px;
  }
`;
const Copy = styled(Doc)`
  font-size: 16px;
  line-height: 22px;
  color: ${colors.b2};

  b,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${colors.b};
  }

  ${media.mobile} {
    font-size: 16px;
    line-height: 24px;
  }
`;
const ImageContainer = styled(Image)`
  width: 100%;
  grid-area: Image;
  border-radius: 4px;
  object-fit: cover;

  ${media.phone} {
    max-width: 100%;
    max-height: 250px;
    width: 100%;
  }
`;
const Link = styled.a`
  color: #00a4e2;
  text-decoration: none;
  font-weight: bold;
  display: flex;

  &:hover {
    svg {
      margin-left: 13px;
    }
  }
`;
const ButtonContainer = styled.div``;
const Btn = styled(Button)`
  ${media.phone} {
    padding: ${spacings.xs}px 0;
    width: 100%;
    line-height: 24px;
  }
`;
const Arrow = styled(ButtonArrow)`
  margin-left: 8px;
  transition: margin-left 0.3s ease;

  path {
    stroke: #00a4e2;
  }
`;
