import styled from "../_snowpack/pkg/@emotion/styled.js";
import React, {useState} from "../_snowpack/pkg/react.js";
import {colors} from "./theme.js";
import {GridContainer} from "./grid.js";
import {CloseBanner} from "./icons.js";
import request from "../common/request.js";
import Button from "./button.js";
import {withAttrs} from "../common/utils.js";
import {t} from "../common/i18n.js";
import Document from "./doc.js";
import Inline from "./inline.js";
const bannersConfig = {
  error: {
    emoji: "🚨",
    iconColor: colors.b,
    color: colors.b,
    backgroundColor: colors.o
  },
  warn: {
    emoji: "⚠️",
    iconColor: colors.b,
    color: colors.b,
    backgroundColor: colors.b5
  },
  info: {
    emoji: "💡",
    iconColor: "white",
    color: colors.b5,
    backgroundColor: colors.b
  },
  cookie: {
    emoji: "🍪",
    iconColor: "white",
    color: colors.b5,
    backgroundColor: colors.b
  }
};
export default function Banner(props) {
  const {content, id, slug, ...rest} = props;
  const {text, type} = content;
  const isCookie = type === "cookie";
  const [visible, setVisibility] = useState(true);
  if (!visible) {
    return null;
  }
  const createHandler = (method) => async () => {
    await request(`/banners/${slug}`, {
      method: "POST",
      body: {
        method
      }
    });
    setVisibility(false);
    if (method === "accept") {
      location.reload();
    }
  };
  const bannerConfig = bannersConfig[type];
  return /* @__PURE__ */ React.createElement(Element, {
    ...rest,
    type
  }, /* @__PURE__ */ React.createElement(GridContainer, null, /* @__PURE__ */ React.createElement(Inner, null, /* @__PURE__ */ React.createElement(Emoji, null, bannerConfig.emoji), /* @__PURE__ */ React.createElement(Content, {
    ...text
  }), !isCookie && /* @__PURE__ */ React.createElement(Close, {
    color: bannerConfig.iconColor,
    onClick: createHandler("close")
  })), isCookie && /* @__PURE__ */ React.createElement(Footer, null, /* @__PURE__ */ React.createElement(FooterInner, null, /* @__PURE__ */ React.createElement(Btn, {
    onClick: createHandler("close")
  }, t("Ablehnen")), /* @__PURE__ */ React.createElement(Btn, {
    onClick: createHandler("accept")
  }, t("Zustimmen"))))));
}
const Element = styled.div`
  background: ${({type}) => bannersConfig[type].backgroundColor};
  color: ${({type}) => bannersConfig[type].color};

  position: sticky;
  top: 0;
  z-index: 9999;
  padding: 24px 0;
`;
const Inner = styled.div`
  display: flex;
  align-items: center;
`;
const Emoji = styled.span`
  font-size: 24px;
  margin-right: 24px;
`;
const Content = styled(Document)`
  font-size: 14px;
  flex-grow: 1;

  a {
    color: inherit;
  }
`;
const Footer = styled.div`
  display: flex;
  padding-top: 12px;
`;
const FooterInner = styled(Inline)`
  margin-left: auto;
`;
const Btn = styled(withAttrs(Button, {small: true, secondary: true}))``;
const Close = styled(CloseBanner)`
  cursor: pointer;
  margin-left: 24px;
`;
