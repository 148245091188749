import {checkFlexGapSupport} from "../common/utils.js";
export const fontFamily = {
  secondary: "'DM Sans', sans-serif",
  primary: "'Mulish', sans-serif"
};
export const lineHeight = "calc(1em + 8px)";
export const shadow = {
  card: "0px 8px 24px rgba(163, 177, 191, 0.35)",
  dropdown: "0px 12px 24px rgba(163, 177, 191, 0.35)"
};
export const headlines = {
  1: 72,
  2: 46,
  3: 36,
  4: 30,
  5: 24
};
export const spacings = {
  xs: 8,
  s: 10,
  m: 16,
  ml: 24,
  l: 32,
  xl: 48,
  xxl: 56
};
export const colors = {
  bg: "#ffffff",
  b: "#001529",
  b1: "#324353",
  b2: "#4C5B69",
  b4: "#99A1A9",
  b5: "#ccd0d4",
  b6: "#f5f5f5",
  w: "#ffffff",
  c: "#00A4E2",
  c1: "#39B8E8",
  c3: "#E3F4FC",
  c4: "#E3F4FC",
  ch: "#0099D3",
  g: "#00CC67",
  g2: "#8BBD22",
  g3: "#F6FFED",
  r: "#E21725",
  r6: "#F5222D",
  r7: "#F4E5E6",
  p: "#9B51E0",
  p2: "#a873e9",
  y: "#FDDA25",
  y1: "#E6C621",
  o: "#FF7E2D",
  shadow: "#a3b1bf"
};
export const breakpoints = {
  tablet: 768,
  desktop: 1024
};
export const media = {
  cartSmall: `@media (max-width: 500px)`,
  phone: `@media (max-width: ${breakpoints.tablet - 1}px)`,
  tablet: `@media (min-width: ${breakpoints.tablet}px) and (max-width: ${breakpoints.desktop - 1}px)`,
  mobile: `@media (max-width: ${breakpoints.desktop - 1}px)`,
  desktop: `@media (min-width: ${breakpoints.desktop}px)`
};
export const GlobalStyles = `
  body {
    position: relative;
    font-family: ${fontFamily.primary};
    font-size: 16px;
    color: ${colors.b};
  }

  * {
    box-sizing: border-box;
  }

  ${media.phone} {
    body {
      font-size: 14px;
    }
  }
`;
export function gap(n, direction = "column") {
  const flexGapSupported = checkFlexGapSupport();
  if (flexGapSupported)
    return `gap: ${n};`;
  if (direction === "column") {
    return `
    & > * + * {
      margin-top: ${n};
    }`;
  }
  return `
  & > * + * {
    margin-left: ${n};
  }`;
}
