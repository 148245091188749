import React from "../_snowpack/pkg/react.js";
import {Global} from "../_snowpack/pkg/@emotion/react.js";
import styled from "../_snowpack/pkg/@emotion/styled.js";
import {colors, GlobalStyles, media} from "./theme.js";
import Header from "./header.js";
import Footer from "./footer.js";
import FooterReduced from "./footer-reduced.js";
import {GridContainer} from "./grid.js";
import {GlobalStoreProvider, useGlobalStore} from "../stores/global.js";
import BackToTopButton from "./back-to-top.js";
import DebugBar from "./debug-bar.js";
import {withSentry} from "../common/sentry.js";
import {CartProvider} from "../stores/cart.js";
import {t} from "../common/i18n.js";
import BottomBanner from "./bottom-banner.js";
export default withSentry(App);
function App(props) {
  const {sessionCart, cart} = props;
  const initialState = isModifyingProduct(props) ? {sidebarActive: true} : {};
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Global, {
    styles: GlobalStyles
  }), /* @__PURE__ */ React.createElement(GlobalStoreProvider, {
    initialState
  }, /* @__PURE__ */ React.createElement(CartProvider, {
    initialState: cart || sessionCart
  }, /* @__PURE__ */ React.createElement(Container, {
    ...props
  }))));
}
function Container(props) {
  const {
    children,
    editor,
    menus,
    debugBar,
    isImpersonating,
    sessionUser,
    ...rest
  } = props;
  const [store] = useGlobalStore();
  const {sidebarActive} = store;
  const isReduced = menus && menus.footer?.component === "account_menu_footer";
  return /* @__PURE__ */ React.createElement(AppContainer, {
    ...rest,
    sidebarActive
  }, !editor && menus && /* @__PURE__ */ React.createElement(Header, {
    menus,
    sessionUser,
    ...rest
  }), /* @__PURE__ */ React.createElement(AppInner, null, editor ? children : /* @__PURE__ */ React.createElement(AppInnerContainer, null, children, /* @__PURE__ */ React.createElement(BackToTopButton, null))), menus && !isReduced && /* @__PURE__ */ React.createElement(FooterC, {
    menus,
    ...rest
  }), menus && isReduced && /* @__PURE__ */ React.createElement(FooterReducedC, {
    menus,
    ...rest
  }), debugBar && /* @__PURE__ */ React.createElement(DebugBar, null), isImpersonating && /* @__PURE__ */ React.createElement(BottomBanner, {
    color: "red"
  }, `impersonating user with email: ${sessionUser?.email} ${sessionUser?.weclapp_customer_id && " weclapp id:" + sessionUser.weclapp_customer_id}`));
}
const FooterC = styled(Footer)`
  flex-shink: 0;
`;
const FooterReducedC = styled(FooterReduced)`
  flex-shink: 0;
`;
const AppInner = styled.div`
  flex-grow: 1;
  flex-shring: 0;
`;
const AppInnerContainer = styled(GridContainer)`
  padding-bottom: 100px;
`;
const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: ${colors.bg};
  min-height: 100vh;

  ${media.mobile} {
    ${({sidebarActive}) => sidebarActive && `
      max-height: 100vh;
      overflow: hidden;
    `}
  }
`;
export function isModifyingProduct(props) {
  const {routes, url} = props;
  const translatedProductRouteWithSlug = t(routes.product);
  const translatedProductRoute = translatedProductRouteWithSlug.substring(0, translatedProductRouteWithSlug.indexOf("/:slug"));
  const isProductRoute = url.indexOf(translatedProductRoute) == 0;
  const isModifyingProduct2 = url.includes("item_id");
  return isProductRoute && isModifyingProduct2;
}
