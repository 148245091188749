import styled from "../_snowpack/pkg/@emotion/styled.js";
import React from "../_snowpack/pkg/react.js";
import {gap, spacings} from "./theme.js";
export default function Stack(props) {
  const {children, spacing = "m", as, ...rest} = props;
  return /* @__PURE__ */ React.createElement(StackContainer, {
    spacing,
    as,
    ...rest
  }, children);
}
const StackContainer = styled.div`
  --spacing: ${({spacing}) => `${spacings[spacing]}px`};
  display: flex;
  flex-direction: column;
  ${gap("var(--spacing)", "column")}
`;
