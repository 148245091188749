import styled from "../_snowpack/pkg/@emotion/styled.js";
import React from "../_snowpack/pkg/react.js";
import {media} from "./theme.js";
export default function BackToTopButton(props) {
  const {...rest} = props;
  const handleClick = () => {
    window.scroll({top: 0, behavior: "smooth"});
  };
  return /* @__PURE__ */ React.createElement(BackToTop, {
    onClick: handleClick,
    ...rest
  }, /* @__PURE__ */ React.createElement("svg", {
    width: "40",
    height: "40",
    viewBox: "0 0 40 40",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, /* @__PURE__ */ React.createElement("path", {
    d: "M0.500002 20C0.500003 9.23044 9.23045 0.499997 20 0.499998C30.7696 0.499999 39.5 9.23045 39.5 20C39.5 30.7696 30.7696 39.5 20 39.5C9.23045 39.5 0.500001 30.7695 0.500002 20Z",
    fill: "white",
    stroke: "#CCCCCC"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M20 27.5L20 12.5",
    stroke: "#324353",
    strokeWidth: "2",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M27 19.5L20 12.5L13 19.5",
    stroke: "#324353",
    strokeWidth: "2",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  })));
}
const BackToTop = styled.div`
  display: none;
  cursor: pointer;

  ${media.mobile} {
    display: flex;
    margin-top: 50px;
  }
`;
