import styled from "../_snowpack/pkg/@emotion/styled.js";
import React, {Children, useState} from "../_snowpack/pkg/react.js";
import {ArrowLeftRounded, ArrowRightRounded} from "./icons.js";
import {colors, gap, media} from "./theme.js";
import {useSwipeable} from "../_snowpack/pkg/react-swipeable.js";
import {css, keyframes} from "../_snowpack/pkg/@emotion/react.js";
import {useEffect} from "../_snowpack/pkg/react.js";
const loopDelay = 4500;
let intervalId;
export default function Slider(props) {
  const {children, ...rest} = props;
  const [activeIndex, setActiveIndex] = useState(0);
  const [slideDirection, setSlideDirection] = useState();
  const count = Children.count(children);
  const canNav = count > 1;
  const animating = !!slideDirection;
  const calcIndex = (i) => {
    return i < 0 ? count + i : i % count;
  };
  const calcOrder = (i, activeIndex2) => {
    if (slideDirection === "left") {
      return calcIndex(i - activeIndex2) + 1;
    }
    if (slideDirection === "right") {
      return calcIndex(i - activeIndex2 + 1) + 1;
    }
    return calcIndex(i - activeIndex2) + 1;
  };
  const setIndex = (i) => {
    if (i < activeIndex) {
      setSlideDirection("left");
    }
    if (i > activeIndex) {
      setSlideDirection("right");
    }
    setActiveIndex(calcIndex(i));
  };
  const onNavLeft = () => {
    if (animating)
      return;
    setIndex(activeIndex - 1);
  };
  const onNavRight = () => {
    if (animating)
      return;
    setIndex(activeIndex + 1);
  };
  const handlers = useSwipeable({
    onSwipedLeft: () => canNav && onNavRight(),
    onSwipedRight: () => canNav && onNavLeft()
  });
  const onAnimationEnd = () => {
    setSlideDirection(void 0);
  };
  const startAnimation = () => {
    if (intervalId)
      return;
    let index = activeIndex;
    intervalId = setInterval(() => setIndex(++index), loopDelay);
  };
  const stopAnimation = () => {
    clearInterval(intervalId);
    intervalId = void 0;
  };
  useEffect(() => {
    startAnimation();
    return stopAnimation;
  }, [intervalId]);
  return /* @__PURE__ */ React.createElement(Container, {
    className: "slider",
    ...handlers,
    ...rest
  }, /* @__PURE__ */ React.createElement(ItemsWindow, {
    onMouseEnter: stopAnimation,
    onTouchStart: stopAnimation,
    onMouseLeave: startAnimation
  }, /* @__PURE__ */ React.createElement(Items, {
    style: {"--active-index": activeIndex, "--count": count},
    slideDirection,
    onAnimationEnd
  }, Children.map(children, (c, i) => /* @__PURE__ */ React.createElement(Item, {
    style: {"--order": calcOrder(i, activeIndex)}
  }, c)))), /* @__PURE__ */ React.createElement(Steps, null, canNav && Array.from({length: count}, (_, i) => /* @__PURE__ */ React.createElement(Step, {
    key: i,
    active: activeIndex === i,
    onClick: () => setIndex(i)
  }))), canNav && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(NavLeft, {
    onClick: onNavLeft,
    disabled: animating
  }), /* @__PURE__ */ React.createElement(NavRight, {
    onClick: onNavRight,
    disabled: animating
  })));
}
const Container = styled.div`
  position: relative;
  padding-left: 30px;
  padding-right: 30px;

  ${media.mobile} {
    padding-left: 0;
    padding-right: 0;
  }

  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 0;
  }

  &:before {
    left: 0;
    transform: translateX(-100%);
  }

  &:after {
    right: 0;
    transform: translateX(100%);
  }
`;
const ItemsWindow = styled.div`
  overflow: hidden;
`;
const slidesGap = "80px";
const Items = styled.div`
  display: flex;
  align-items: center;
  flex-direction: columns;
  flex-wrap: nowrap;
  animation-duration: .6s;
  ${gap(slidesGap)};

  ${({slideDirection}) => {
  if (slideDirection == "left") {
    return css`
        animation-name: ${slideLeftIn};
      `;
  }
  if (slideDirection == "right") {
    return css`
        animation-name: ${slideRightIn};
      `;
  }
}}
}

`;
const Item = styled.div`
  width: 100%;
  flex-shrink: 0;
  order: var(--order);

  ${media.mobile} {
    align-self: stretch;
  }
`;
const NavLeft = styled(ArrowLeftRounded)`
  position: absolute;
  width: 40px;
  height: 40px;
  left: clamp(-100px, calc((1200px - 100vw) / 4), -20px);
  top: 50%;
  transform: translateY(0);
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s, transform 0.3s;
  z-index: 1;

  .slider:hover & {
    opacity: 1;
    transform: translateY(-50%);
  }

  ${media.mobile} {
    display: none;
  }
`;
const NavRight = styled(ArrowRightRounded)`
  position: absolute;
  top: 50%;
  right: clamp(-100px, calc((1200px - 100vw) / 4), -20px);
  width: 40px;
  height: 40px;
  transform: translateY(0);
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s, transform 0.3s;
  z-index: 1;

  .slider:hover & {
    opacity: 1;
    transform: translateY(-50%);
  }

  ${media.mobile} {
    display: none;
  }
`;
const Steps = styled.div`
  display: flex;
  justify-content: center;
  padding: 30px 0;

  ${media.mobile} {
    padding: 0;
  }
`;
const Step = styled.div`
  padding: 15px;
  cursor: pointer;

  &:before {
    display: block;
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 100%;
    border: 1px solid ${colors.b5};
    ${({active}) => active && `background: ${colors.b5};`}
  }
`;
const slideRightIn = keyframes`
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(calc(-100% - ${slidesGap}));
  }
`;
const slideLeftIn = keyframes`
  from {
    transform: translateX(calc(-100% - ${slidesGap}));
  }

  to {
    transform: translateX(0);
  }
`;
