import styled from "../_snowpack/pkg/@emotion/styled.js";
import React from "../_snowpack/pkg/react.js";
import {colors} from "./theme.js";
import {Title, Text, Paragraph, Link} from "./typo.js";
export default function Document(props) {
  const {content, templateStringReplacer, ...rest} = props;
  return /* @__PURE__ */ React.createElement(DocContainer, {
    ...rest
  }, /* @__PURE__ */ React.createElement(DocNodes, {
    nodes: content,
    templateStringReplacer
  }));
}
function DocNodes(props) {
  const {nodes, templateStringReplacer} = props;
  if (!nodes)
    return null;
  return /* @__PURE__ */ React.createElement(React.Fragment, null, nodes.map((n, i) => /* @__PURE__ */ React.createElement(DocNode, {
    key: i,
    templateStringReplacer,
    ...n
  })));
}
function DocNode(props) {
  const {type, templateStringReplacer} = props;
  if (type === "text") {
    return /* @__PURE__ */ React.createElement(TextNode, {
      ...props,
      templateStringReplacer
    });
  }
  if (type === "paragraph") {
    const {content} = props;
    return /* @__PURE__ */ React.createElement(Paragraph, null, /* @__PURE__ */ React.createElement(DocNodes, {
      nodes: content,
      templateStringReplacer
    }));
  }
  if (type === "heading") {
    const {content, attrs} = props;
    return /* @__PURE__ */ React.createElement(Title, {
      level: attrs.level
    }, /* @__PURE__ */ React.createElement(DocNodes, {
      nodes: content,
      templateStringReplacer
    }));
  }
  if (type === "bullet_list") {
    const {content} = props;
    return /* @__PURE__ */ React.createElement("ul", null, /* @__PURE__ */ React.createElement(DocNodes, {
      nodes: content,
      templateStringReplacer
    }));
  }
  if (type === "ordered_list") {
    const {content} = props;
    return /* @__PURE__ */ React.createElement("ol", null, /* @__PURE__ */ React.createElement(DocNodes, {
      nodes: content,
      templateStringReplacer
    }));
  }
  if (type === "list_item") {
    const {content} = props;
    return /* @__PURE__ */ React.createElement("li", null, /* @__PURE__ */ React.createElement(DocNodes, {
      nodes: content,
      templateStringReplacer
    }));
  }
  if (type === "blockquote") {
    const {content} = props;
    return /* @__PURE__ */ React.createElement("blockquote", null, /* @__PURE__ */ React.createElement(DocNodes, {
      nodes: content,
      templateStringReplacer
    }));
  }
  if (type === "horizontal_rule") {
    return /* @__PURE__ */ React.createElement("hr", null);
  }
  if (type === "image") {
    return /* @__PURE__ */ React.createElement("img", {
      ...props.attrs,
      loading: "lazy"
    });
  }
  if (type === "hard_break") {
    return /* @__PURE__ */ React.createElement("br", null);
  }
  console.error(`${type} has no presentation! Please add one`);
  return null;
}
function TextNode(props) {
  const {text, templateStringReplacer} = props;
  const Marked = TextMarks(props);
  return /* @__PURE__ */ React.createElement(Text, null, /* @__PURE__ */ React.createElement(Marked, null, templateStringReplacer ? templateStringReplacer(text) : text));
}
function TextMarks(props) {
  const {marks = [], templateStringReplacer} = props;
  const typeToTag = {
    bold: "b",
    italic: "i",
    underline: "u",
    code: "code",
    link: Link
  };
  return marks.reduce((Acc, m) => {
    const {type} = m;
    const Tag = typeToTag[type];
    if (!Tag) {
      console.warn(`type not supported for mark`, m);
      return (props2) => /* @__PURE__ */ React.createElement(Acc, {
        ...props2
      });
    }
    let additionalProps = {};
    const isLink = type === "link";
    if (isLink) {
      const {href, linktype, target} = m.attrs;
      const replacedHref = templateStringReplacer ? templateStringReplacer(href) : href;
      const extendedHref = linktype === "email" ? `mailto:${replacedHref}` : replacedHref;
      additionalProps = {href: extendedHref, target};
    }
    return function Mark(props2) {
      return /* @__PURE__ */ React.createElement(Acc, null, /* @__PURE__ */ React.createElement(Tag, {
        ...props2,
        ...additionalProps
      }));
    };
  }, (props2) => /* @__PURE__ */ React.createElement(Text, null, props2.children));
}
const DocContainer = styled.div`
  a {
    color: ${colors.b};
  }

  b {
    font-weight: bold;
  }

  i {
    font-style: italic;
  }

  p + *:not(:empty) {
    margin-top: 1em;
  }

  ul {
    padding-left: 1em;
    list-style: disc;
  }

  ol {
    padding-left: 1em;
    list-style: decimal;
  }

  li {
    padding-left: 0.5em;
  }

  li + li:not(:empty) {
    margin-top: 0.5em;
  }
`;
