import React from "../_snowpack/pkg/react.js";
import {findDOMNode, unmountComponentAtNode} from "../_snowpack/pkg/react-dom.js";
export default function Component(props) {
  const {_editable, children} = props;
  return /* @__PURE__ */ React.createElement(React.Fragment, null, _editable && /* @__PURE__ */ React.createElement(HTMLComment, null, _editable), children);
}
class HTMLComment extends React.Component {
  componentDidMount() {
    const el = findDOMNode(this);
    unmountComponentAtNode(el);
    el.outerHTML = this.props.children;
  }
  render() {
    return /* @__PURE__ */ React.createElement("div", null);
  }
}
