import styled from "../_snowpack/pkg/@emotion/styled.js";
const BottomBanner = styled.div`
  position: fixed;
  align-self: flex-end;
  bottom: 0;
  z-index: 100000;
  left: 0;
  width: 100%;
  height: 50px;
  padding: 5px;
  font-weight: bold;
  text-align: center;
  line-height: 40px;
  ${({color}) => `background-color: ${color};`}
`;
export default BottomBanner;
