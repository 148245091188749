import React from "../_snowpack/pkg/react.js";
import styled from "../_snowpack/pkg/@emotion/styled.js";
import {css} from "../_snowpack/pkg/@emotion/react.js";
import {fontFamily, headlines} from "./theme.js";
export function Text(props) {
  const {as = "span", children, ...rest} = props;
  const shouldSetInnerHTML = typeof children === "string" && (children.includes("<wbr>") || children.includes("&shy;"));
  if (shouldSetInnerHTML) {
    return /* @__PURE__ */ React.createElement(TextContainer, {
      as,
      dangerouslySetInnerHTML: {__html: children},
      ...rest
    });
  }
  return /* @__PURE__ */ React.createElement(TextContainer, {
    as,
    ...rest
  }, children);
}
export function Title(props) {
  const {level = 1, ...rest} = props;
  const size = headlines[level];
  return /* @__PURE__ */ React.createElement(Text, {
    as: `h${level}`,
    font: "secondary",
    weight: "bold",
    size,
    ...rest
  });
}
export function Paragraph(props) {
  return /* @__PURE__ */ React.createElement(Text, {
    as: "p",
    ...props
  });
}
export function Link(props) {
  return /* @__PURE__ */ React.createElement(Text, {
    as: "a",
    ...props
  });
}
const TextContainer = styled.span`
  ${({font, size, lineHeight, weight}) => css`
    ${font && `font-family: ${fontFamily[font]};`}
    ${size && `font-size: ${size}px;`}
    ${weight && `font-weight: ${weight};`}
    line-height: ${lineHeight ? `${lineHeight}px` : `calc(1em + 8px)`};
  `}
`;
